import React, { useState, useEffect } from 'react';

type AdminReportFilterProps = {
   readonly filters?: any;
   readonly selectedFilter: number;
   readonly reportTypeSelectedCb?: (value: number) => void;
};


function AdminReportFilter(props:AdminReportFilterProps) {
   const [currentFilter, setCurrentFilter] = useState(0)

   useEffect(() => {
      setCurrentFilter(props.selectedFilter);
   }, [props]);

   const onPropertyChanged = function(e){
      if (!e){
         return;
      }

      let { value } = e.target;
      value = parseInt(value);

      if (value >= 0){
         props.reportTypeSelectedCb(value);
         setCurrentFilter(value);
      }
   }

   const getSelectItems = () => {
      var options = [];
      options.push(<option key={0} value={0}>Alle tjenester</option>);

      props.filters.map((type) => {
         options.push(<option value={type.projectTypeId}>{type.name}</option>);
      });

      return (
         <select className='bk-input-field' onChange={(e) => onPropertyChanged(e)} value={currentFilter}>
            {options}
         </select>
      );
   }

   return(
      <>
        {getSelectItems()}
      </>
   )
}
export default AdminReportFilter;