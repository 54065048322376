import React, { useState, useEffect, useRef } from 'react'
import { FinanceTableApi } from '../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { NumericInput, SectionHeader, TextLineInput, ModuleComment } from '.'

type FinanceTableProps = {
   readonly moduleId: number;
   readonly bridgeId?: number;
   readonly subId: number;
   readonly descriptionTitle: string;
   readonly valueTitle: string;
   readonly sumTitle: string;
   readonly infoText?: string;
   readonly showHeader?: boolean;
   readonly header?: string;
   readonly readOnly?: boolean;
   readonly order?: number;
   readonly displayIcons?: boolean;
   readonly hideInput?: boolean;
   readonly showComment?: boolean;
   readonly allowMultiple?: boolean;
   readonly allowBudget?: boolean;
   readonly budget?: number;
   readonly budgetTitle?: string;
   readonly budgetResultTitle?: string;
   readonly enableCount?: boolean;
   readonly countNumber?: number;
   readonly onValueChangeCb?: (value: number) => void;
   readonly onValueDeleteCb?: () => void;
   readonly onTableChangedCb?: (id: number, heading: string) => void;
   readonly onTableAddedCb?: (id: number) => void;
   readonly onTableDeletedCb?: (id: number) => void;
};

function FinanceTable(props: FinanceTableProps) {


   const [financeTable, setFinanceTable] = useState({
      financeTableId: 0,
      subId: 0,
      heading: '',
      bridgeId: 0,
   });
   const [financeRows, setFinanceRows] = useState([]);
   const [showHeader, setShowHeader] = useState(false);
   const [dynamicBridgeId, setdynamicBridgeId] = useState(0);

   const [sum, setSum] = useState(0);
   useEffect(() => {
      if (props.showHeader != null) {
         setShowHeader(props.showHeader);
      }

      var bridgeId = 0;
      if (props.bridgeId != null) {
         bridgeId = props.bridgeId
      }

      //Fetch comments for this module
      FinanceTableApi.get(props.moduleId, props.subId, bridgeId)
         .then(
            (response) => {
               setFinanceTable(response.data);
               setFinanceRows(checkIfRowsShouldBeAdded(response.data.rows, response.data.financeTableId));
               calculateSum(response.data.rows);
            },
            (error) => {
               console.log(error);
            })

   }, [props.moduleId, props.subId]);


   const checkIfRowsShouldBeAdded = (rows, financeTableId) => {
      var add = true;
      if (rows.length > 0) {
         var last = rows[rows.length - 1];
         if ((last.description == null || last.description == '') && (last.value == null || last.value == 0)) {
            add = false;
         }
      }
      if (add)
         return addBlankRow(rows, financeTableId);
      else
         return rows;
   }

   const calculateSum = (rows) => {
      var res = 0;
      rows.map((row, index) => {
         res = res + row.value;
      })
      if (res != sum) {
         setSum(res);
         if (props.onValueChangeCb != null)
            props.onValueChangeCb(res);
      }
   }

   const addBlankRow = (rows, financeTableId) => {
      var blank = {
         financeTableRowId: 0,
         financeTableId: financeTableId,
         description: '',
         value: 0,
      }
      rows.push(blank);
      return rows;
   }

   const handleRowChange = (value, id, name) => {

      setFinanceRows(
         financeRows.map(item =>
            item.financeTableRowId === id
               ? { ...item, [name]: value }
               : item
         ));
      var row = financeRows.find(x => x.financeTableRowId === id);
      row[name] = value;
      updateRow(row);

   }

   const onRowChange = (value, id) => {
      var row = financeRows.find(x => x.financeTableRowId === id);
      row.value = value;
      updateRow(row);
   }

   const handleRowDelete = (rowId) => {
      if (rowId > 0) {
         FinanceTableApi.deleteRow(rowId)
            .then(
               (response) => {
                  setFinanceRows(financeRows.filter(row => row.financeTableRowId !== rowId));
                  calculateSum(financeRows.filter(row => row.financeTableRowId !== rowId));
                  if (props.onValueDeleteCb != null)
                     props.onValueDeleteCb();
               },
               (error) => {
                  console.log(error);
               })
      }
   }

   const updateRow = (row) => {
      var data = {
         financeTableRowId: row.financeTableRowId,
         financeTableId: financeTable.financeTableId,
         description: row.description,
         value: row.value ?? 0
      }
      FinanceTableApi.updateRow(data)
         .then(
            (response) => {
               var newArray = financeRows.map(x => {
                  if (x.financeTableRowId !== row.financeTableRowId) return x
                  return response.data
               });
               setFinanceRows(checkIfRowsShouldBeAdded(newArray, financeTable.financeTableId))
               calculateSum(newArray);
            },
            (error) => {
               console.log(error);
            })
   }

   const onNameChange = (value) => {
      setFinanceTable((financeTable) => ({ ...financeTable, heading: value }));
      updateFinanceTable(value)
   }


   const updateFinanceTable = (heading) => {
      var data = {
         financeTableId: financeTable.financeTableId,
         heading: heading
      }
      FinanceTableApi.update(data)
         .then(
            (response) => {
               props.onTableChangedCb(financeTable.financeTableId, heading)
            },
            (error) => {
               console.log(error);
            })
   }

   const onBuildingAdd = () => {
      props.onTableAddedCb(financeTable.financeTableId)
   }
   const onBuildingDelete = () => {
      props.onTableDeletedCb(financeTable.financeTableId)
   }


   let inputHtml = (<>
      <TextLineInput value={financeTable.heading} name="building" onValueChangeCb={onNameChange} key={'finance-table-header-' + financeTable.financeTableId} class='module-row-title-big' placeholder="Navn på bygning" />
   </>
   )

   let menuHtml = (<>
      <div className='bk-icon'><FontAwesomeIcon icon={faTrashAlt} title="Slett bygning" onClick={onBuildingDelete} /></div>
      <div className='bk-icon'><FontAwesomeIcon icon={faPlus} title="Legg til bygning" onClick={onBuildingAdd} /></div>
   </>);

   var rowsHtml = null;
   if (financeRows) {
      rowsHtml = financeRows.map((row, index) => {
         return <>
            <div className="module-row" key={"finance-table-" + row.moduleId + "-" + row.subId + "-" + row.bridgeId + "-" + row.financeTableRowId + "-" + index}>
               {props.enableCount ? <div className="flex-015">{index + 1}</div> : <></>}
               <div className="flex-10">
                  <TextLineInput
                     name='description'
                     key={"finance-table-description-" + index}
                     class="module-row-input"
                     value={row.description}
                     id={row.financeTableRowId}
                     onValueChangeCb={handleRowChange}
                  />
               </div>
               <div className="flex-2">
                  <NumericInput id={row.financeTableRowId} value={row.value} class={'align-right'} suffix=',-' onValueChangeCb={onRowChange}></NumericInput>
               </div>
               <div className='bk-icon delete-icon' onClick={() => handleRowDelete(row.financeTableRowId)}><FontAwesomeIcon icon={faTimes} className="tg-icon" title="Slett" /></div>

            </div>
         </>
      });
   }


   var contentHtml = <>
      <div className="module-row" key={"finance-table-header"}>
         {props.countNumber ? <div className="flex-015 bold">{props.countNumber}</div> : <></>}
         <div className="flex-10 module-row-title bold">{props.descriptionTitle}</div>
         <div className="flex-2 module-row-title bold">{props.valueTitle}</div>
         <div className='bk-icon'></div>
      </div>
      {rowsHtml}

      <div className="module-row" key={"finance-table-sum"}>
         {props.enableCount ? <div className="flex-015"></div> : <></>}
         <div className="flex-10 module-row-title-big align-right bold"><span>{props.sumTitle}</span></div>
         <div className="flex-2">
            <NumericInput disabled={true} value={sum} suffix=',-' class={'align-right'} ></NumericInput>
         </div>
         <div className='bk-icon'></div>
      </div>

      {(props.allowBudget) ?
         <>
            <div className="module-row" key={"finance-table-budget"}>
               {props.enableCount ? <div className="flex-015"></div> : <></>}
               <div className="flex-10 module-row-title-big align-right bold"><span>{props.budgetTitle}</span></div>
               <div className="flex-2 module-row-title">
                  <NumericInput disabled={true} suffix=',-' value={props.budget} placeholder={'0'} class={'align-right'} ></NumericInput>
               </div>
               <div className='bk-icon delete-icon'></div>
            </div>

            <div className="module-row" key={"finance-table-budgetresult"}>
               {props.enableCount ? <div className="flex-015"></div> : <></>}
               <div className="flex-10 module-row-title-big align-right bold"><span>{props.budgetResultTitle}</span></div>
               <div className="flex-2 module-row-title">
                  <NumericInput disabled={true} suffix=',-' value={props.budget - sum} placeholder={'0'} class={'align-right'} ></NumericInput>
               </div>
               <div className='bk-icon delete-icon'></div>
            </div>
            <br></br>
         </>
         :
         <></>

      }

      <div className="module-row">
         <div className='flex-10 module-row-title-small align-right bold'>{props.infoText}</div>
         <div className='bk-icon'></div>
      </div>
   </>

   


   return <>
      {(showHeader) ?
         <>
            <div className='module-section' key={'finance-table-' + financeTable.financeTableId}>
               <SectionHeader title={props.header} input={props.hideInput ? null : inputHtml} menu={props.allowMultiple ? menuHtml : null}></SectionHeader>
               <div className='content-frame'>
                  {contentHtml}
               </div>
            </div>

            {
               props.showComment ?
                  <><ModuleComment moduleId={props.moduleId} multiComment={true} readOnly={props.readOnly} phraseKey='calculation.comments'></ModuleComment></>
                  :
                  <></>
            }
         </>
         :
         <>
            {contentHtml}
         </>
      }





   </>

}

export default FinanceTable;