import React, {useState} from 'react';
import './SectionHeaderModule.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { count } from 'console';

interface SectionHeaderModuleProps {
    title: string,
    menu?: JSX.Element,
    input?:JSX.Element
}


export const SectionHeaderModule: React.FC<SectionHeaderModuleProps> = ({ title, menu}) => {

      return <> 
         <div className="section-header-module module-row">
            <div className="description">{title || ''} </div>
            <div className="bk-icon">{menu}</div>
         </div>
      </>
   }