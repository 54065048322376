import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { actionCreators } from '../../store/auth';
import { SimpleCalculationApi } from '../../api';
import { useHistory } from 'react-router-dom'
import { useParams } from "react-router-dom";
import { SimpleCalcInfo, SimpleCalcDescription, SimpleCalcCalculation, SimpleCalcResult, SimpleCalcPrintDialog } from './child-components'
import { ProjectLink, SectionHeader, Spinner, SectionHeaderModule } from '../../components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight, faPenToSquare, faPrint, faTimes } from '@fortawesome/free-solid-svg-icons'
import { TextLineInput, ModuleComment, } from '../../components'

type SimpleCalculationProps = typeof actionCreators &
{
  readonly isAuthenticated: boolean;
  readonly role: number;
  readonly userName: string;
};


const keyPressTimer = 1000;
let caclulationTimer = null;

const SimpleCalculation: React.FC<SimpleCalculationProps> = ({
  isAuthenticated,
  userName,
  role
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const [calculation, setCalculation] = useState({
    projectId: 0,
    simpleCalculationId: 0,
    name: '',
    subTitle: '',
    descriptions: [],
    infos: [],
    categories: []
  });
  const [project, setProject] = useState({
    address: {
      address: '',
      zipCode: '',
      city: ''
    },
    userName: ''
  });
  const [openPrintDialog, setOpenPrintDialog] = useState(false);

  var openPrintDlg = false;

  const params = useParams();
  const calculationId = (params as any).id;
  const history = useHistory();
  const calculationRef = useRef(calculation);
  calculationRef.current = calculation;


  useEffect(() => {
    const fetchData = async () => {
      SimpleCalculationApi.getSimpleCalculation(calculationId)
        .then(
          (response) => {
            setCalculation(response.data.calculation);
            setProject(response.data.project);
            setIsLoading(false);
          },
          (error) => {
            console.log(error);
            setIsLoading(false);
            actionCreators.logoutUser();
            history.push("/login");
          });
    }
    fetchData();
  }, []);


  const handleChange = function (e) {
    const { name, value } = e.target;
    setCalculation(calculation => ({ ...calculation, [name]: value }));

    if (caclulationTimer !== null) {
      clearTimeout(caclulationTimer);
    }
    caclulationTimer = setTimeout(() => {
      delayedCalculationUpdate();
    }, keyPressTimer);

  }

  const delayedCalculationUpdate = function () {
    SimpleCalculationApi.updateSimpleCalculation(calculationRef.current)
      .then(
        (response) => {

        },
        (error) => {
          console.log(error);
        }
      );
  }

  const handleCategoriesUpdated = function (categories) {
    setCalculation(calculation => ({ ...calculation, categories: categories }));
  }

  const handlePrint = function () {
    setOpenPrintDialog(true);
  }

  const handlePrintCb = function (type) {
    setOpenPrintDialog(false);
    if (type > 0) {
      SimpleCalculationApi.downloadPdf(calculationId, type);
    }
  }

  const handleProjectClick = function () {
    history.push('/project/' + calculation.projectId);
  }

  const showOwner = (ownerName) => {
    if (ownerName != userName && ownerName != null && ownerName != '') {
      return <span className='bk-project-owner-title'>Bygningssakkyndig: {ownerName}</span>
    }
  }
  return (
    <>
      <div className="main-container">
        <div className="module-row mb-2">

          <div className="bk-text-blue-big flex-4">
            <div className=''>
              Detaljert kalkulasjon
            </div>
            {project ? showOwner(project.userName) : ''}
          </div>

          <ProjectLink id={calculation.projectId} address={project.address.address} zipCode={project.address.zipCode} city={project.address.city} />
          <div className="flex-015"><FontAwesomeIcon className='printer-icon bk-icon' icon={faPrint} onClick={handlePrint} /></div>
        </div>
        
        <div className='report-content'>
          <SectionHeader title={' Forside'}></SectionHeader>
          <div className='content-frame'>
            <div className="module-row">
              <div className="flex-3">
                Dokumentets tittel:
              </div>
              <div className="flex-6">
                <TextLineInput name="name" value={calculation.name} onValueChangeCb={handleChange} />
              </div>
            </div>
            <div className="module-row">
              <div className="flex-3">
                Dokumentets undertittel:
              </div>
              <div className="flex-3">
                <TextLineInput name="subTitle" value={calculation.subTitle} onValueChangeCb={handleChange} />
              </div>
            </div>
          </div>

          <SectionHeader title={' Opplysninger'}></SectionHeader>
          <div className='content-frame'>
            <SimpleCalcInfo calculationId={calculationId} infoRows={calculation.infos} />
          </div>

          <SimpleCalcDescription calculationId={calculationId} descriptionElements={calculation.descriptions}></SimpleCalcDescription>

          <SimpleCalcCalculation
            simpleCalculationId={calculation.simpleCalculationId}
            categoriesArray={calculation.categories}
            categoriesUpdateCb={handleCategoriesUpdated} />

          <SectionHeader title={'Resultat'}></SectionHeader>
          <div className='content-frame'>
            <SimpleCalcResult categoriesArray={calculation.categories} />
          </div>
        </div>
      </div>

      <SimpleCalcPrintDialog open={openPrintDialog} handlePrintCb={handlePrintCb} />
      <Spinner isLoading={isLoading} />
    </>
  );

}

const mapStateToProps = (state: IApplicationState) => ({
  isAuthenticated: state.auth.isAuthenticated,
  role: state.auth.role,
  userName: state.auth.userName,
});

export default connect(mapStateToProps, actionCreators)(SimpleCalculation);
//export default React.memo(SimpleCalculation);