import React from 'react';
import { RoutesConfig } from '../../config/routes.config';
import { BkButton } from '../../components';
import { useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'

const RegisterConfirmation: React.FC = () => {

  const history = useHistory();

  const handleSubmit = () => {
    history.push(RoutesConfig.Login.path)
  }


  return (
    <div className="container">
      <div className='module-row'>
        <div className='flex-1'></div>
        <div className='flex-2 dialog-container shadow'>
          <div className='bk-text-blue bold mb-3'>
            Takk for at du har registrert deg hos oss
          </div>

          <div className="bk-text-blue mb-3">
            Du er nå klar til å benytte deg av byggekost.no sine tjenester.
            Har du spørsmål rundt tjenesten, så ikke vent med å kontakte oss.
          </div>

          <BkButton caption='Trykk her for å logge inn' onClickHandler={handleSubmit} />

        </div>
        <div className='flex-1'></div>
      </div>




    </div>
  )
}

export default RegisterConfirmation;