import React, {useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import {Menu, Divider} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import {formatValue } from '../../../utils'
import { BkButton, NumericInput, TextLineInput, AutoTextBox, SelectInput } from '../../../components';

const availableUnits = ['lm', 'm²', 'm³', 'stk', 'RS', 'Kg', 'Tonn', 'liter'];
const keyPressTimer = 500;
let rowTimer = null;

const CalculationTemplateCategoryRow = ({categoryRow, catIndex, index, updateCb, moveUpCb, moveDownCb, deleteCb}) => {
  const [row, setRow] = React.useState({
    id:0,
    description:'',
    order:0,
    unitQuantity:0.0,
    unit:0.0,
    unitPrice:0.0,
    unitTotal:0.0,
    hours:0.0,
    hourPrice:0.0,
    workTotal:0.0,
    total:0.0
  });

  
  useEffect(() => {
    setRow(categoryRow);
  }, [categoryRow])

  const handleCategoryRowChange = function(e) {
    const { name, value } = e.target;
    let changedRow = row;

    if (name === 'description' || name === 'unit') {
      changedRow[name] = value;
      setRow(row =>({...row, [name]:value}));
    }
    else {
      changedRow[name] = parseFloat(value);
      setRow(row =>({...row, [name]:parseFloat(value)}));
    }
    
    if (rowTimer !== null){
      clearTimeout(rowTimer);
    }

    rowTimer = setTimeout(() => {
      updateCb(changedRow);
    }, keyPressTimer);  
  }

  const handleInfoMoveUp = function() {
    moveUpCb(row.id);
  }

  const handleInfoMoveDown = function() {
    moveDownCb(row.id);
  }

  const handleInfoDelete = function() {
    deleteCb(row.id);    
  }



  const getSelectUnits = function(selectedUnit) {
    var options = [];

    var newOptions =  availableUnits.map((unit, index) => {
      return <option key={index} value={unit}>{unit}</option>
    });
    options.push(<option key={0} value={0}>{''}</option>)
    options.push(newOptions);

    return (
      <SelectInput valueKey='id' selectedValue={selectedUnit} nameKey='name' defaultOption='-' options={availableUnits} name='unit' onValueChangeCb={handleCategoryRowChange} />
      
      // <select className='simple-calc-select' name='unit' value={selectedUnit} onChange={(e)=>handleCategoryRowChange(e)}>
      //   {options}
      // </select>
    );
  }

  function ActionMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
    
      return <div aria-controls="fade-menu" aria-haspopup="true" className='navbar-profile'  >
          <FontAwesomeIcon icon={faCaretDown}  onClick={handleClick}/>
          <Menu
              elevation={0}
              //getContentAnchorEl={null}
              // anchorOrigin={{
              //   // vertical: 'bottom',
              //   horizontal: 'center',
              // }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            className="navbar-dropdown-menu"
          >
            <MenuItem onClick={handleInfoMoveUp}>Flytt opp</MenuItem>
            <MenuItem onClick={handleInfoMoveDown}>Flytt ned</MenuItem>
            <Divider />
            <MenuItem onClick={handleInfoDelete}>Slett underpunkt</MenuItem>
          </Menu>
        </div>;

  }

  const getColorClass = function(index, main) {
    if (index % 2 > 0) {
      if (main) {
        return "sc-color-main-odd";       
      }
      return "sc-color-light-odd";
    }
    if (main) {
      return "sc-color-main-even";       
    }
    return "sc-color-light-even";
  }



  return (
    <>
    <div className="display-flex">
      <div className={"flex-1 simple-calc-category-row align-center " + getColorClass(catIndex,true)}>{index+1}</div>
      <div className="flex-3 simple-calc-category-row">
        <input className="simple-calc-input" placeholder="Kort beskrivelse" name={"description"} type="text" value={row.description} onChange={(e)=>handleCategoryRowChange(e)} />
      </div>
      <div className="flex-1 simple-calc-category-row">
        <input className="simple-calc-input align-right" placeholder="0" name={"unitQuantity"} step="0.5" type="number" value={row.unitQuantity > 0?row.unitQuantity:''} onChange={(e)=>handleCategoryRowChange(e)} />
      </div>
      <div className="flex-1 simple-calc-category-row">
        {getSelectUnits(categoryRow.unit)}
      </div>
    
      <div className="flex-1 simple-calc-category-row">
        <input className="simple-calc-input-reduced align-right" placeholder="0" name={"unitPrice"} type="number" value={row.unitPrice > 0?row.unitPrice:''} onChange={(e)=>handleCategoryRowChange(e)} />,-
      </div>
      <div className={"flex-1 simple-calc-category-row align-right " + getColorClass(catIndex,false)}><div className="simple-calc-category-value">{formatValue(categoryRow.unitTotal, 0, ',-', '0,-')}</div></div>
      <div className="flex-1 simple-calc-category-row">
        <input className="simple-calc-input align-right" placeholder="0" name={"hours"} type="number" step="0.5" value={row.hours > 0?row.hours:''} onChange={(e)=>handleCategoryRowChange(e)} />
      </div>
      <div className="flex-1 simple-calc-category-row">
        <input className="simple-calc-input-reduced align-right" placeholder="0" name={"hourPrice"} type="number" value={row.hourPrice > 0?row.hourPrice:''} onChange={(e)=>handleCategoryRowChange(e)} />,-
      </div>
      <div className={"flex-1 simple-calc-category-row align-right " + getColorClass(catIndex,false)}><div className="simple-calc-category-value">{formatValue(categoryRow.workTotal, 0, ',-', '0,-')}</div></div>
      <div className={"flex-1 simple-calc-category-row align-right " + getColorClass(catIndex,true)}><div className="simple-calc-category-value">{formatValue(categoryRow.total, 0, ',-', '0,-')}</div></div>
      <div className="simple-calc-heading-menu"><ActionMenu /></div>
    </div>
    </>
  );

}
export default React.memo(CalculationTemplateCategoryRow);