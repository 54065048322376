import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import parse from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faArrowLeft, faDollar } from '@fortawesome/free-solid-svg-icons'
import { BkButton } from '../../components';

const DocumentLinks: React.FC = () => {

   const openPdf = (url) => {
      const win = window.open(url, "_blank");
      win.focus();
   }
   return (
      <div className='main-container'>
         <div className='module-row' id='module-row-no-margin'>
            <div className="bk-text-blue-big flex-5"> Linker </div>
            <div className='flex-1'>
               <a href="/documents" className='dialog-button'>
                  Tilbake til hovedmeny
               </a></div>
         </div>

         <div className="btn_grid">
            <div className="dashboard-btn" id="dashboard-link-btn">
               <FontAwesomeIcon icon={faLink} className="fa-2x" />
               <a href="https://lovdata.no/dokument/SF/forskrift/2021-06-08-1850" target="_blank" className='dashboard-btn-text'>
                  Forskrift til avhendingslova (tryggere bolighandel)
                  <br></br>
                  1. januar 2022
               </a>
            </div>
            <div className="dashboard-btn" id="dashboard-link-btn">
               <FontAwesomeIcon icon={faLink} className="fa-2x" />
               <a href="https://dibk.no/regelverk/forskrift-til-avhendingslova-tryggere-bolighandel/" target="_blank" className='dashboard-btn-text'>
                  Ny forskrift til avhendingslova (tryggere bolighandel)
                  <br></br>
                  1. januar 2022
               </a>
            </div>
            <div className="dashboard-btn " id="dashboard-link-btn">
               <FontAwesomeIcon icon={faLink} className="fa-2x" />
               <a href="https://www.digitalarkivet.no/" target="_blank" className='dashboard-btn-text'>
                  Digitalarkivet</a>
            </div>
            <div className="dashboard-btn " id="dashboard-link-btn">
               <FontAwesomeIcon icon={faLink} className="fa-2x" />
               <a href="https://www.kartverket.no/" target="_blank" className='dashboard-btn-text'>
                  Kartverket</a>
            </div>

            <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Rundskriv - Krav til verdsettelse av fast eiendom.pdf')}>
               <FontAwesomeIcon icon={faDollar} className="fa-2x flex-05" />
               <div className='dashboard-btn-text flex-3'>
                  Verdsettelse av fast eiendom. 
                  <div>Kan sendes til bank ifm. valideringskode.</div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default DocumentLinks;