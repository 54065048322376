import React from "react";
import './BkButtonSmall.scss';

function BkButtonSmall(props) {

  function handleClick(e) {
    e.preventDefault();
    if (props.onClickHandler !== undefined) {
      if (props.arg !== undefined) {
        props.onClickHandler(props.arg);
      }
      else {
        props.onClickHandler();
      }
    }
  }

  return <div className={['bkbutton-small' + ' ' + (props.disabled == true ? 'bkbutton-disabled' : '') + ' ' + (props.color != "" ? 'bkbutton-' + props.color : '')].join() } onClick={(e) =>handleClick(e)}>{props.caption}</div>
}

export default BkButtonSmall;