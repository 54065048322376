import React, {useState} from 'react';
import './ModuleHeader.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { count } from 'console';

interface ModuleHeaderProps {
    title: string,
    menu?: JSX.Element,
    input?:JSX.Element,
    color?:string,
    show?:boolean,
    onClicked?:()=>void
    helpText?:string
}


export const ModuleHeader: React.FC<ModuleHeaderProps> = ({ title, menu, color, show, onClicked ,helpText}) => {

      return <> 
            <div className={[color,'module-header module-row cursor-pointer'].join(' ')} onClick={onClicked} title={helpText}>
         { show ?
            <div className='module-header-icon bk-icon'><FontAwesomeIcon icon={faCaretDown}/></div>
            :
            <div className='module-header-icon bk-icon'><FontAwesomeIcon icon={faCaretRight}/></div>
         }
         <div className='title'>{title}</div>
         </div>
      </>
   }