import React, { useState, useEffect, useRef } from 'react';
import { actionCreators } from '../../store/auth';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { useHistory } from 'react-router-dom'
import { AdminApi } from '../../api/admin.service';
import AdminMenu from './child-components/AdminMenu';
import StatisticsCard from './child-components/StatisticsCard';
import { StatisticsBox, Spinner } from '../../components';
import {
   Chart as ChartJS,
   CategoryScale,
   LinearScale,
   BarElement,
   Title,
   Tooltip,
   Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
   CategoryScale,
   LinearScale,
   BarElement,
   Title,
   Tooltip,
   Legend
);

type AdminProps = typeof actionCreators &
{
   readonly isAuthenticated: boolean;
   readonly isAdmin: boolean;
};

const Admin: React.FC<AdminProps> = ({
   isAuthenticated,
   isAdmin,
   logoutUser
}) => {

   const [loading, setLoading] = useState(true);
   const [statistics, setStatistics] = useState({
      acceptedUsers: {},
      projects: {},
      services: {
         name: '',
         total: 0,
         year: 0,
         month: 0,
         week: 0,
         day: 0
      },
      technicalCalculations: [],
      otherCalculations: [],
      calculations: [],
      reports: []
   });
   const history = useHistory();

   const [messageNumber, setMessageNumber] = useState(0);
   useEffect(() => {
      //Get number to messages
      AdminApi.getMessageCount()
         .then(
            (response) => {
               setMessageNumber(response.data);
            },
            (error) => {
               console.log(error);
            });

   })

   useEffect(() => {
      fetchData();
   }, [])

   const fetchData = async () => {
      AdminApi.getStatistics()
         .then(
            (response) => {
               setStatistics(response.data);
               setLoading(false);
            },
            (error) => {
               console.log(error);
            });
   }


   if (!isAuthenticated) {
      logoutUser();
      history.push("/login");
   }

   if (!isAdmin) {
      history.push("/projects");
   }

   const handleSubmit = () => {
      //event.preventDefault();
      AdminApi.resyncMailMojo()
         .then(() => {
            //console.log("MailMojo updated");
         });
   }

   let number = null
   if (messageNumber == 0) {
      number = <span className='messagebox-number-zero'>{messageNumber}</span>

   }
   else {
      number = <span className='messagebox-number-nonzero'>{messageNumber}</span>

   }

   const formatNumber = (number) => {
      if (number == 0) {
         return ' ';
      }

      if (number != null && number != undefined) {
         return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      }
   }


   return (
      <>
         <div className="admin-container">
            <AdminMenu activeMenuItem={'Dashboard'}></AdminMenu>

            <div className='mt-2 module-row align-items-top'>
               <div className='flex-01'></div>
               <div className='flex-1'>
                  <StatisticsCard stats={statistics.acceptedUsers} ></StatisticsCard>
                  <StatisticsCard stats={statistics.projects} ></StatisticsCard>
                  <StatisticsCard stats={statistics.services} ></StatisticsCard>
               </div>
               <div className='flex-01'></div>
               <div className='flex-4'>
                  <StatisticsBox title={'Rapporter'} data={statistics.reports}></StatisticsBox>
                  <StatisticsBox title={'Teknisk-kalkulasjon privat'} data={statistics.calculations}></StatisticsBox>
                  <div className='statistics-header-row statistics-sum-row module-row' id='module-row-no-margin'>
                     <span className="flex-3">SUM</span>
                     <span className="flex-1 align-right">{formatNumber(statistics.services.total)}</span>
                     <span className="flex-1 align-right">{formatNumber(statistics.services.year)}</span>
                     <span className="flex-1 align-right">{formatNumber(statistics.services.month)}</span>
                     <span className="flex-1 align-right">{formatNumber(statistics.services.week)}</span>
                     <span className="flex-1 align-right">{formatNumber(statistics.services.day)}</span>
                  </div>
               </div>
               <div className='flex-2'></div>
            </div>

            <Spinner isLoading={loading} />
         </div >

      </>
   );
}

const mapStateToProps = (state: IApplicationState) => ({
   isAuthenticated: state.auth.isAuthenticated,
   isAdmin: state.auth.isAdmin
});

export default connect(mapStateToProps, actionCreators)(Admin);