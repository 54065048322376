import React from 'react'
import {CategoryHeader, SectionHeaderModule} from '.'
import parse from 'html-react-parser';

function FixedTextBox(props) {

   return <>
   
         <SectionHeaderModule title={props.title}/>
         <div className="introduction-text">
            {parse(props.text)}
         </div>
   </>
}
  
export default FixedTextBox;