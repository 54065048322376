import React, { useState, useEffect, useRef } from 'react'
import { SectionHeader, ModuleComment } from '.'
import { ModuleSectionApi } from '../api';
import { TextLineInput } from '../components'

type ModuleSectionProps = {
   readonly moduleId: number;
   readonly readOnly: boolean;
};

const keyPressTimer = 1000;
let inputTimer = null;

function ModuleSection(props: ModuleSectionProps) {

   const [sections, setSections] = useState([]);


   useEffect(() => {
      //Fetch comments for this module
      ModuleSectionApi.getModuleSection(props.moduleId)
         .then(
            (response) => {
               setSections(checkIfSectionShouldBeAdded(response.data));
            },
            (error) => {
               console.log(error);
            })
   }, [props]);


   const checkIfSectionShouldBeAdded = (array) => {

      var add = true;
      if (array.length > 0) {
         var last = array[array.length - 1];
         if (last.title == null || last.title == '') {
            add = false;
         }
      }
      if (add)
         return addBlank(array);
      else
         return array;
   }

   const addBlank = (array) => {
      var blank = {
         moduleSectionId: 0,
         moduleId: props.moduleId,
         title: '',
         order: array.length,
         deleted: false
      }
      array.push(blank);

      ModuleSectionApi.addModuleSection(blank)
         .then(
            (response) => {
               var updated = sections.map(item =>
                  item.moduleSectionId === 0
                     ? { ...item, moduleSectionId: response.data.moduleSectionId }
                     : item);
               setSections(updated)
            },
            (error) => {
               console.log(error);
            })

      return array;
   }



   const handleChange = (value, id, name) => {
      setSections(
         sections.map(item =>
            item.moduleSectionId === id
               ? { ...item, [name]: value }
               : item
         ))
      var copy = sections.find(c => c.moduleSectionId == id);
      if (copy == null) {
         return;
      }
      copy[name] = value;
      saveSection(copy);
   }

   const saveSection = (section) => {
      ModuleSectionApi.updateModuleSection(section)
         .then(
            (response) => {
               setSections(checkIfSectionShouldBeAdded(sections))
            },
            (error) => {
               console.log(error);
            })
   }

   var sectionsHtml = null;
   if (sections) {
      sectionsHtml = sections.map((section, index) => {
         let inputHtml = (<>
            <TextLineInput readOnly={props.readOnly} name="title" id={section.moduleSectionId} placeholder="Valgfri overskrift" value={section.title}
               onValueChangeCb={handleChange} />
         </>);

         return (
            <>
               <SectionHeader title={''} input={inputHtml}></SectionHeader>
               <div className='content-frame'>
                  <div className="module-row">
                  <div className='flex-1'>
                        <ModuleComment moduleId={props.moduleId} sectionId={section.moduleSectionId} multiComment={true} readOnly={props.readOnly} displayFrame={false} phraseKey={'moduleSection.information' + section.moduleSectionId}></ModuleComment>
                     </div>
                  </div>
               </div>
            </>
         )
      });
   }


   return <>
      {sectionsHtml}
   </>
}

export default ModuleSection;