import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUser } from '@fortawesome/free-solid-svg-icons'
import { Menu, Divider } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import { Route as ReactRoute } from 'react-router';
import { History } from 'history';
import { useHistory } from "react-router-dom";

interface UserMenuProps {
    isLoggedIn: boolean;
    userName: string;
    logoutUserRequest: (callback: () => void) => void;
}   

const UserMenu: React.FC<UserMenuProps> = ({logoutUserRequest,isLoggedIn, userName}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const history = useHistory();
    
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleMyProfile = () => {
      setAnchorEl(null);
      history.push('/profile');
    }

    const handleLogout = (history: History<any>) => (): void => {
        const onLogoutCallbackFn = () => history.push("/login");
        logoutUserRequest(onLogoutCallbackFn);
      };

    if (isLoggedIn) {
      return <div aria-controls="fade-menu" aria-haspopup="true">
        <FontAwesomeIcon icon={faBars} onClick={handleClick} className='navbar-user-menu'/>
        <Menu
          id="fade-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <MenuItem disabled className="navbar-dropdown-menu-text"> 

          {userName}

          <FontAwesomeIcon icon={faUser}  onClick={handleClick} className="fa-sm"/>  

           </MenuItem>
          <MenuItem disabled className="navbar-dropdown-menu-text">Takstmann</MenuItem>
          <Divider />
          <MenuItem onClick={handleMyProfile} className="navbar-dropdown-menu-text navbar-dropdown-menu-btn">Min side</MenuItem>
          <ReactRoute
              render={({ history }) => (
                <MenuItem onClick={handleLogout(history)} className="navbar-dropdown-menu-text navbar-dropdown-menu-btn">Logg ut</MenuItem>
              )}
            />  
        </Menu>
        </div>;
    }
    return <></>;
};

export default UserMenu;

