import React from 'react';
import { BrowserRouter, Switch, Route, Redirect, Link } from "react-router-dom";
const About: React.FC = () => {

  return (
    <div className="main-container">
       <div className='module-row' id='module-row-no-margin'>
            <div className='flex-5'></div>
            <div className='flex-1'>
               <a href="/documents" className='dialog-button'>
                  Tilbake til hovedmeny
               </a></div>
         </div>
      <div className="report-content">
        <div className="about-text">
          <div className="bk-text-blue-big">Velkommen til Byggekost.no</div>
          <div className='about-text-content mt-3'>
          Byggekost.no ble utviklet i 2013 med en en visjon om å gjøre teknisk kalkulasjon av eksisterende bygninger enklere og raskere. Etter flere års grundig utvikling og testing ble programmet tilgjengelig for et utvalg testpersoner i NITO-Takst i 2017. Allerede i 2018 ble Byggekost.no gjort tilgjengelig for takstmenn over hele Norge.

          Solid erfaring og bransjekompetanse.
          I dag eies Byggekost.no av Pro Verk AS, et selskap grunnlagt i 2018 av Erik Sørensen og Runar Gautestad. Begge har lang og solid erfaring fra byggebransjen (fra 1980) og takstbransjen (fra 1997). Med fagbrev og mesterbrev i tømrerfaget, teknisk fagskole og takstutdanning med tilhørende godkjenninger, bringer de unik kompetanse til utviklingen av Byggekost.no.

          Mer enn bare teknisk kalkulasjon
          Byggekost.no leverer i dag et bredt spekter av tjenester som dekker behovene til takstmenn over hele landet. I tillegg til teknisk kalkulasjon av bygninger, tilbyr vi skjematur og løsninger for:
          <div className="module-row my-4 align-items-top">
            <ul className='flex-1'>
              <li>Arealrapport</li>
              <li>Brevtakst</li>
              <li>Byggelånskontroll</li>
            </ul>
            <ul className='flex-1'>
              <li>Forhåndstakst</li>
              <li>Landbrukstakst</li>
              <li>Næringstakst</li>
            </ul>
            <ul className='flex-1'>
              <li>Reklamasjonsrapport</li>
              <li>Skaderapport</li>
              <li>Tilbudsbrev</li>
            </ul>
            <ul className='flex-1'>
              <li>Tilstandsrapport</li>
              <li>Tomtetakst</li>
              <li>Vedlikeholdsrapport</li>
            </ul>
            <ul className='flex-1'>
              <li>Verditakst</li>
              <li>Verdivurdering</li>
            </ul>
          </div>
          Skreddersydd for takstbransjen
          Våre produkter og tjenester er spesialutviklet for å forenkle og effektivisere arbeidshverdagen til takstmenn. Med Byggekost.no får du tilgang til et brukervennlig, pålitelig og nøyaktig verktøy som er utviklet av eksperter med inngående forståelse og kunnskap om bransjen.

          Opplev hvordan Byggekost.no kan forbedre din arbeidsflyt og bidra til økt kvalitet og effektivitet i dine tjenester.

          Ta kontakt i dag!
          Er du klar for å ta din takstvirksomhet til neste nivå? Ta kontakt med oss for en uforpliktende prat, og lær mer om hvordan Byggekost.no kan hjelpe deg!
          </div> 
          <div className='mt-4'><img style={{ width: "14rem" }} alt="Pro verk AS" src="\Content\images\design\Proverk_logo.svg" /><div>

            <div className="module-row mt-5 align-center">
              <div className="flex-1"></div>
              <div className="flex-1">
                <img style={{ width: "12rem" }} alt="Runar Gautestad" src="\Content\images\bilde-runar.jpg" className="web-about-img" />
                <div className="bk-text-blue mt-2" >
                  Runar Gautestad
                  <div className="bk-text-blue bold" >(+47) 379 30 707</div>
                </div>
              </div>
              <div className="flex-1">
                <img style={{ width: "12rem" }} alt="Erik Sørensen" src="\Content\images\bilde-erik.jpg" className="web-about-img" />
                <div className="bk-text-blue mt-2">
                  Erik Sørensen
                  <div className="bk-text-blue bold">(+47) 906 35 160</div>
                </div>
              </div>
              <div className="flex-1"></div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );

}
export default About;