import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { Menu, Divider } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import { SimpleCalculationApi } from '../../../api';
import { NumericInput, TextLineInput } from '../../../components';

const keyPressTimer = 1000;
let infoTimer = null;

const SimpleCalcInfoRow = ({ infoRow, handleInfoMoveUpCb, handleInfoMoveDownCb, handleInfoDeleteCb, handleInfoUpdatedCb }) => {
  const [info, setInfo] = React.useState({
    simpleCalculationInfoId: 0,
    name: '',
    text: '',
    order: 0
  });

  useEffect(() => {
    setInfo(infoRow);
  }, [infoRow])

  const handleInfoChange = function (e) {
    const { name, value } = e.target;
    let changedInfo = info;

    if (name === "text") {
      changedInfo.text = value;
    }
    else {
      changedInfo.name = value;
    }
    setInfo(info => ({ ...info, [name]: value }));

    if (infoTimer !== null) {
      clearTimeout(infoTimer);
    }

    infoTimer = setTimeout(() => {
      updateInfo(changedInfo);
    }, keyPressTimer);
  }

  const updateInfo = function (info) {

    SimpleCalculationApi.updateInfo(info)
      .then(
        (response) => {
          handleInfoUpdatedCb(info);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  const handleInfoMoveUp = function (infoId) {
    handleInfoMoveUpCb(infoId);
  }

  const handleInfoMoveDown = function (infoId) {
    handleInfoMoveDownCb(infoId);
  }

  const handleInfoDelete = function (infoId) {
    handleInfoDeleteCb(infoId);
  }


  function ActionMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return <div aria-controls="fade-menu" aria-haspopup="true" className='navbar-profile'  >
      <FontAwesomeIcon icon={faBars} onClick={handleClick} />
      <Menu
        elevation={0}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        className="navbar-dropdown-menu"
      >
        <MenuItem onClick={() => handleInfoMoveUp(props.infoId)}>Flytt opp</MenuItem>
        <MenuItem onClick={() => handleInfoMoveDown(props.infoId)}>Flytt ned</MenuItem>
        <Divider />
        <MenuItem onClick={() => handleInfoDelete(props.infoId)}>Slett</MenuItem>
      </Menu>
    </div>;

  }




  return (
    <>
      <div key={info.simpleCalculationInfoId} className="module-row">
        <div className="flex-3">
          <TextLineInput placeholder="Emne" name="name" value={info.name} onValueChangeCb={(e) => handleInfoChange(e)} />
        </div>
        <div className="flex-5">
          <TextLineInput placeholder="Tekst" name="text" value={info.text} onValueChangeCb={(e) => handleInfoChange(e)} />
        </div>
        <div className="bk-icon" title="Alternativer">
          <ActionMenu infoId={info.simpleCalculationInfoId} />
        </div>
      </div>
    </>
  )

}
export default React.memo(SimpleCalcInfoRow);