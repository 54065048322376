import React from 'react'

function StatisticsBox(props) {

   var totalTotal = 0;
   var totalYear = 0;
   var totalMonth = 0;
   var totalWeek = 0;
   var totalDay = 0;

   const getRowClass = function(index) {
      if (index % 2 === 0) {
        return "statistics-data-row module-row white-row"
      }
      else {
        return "statistics-data-row module-row grey-row"
      }
    }

    const formatNumber = (number) => {
      if (number == 0){
         return ' ';
      }
      
      if (number != null && number != undefined) {
         return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      }
   }

   var reportdataHtml = null;
   if (props.data) {
      reportdataHtml = props.data.map((data, index) => {
         return <>
         <div className={getRowClass(index)} id='module-row-no-margin'>
            <div className="flex-3">{data.name}</div>
            <div className="flex-1 align-right">{formatNumber(data.total)}</div>
            <div className="flex-1 align-right">{formatNumber(data.year)}</div>            
            <div className="flex-1 align-right">{formatNumber(data.month)}</div>
            <div className="flex-1 align-right">{formatNumber(data.week)}</div>
            <div className="flex-1 align-right">{formatNumber(data.day)}</div>
         </div>
         </>
      });
   }

   if (props.data) {
      props.data.map((data, index) => {
         totalTotal += data.total;
         totalYear += data.year;
         totalMonth += data.month;
         totalWeek += data.week;
         totalDay += data.day;
      });
   }

   const statisticsHtml = () => {
      if (reportdataHtml.length != 0) {
            return <>
               <div className="statistics-box">
                  <div className="statistics-header-row module-row" id='module-row-no-margin'>
                     <div className="flex-3">{props.title}</div>
                     <div className="flex-1 align-right">Totalt</div>
                     <div className="flex-1 align-right">År</div>
                     <div className="flex-1 align-right">Måned</div>
                     <div className="flex-1 align-right">Uke</div>
                     <div className="flex-1 align-right">Idag</div>
                  </div>
                  {reportdataHtml}
                  <div className='statistics-header-row statistics-sum-row module-row' id='module-row-no-margin'>
                     <span className="flex-3">SUM</span>
                     <span className="flex-1 align-right">{formatNumber(totalTotal)}</span>
                     <span className="flex-1 align-right">{formatNumber(totalYear)}</span>
                     <span className="flex-1 align-right">{formatNumber(totalMonth)}</span>
                     <span className="flex-1 align-right">{formatNumber(totalWeek)}</span>
                     <span className="flex-1 align-right">{formatNumber(totalDay)}</span>
                  </div>
               </div>
            </>
      }
   };
   

   return <>
      {statisticsHtml()}
   </>
}
  
export default StatisticsBox;