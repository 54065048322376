import React from 'react';
import { useHistory } from 'react-router-dom'
import { TextField, Button } from '@material-ui/core'
import { HomeApi } from '../../api';
import { RoutesConfig } from '../../config/routes.config';
import { ContactButton } from '../../components';
import { BkButton } from '../../components';
import Form from 'react-bootstrap/Form';

const Contact: React.FC = () => {
   const [values, setValues] = React.useState({
      name: '',
      email: '',
      title: '',
      message: '',
   });
   const history = useHistory();

   const handleChange = name => event => {
      setValues({ ...values, [name]: event.target.value });
   };

   const handleSubmit = () => {
      //event.preventDefault();
      HomeApi.sendMail(values.name, values.email, values.title, values.message)
         .then(() => {
            history.push(RoutesConfig.ContactConfirmation.path)
         });
   }

   return (
      <div className="main-container">
         <div className="module-row">
            <div className='flex-1'></div>
            <div className='flex-5'>
               <div className="dialog-container shadow">
               <Form>
                  <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                     <Form.Label className='dialog-title'>Navn</Form.Label>
                     <Form.Control onChange={handleChange('name')} type="text" placeholder="Navn Etternavn" className='contact-content' />
                  </Form.Group>

                  <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                     <Form.Label className='dialog-title'>E-post</Form.Label>
                     <Form.Control onChange={handleChange('email')} type="email" placeholder="navn@eksempel.no"  className='contact-content'/>
                  </Form.Group>

                  <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                     <Form.Label className='dialog-title'>Emne</Form.Label>
                     <Form.Control onChange={handleChange('title')} type="text" placeholder='Type oppdrag' className='contact-content' />
                  </Form.Group>

                  <Form.Group className="mb-2" controlId="exampleForm.ControlTextarea1">
                     <Form.Label className='dialog-title'>Melding</Form.Label>
                     <Form.Control onChange={handleChange('message')} as="textarea" rows={3} placeholder="Fritekst"  className='contact-autoTextBox'/>
                  </Form.Group>

                  <div className="module-row mt-4 justify-end ">
                     <button className="dialog-button" onClick={handleSubmit}>Send e-post</button>
                  </div>
               </Form>
            </div>
            </div>
            <div className='flex-1'></div>
         </div>
      </div>
   )
}

export default Contact;