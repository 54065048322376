import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { actionCreators } from '../../store/auth';
import { useHistory } from 'react-router-dom'
import { TextField, Checkbox, FormControlLabel } from '@material-ui/core'
import { MyProfileApi } from '../../api';
import { RoutesConfig } from '../../config/routes.config';
import { BkButton } from '../../components';
import Grid from '@material-ui/core/Grid'
import { useForm } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles';
import Form from 'react-bootstrap/Form';
import { isNullOrEmpty } from '../../utils';


type InvoiceProps = typeof actionCreators &
{
   readonly isAuthenticated: boolean;
   readonly role: number;
   readonly userName: string;
};


const Invoice: React.FC<InvoiceProps> = ({
   isAuthenticated,
   userName,
   role,
   logoutUser
}) => {

   const [useCompanyAddress, setUseCompanyAddress] = useState(false)
   const [ehf, setEhf] = useState(false)
   const [values, setValues] = useState({
      userId: 0,
      company: '',
      organisationNumber: '',
      address: '',
      zipCode: '',
      city: '',
      email: '',
      invoiceAddress: '',
      invoiceZipCode: '',
      invoiceCity: '',
      invoiceEmail: '',
      ehf: false
   });

   const [companyError, setCompanyError] = useState(false);
   const [organisationNumberError, setOrganisationNumberError] = useState(false);
   const [invoiceAddressError, setInvoiceAddressError] = useState(false);
   const [invoiceCityError, setInvoiceCityError] = useState(false);
   const [invoiceZipCodeError, setInvoiceZipCode] = useState(false);
   const [invoiceEmailError, setInvoiceEmail] = useState(false);


   useEffect(() => {
      const fetchData = async () => {
         MyProfileApi.getUserData()
            .then(
               (response) => {
                  setValues(response.data.info);
               },
               (error) => {
                  console.log(error);
               });
      }

      fetchData();
   }, [])


   const { register, handleSubmit, errors, formState } = useForm({
      mode: "all"
   });


   const history = useHistory();
   if (!isAuthenticated) {
      logoutUser();
      history.push(RoutesConfig.Login.path);
   }



   const handleChange = name => event => {

      setCompanyError(false);
      setOrganisationNumberError(false);
      setInvoiceAddressError(false);
      setInvoiceCityError(false);
      setInvoiceZipCode(false);
      setInvoiceEmail(false);

      setValues({ ...values, [name]: event.target.value });
   };

   const handleChangeUseCompanyAddress = () => {
      if (!useCompanyAddress) {
         setValues({ ...values, invoiceAddress: values.address, invoiceZipCode: values.zipCode, invoiceCity: values.city, invoiceEmail: values.email });
      }
      setUseCompanyAddress(!useCompanyAddress);
   }

   const handleChangeEhf = () => {
      setValues({ ...values, ehf: !ehf});
      setEhf(!ehf);
   }


   const handleSave = () => {

      var error = false;
      if (isNullOrEmpty(values.company)) {
         setCompanyError(true);
         error = true;
      }
      if (isNullOrEmpty(values.organisationNumber)) {
         setOrganisationNumberError(true);
         error = true;
      }
      if (isNullOrEmpty(values.invoiceAddress)) {
         setInvoiceAddressError(true);
         error = true;
      }
      if (isNullOrEmpty(values.invoiceCity)) {
         setInvoiceCityError(true);
         error = true;
      }
      if (isNullOrEmpty(values.invoiceZipCode)) {
         setInvoiceZipCode(true);
         error = true;
      }

      if (isNullOrEmpty(values.invoiceEmail)) {
         setInvoiceEmail(true);
         error = true;
      }

      if (!error) {

         var info = {
            userId: values.userId,
            company: values.company,
            organisationNumber: values.organisationNumber,
            invoiceAddress: values.invoiceAddress,
            invoiceZipCode: values.invoiceZipCode,
            invoiceCity: values.invoiceCity,
            invoiceEmail: values.invoiceEmail,
            ehf: ehf
         }

         MyProfileApi.setInvoiceInfo(info)
            .then(
               (response) => {
                  history.push(RoutesConfig.Projects.path);
               },
               (error) => {
                  console.log(error);
               });
      }


   }

   const useTextFieldStyles = makeStyles(() => ({
      root: {
         '& label.Mui-focused': {
            color: '#7DA868',
         },
         '& .MuiInput-underline:after': {
            borderBottomColor: '#7DA868',
         },
      }
   }));

   const textFieldStyles = useTextFieldStyles(false);


   return (
      <>
         <div className="container">
            <div className='col-6 mx-auto report-content'>
               <div className="invoice-heading">Rediger firmainformasjon:</div>
               <Form /*onSubmit={handleSave}*/>
                  <Form.Group>
                     <div className='mx-3 row'>

                        <div className='col-6'>
                           <TextField className={textFieldStyles.root} autoFocus
                              name="company" label="Firmanavn" error={companyError}
                              onChange={handleChange('company')} required value={values.company} fullWidth />
                        </div>

                        <div className='col-6'>
                           <TextField className={textFieldStyles.root}
                              name="organisationNumber" label="Org.nr." required error={organisationNumberError}
                              onChange={handleChange('organisationNumber')} value={values.organisationNumber} fullWidth />
                        </div>

                     </div>

                  </Form.Group>
                  <div className="invoice-subtitle-flex">
                     <div className="invoice-heading-small">Faktura adresse</div>
                     <div className=''>
                        <FormControlLabel className='invoice-control-checkbox-label' control={
                           <Checkbox
                              className="invoice-control-checkbox"
                              checked={useCompanyAddress}
                              onChange={handleChangeUseCompanyAddress}
                              name="useCompanyAddress" />
                        }
                           label="Bruk lagret firmaadresse"
                        />
                     </div>
                  </div>
                  <Form.Group>
                     <div className='row mx-3'>
                        <div className=' col-12 mx-auto'>
                           <TextField className={textFieldStyles.root}
                              name="invoiceAddress" error={invoiceAddressError}
                              label="Adresse" required onChange={handleChange('invoiceAddress')} value={values.invoiceAddress} fullWidth />
                        </div>
                     </div>
                     <div className='row mx-3 mt-3'>
                        <div className=' col-6'>
                           <TextField className={textFieldStyles.root}
                              name="invoiceCity" error={invoiceCityError}
                              label="Sted" required onChange={handleChange('invoiceCity')} value={values.invoiceCity} fullWidth />
                        </div>
                        <div className=' col-6'>
                           <TextField className={textFieldStyles.root}
                              name="invoiceZipCode" error={invoiceZipCodeError}
                              label="Postnr." required onChange={handleChange('invoiceZipCode')} value={values.invoiceZipCode} fullWidth />
                        </div>
                     </div>

                     <div className='row mx-3 mt-3'>
                        <div className=' col-12 mx-auto'>
                           <TextField className={textFieldStyles.root}
                              name="invoiceEmail" error={invoiceEmailError}
                              label="E-post faktura" required onChange={handleChange('invoiceEmail')} value={values.invoiceEmail} fullWidth />
                        </div>
                     </div>
                  </Form.Group>

                  <div className="invoice-subtitle-flex">
                     <div className="invoice-heading-small">Ønskes EHF faktura?</div>
                     <div className=''>
                        <Checkbox
                           className="invoice-control-checkbox"
                           checked={values.ehf}
                           onChange={handleChangeEhf}
                           name="ehf" />
                     </div>
                  </div>

                  <div className='module-row my-4 justify-end me-5'>
                     <BkButton caption='Lagre' onClickHandler={handleSave} />
                  </div>
               </Form>

            </div>
         </div>
      </>
   )
}

const mapStateToProps = (state: IApplicationState) => ({
   isAuthenticated: state.auth.isAuthenticated,
   role: state.auth.role,
   userName: state.auth.userName,
});

export default connect(mapStateToProps, actionCreators)(Invoice);