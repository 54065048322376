import React, {useState, useEffect} from 'react'
import {UploadImage} from '../../../components'



interface ApprovalMarkProps {
   id: number,
   url:string,
   description:string,
   readOnly?:boolean,
   savePictureCb?: (id,url,description) => void
}

const keyPressTimer = 1000;
let nameTimer = null;

export const ApprovalMarkPicture: React.FC<ApprovalMarkProps> = ({ id, url, description, readOnly, savePictureCb }) => {


   const [pictureUrl, setPictureUrl] = useState('');
   const [subtitle, setSubtitle] = useState('');

   useEffect(() => {
      setPictureUrl(url);
      setSubtitle(description);
   }, [])


   const onSubtitleChange = (e) => {
      const { name, value } = e.target;
      setSubtitle(value);

      if (nameTimer !== null){
         clearTimeout(nameTimer);
       }
       nameTimer = setTimeout(() => {
         updatePicture(value, pictureUrl);
       }, keyPressTimer);  
   }



   const updatePicture =(subtitle,url) => {
      //Store
      savePictureCb(id, url, subtitle);
   }

  
    const handleImageUrlUpdated = function(newUrl) {
       //Todo delete
      setPictureUrl(newUrl);
      updatePicture(subtitle,newUrl);
    }
  
    return <>
    <div className="approval-mark-picture">
       <UploadImage 
         readOnly= {readOnly}
          pictureUrl={url}
          onUrlChangedCb={handleImageUrlUpdated}
          />
       <textarea name="text" placeholder='Bilde kommentar' readOnly={readOnly} className="bk-input-field mt-2 bk-input-align-center" rows={1} value={subtitle} onChange={(e) => onSubtitleChange(e)}/>
    </div>

 </>
 
}
  
export default ApprovalMarkPicture;