import React, {useState, useEffect} from 'react';
import { actionCreators} from '../../store/auth';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { useHistory, useParams } from 'react-router-dom'
import {AdminApi, AuthApi, ReportApi} from '../../api';
import AdminMenu from './child-components/AdminMenu'
import Table from 'react-bootstrap/Table';
import { Chart as ChartJS, CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend, } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  export const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom' as const,
        labels: {
  
        }
      },
      title: {
        display: true,
        position: 'top' as const,
        text: 'Oversikt over nye brukere',
        font: {
            size: 20
        },
        padding: {
            bottom: 20
        }
      },
    },
  };



  export const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'Betaler',
        data: [12, 19, 3, 5, 2, 3],
        borderColor: '#44758C',
        backgroundColor: '#44758C',
    },
    {
      label: 'Betaler ikke',
      data: [10, 9, 3, 4, 1, 3],
      borderColor: '#7DA868',
      backgroundColor: '#7DA868',
    },
  ],
};



export const options_bar = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom' as const,
    },
    title: {
      display: true,
      position: 'top' as const,
        text: 'Oversikt over tjenester',
        font: {
            size: 20
        },
        padding: {
            bottom: 20
        }
    },
  },
};


export const data_bar = {
  labels: ['Rapport', 'Kalkulasjon', 'Annet',],
  datasets: [
    {
      label: "Antall",
      data: [19, 12, 0, ],
      backgroundColor: '#44758C',
    }
  ],
};




type StatisticsProps = typeof actionCreators &
{
  readonly isAuthenticated: boolean;
  readonly isAdmin: boolean;
};

const Statistics: React.FC<StatisticsProps> = ({ 
   isAuthenticated, 
   isAdmin,
   logoutUser
 }) => {

   const history = useHistory();


   if(!isAuthenticated) {
      logoutUser();
      history.push("/login");
   }

   if(!isAdmin) {
      history.push("/projects");
   }


   return (
      <>
           <div className="admin-container">
               <AdminMenu activeMenuItem={'Statistikk'}></AdminMenu>

               <div className="container">
                <div className='row'> 
                    <div className='bk-row-header bk-text-blue-big mb-4 mx-4'>Statistikk</div>
                </div>

                <div className='row mb-5'>
                    <div className='col-5 col-centered'><Line options={options} data={data} width="200px" /></div>
                    
                    <div className='col-5 col-centered'><Bar options={options_bar} data={data_bar} width="200px" /></div>
                   
                </div>
                   
                <div className='row'>
                    <div className='col-10 col-centered'> 
                    <Table striped bordered className=''>
                       <thead>
                           <tr>
                               <th>Navn</th>
                               <th>Oppdatert</th>
                               <th>Lagret</th>
                               <th>Endring</th>
                    
                           </tr>
                       </thead>
                       <tbody>
                           <tr>
                               <td>Navn</td>
                               <td>1295</td>
                               <td>345</td>
                               <td>65</td>
    
                           </tr>
                           <tr>
                               
                               <td>Navn</td>
                               <td>6543</td>
                               <td>567</td>
                               <td>785</td>
    
                           </tr>
                           <tr>
                               
                               <td>Navn</td>
                               <td>43223</td>
                               <td>23444</td>
                               <td>23</td>
                    
                           </tr>
                           <tr>
                               
                               <td>Navn</td>
                               <td>553</td>
                               <td>34</td>
                               <td>434</td>
                    
                           </tr>
                       </tbody>
                   </Table>
                    </div>
                
                </div>
                
               </div>
           </div>

      </>
   );
}

const mapStateToProps = (state: IApplicationState) => ({
   isAuthenticated: state.auth.isAuthenticated,
   isAdmin: state.auth.isAdmin
 });
 
 export default connect(mapStateToProps, actionCreators)(Statistics);