import React from 'react';
import ReactTooltip from 'react-tooltip';
import { formatValue } from '../../../utils'
import AreaTableRow from './AreaTableRow';
import { TextLineInput, NumericInput, BkButton } from '../../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight, faPenToSquare, faPrint, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'

const primaryAreaProperty = 27;
const secondaryAreaProperty = 28;

interface AreaTableProps {
  calculation: any,
  additions: any,
  buildYears: any,
  properties: any,
  displaySecondaryArea: boolean,
  readonly handleAreaChangeCb?: (area: any) => void;
  readonly deleteAdditionCb?: (id: any) => void;
  readonly newAdditioncb?: () => void;
  readOnly?: boolean;
}

function AreaTable(props: AreaTableProps) {

  const handleAreaChange = function (area) {
    props.handleAreaChangeCb(area);
  }

  const handleAreaDelete = function (id) {
    props.deleteAdditionCb(id);
  }

  const newAdditionHandler = function () {
    props.newAdditioncb();
  }

  const getTotalPrimaryArea = function () {
    var area = props.calculation.primaryArea;
    props.additions.map((addition) => {
      area = area + addition.calculation.primaryArea;
      return area;
    });
    return area;
  }

  const getTotalSecondaryArea = function () {
    var area = props.calculation.secondaryArea;
    props.additions.map((addition) => {
      area = area + addition.calculation.secondaryArea;
      return area;
    });
    return area;
  }

  const getTotalArea = function () {
    var area = getTotalPrimaryArea() + getTotalSecondaryArea();
    return area;
  }


  const getHelptext = function (id) {
    var text = "Not found";

    const getText = (value) => {
      const res = props.properties.find(element => element.propertyTypeId === value);
      const currentText = res ? res.helpText : "";
      return currentText;
    }

    if (id === 0) {
      text = getText(primaryAreaProperty);
    }
    else if (id === 1) {
      text = getText(secondaryAreaProperty);
    }

    return text;
  }

  const getPrimaryRoomText = function (id) {
    var text = "Not found";

    const setText = (value) => {
      let current = props.properties.find(element => element.propertyTypeId === value);
      const currentText = current ? current.name : "";
      return currentText;
    }

    if (id === 0) {
      text = setText(primaryAreaProperty);

    }
    if (id === 1) {
      text = setText(secondaryAreaProperty);
    }

    return text;
  }

  const getArea = (calc) => {
    return {
      calculationId: calc.calculationId,
      primaryArea: calc.primaryArea,
      secondaryArea: calc.secondaryArea,
      buildYear: calc.buildYear
    }
  }


  let addonsAreaHtml = null;
  if (props.additions) {
    addonsAreaHtml = props.additions.map((addition, index) => {
      return (
        <>
          <AreaTableRow id={addition.calculation.calculationId} name={'Tilbygg ' + (index + 1)} buildYears={props.buildYears} area={getArea(addition.calculation)} displaySecondaryArea={props.displaySecondaryArea} displayDelete={true} onAreaChangeCb={handleAreaChange} onDeleteCb={handleAreaDelete}></AreaTableRow>
        </>
      )
    });
  }

  return (
    <>
      <div className='module-row'>
        <div className='flex-2 module-row-title align-center'>Bygning</div>
        <div className='flex-1 module-row-title align-center'>Byggeår</div>


        {props.displaySecondaryArea &&
          <>
            <div className='flex-1 module-row-title align-center'>
              {getPrimaryRoomText(0)}
              <FontAwesomeIcon className='ms-2 bk-icon' icon={faQuestionCircle} data-for={"prom"} data-tip={getHelptext(0)} />
              <ReactTooltip place="right" id={"prom"} type="light" html={true} effect="solid" className="helptext-custom" />
            </div>
            <div className='flex-1 module-row-title align-center'>
              {getPrimaryRoomText(1)}
              <FontAwesomeIcon className='ms-2 bk-icon' icon={faQuestionCircle} data-for={"srom"} data-tip={getHelptext(1)} />
              <ReactTooltip place="right" id={"srom"} type="light" html={true} effect="solid" className="helptext-custom" />
            </div>
            <div className='flex-1 module-row-title align-center'>Sum</div>
            <div className='bk-icon'></div>
          </>
        }
        {!props.displaySecondaryArea &&
          <>
            <div className="flex-1 module-row-title align-center">
              <div className="display-flex">
                <div className="flex-1 area-table-header area-table-header-center">
                  BRA
                  <FontAwesomeIcon className='ms-2 bk-icon' icon={faQuestionCircle} data-for={"prom"} data-tip={getHelptext(0)} />
                  <ReactTooltip place="right" id={"prom"} type="light" html={true} effect="solid" className="helptext-custom" />
                </div>
              </div>

            </div>

            <div className="flex-1"></div>
            <div className="flex-3"></div>
          </>
        }
      </div>

      <AreaTableRow readOnly={props.readOnly} id={props.calculation.calculationId} name={'Opprinnelig bygning'} buildYears={props.buildYears} area={getArea(props.calculation)} displaySecondaryArea={props.displaySecondaryArea} displayDelete={false} onAreaChangeCb={handleAreaChange} onDeleteCb={handleAreaDelete}></AreaTableRow>

      {addonsAreaHtml}

      <div className='module-row'>
        <div className='flex-2 module-row-title align-center'></div>
        <div className='flex-1 module-row-title align-center'>Sum BRA</div>
        {props.displaySecondaryArea &&
          <>
            <div className='flex-1 module-row-title align-center'>
              <NumericInput readOnly={props.readOnly} disabled placeholder="0" suffix='m²' value={getTotalPrimaryArea()} />
            </div>
            <div className='flex-1 module-row-title align-center'>
              <NumericInput readOnly={props.readOnly} disabled placeholder="0" suffix='m²' value={getTotalSecondaryArea()} />
            </div>
            <div className='flex-1 module-row-title align-center'>
              <NumericInput readOnly={props.readOnly} disabled placeholder="0" suffix='m²' value={getTotalArea()} />
            </div>
          </>
        }
        {!props.displaySecondaryArea &&
          <>
            <div className='flex-1 module-row-title align-center'>
              <NumericInput readOnly={props.readOnly} disabled placeholder="0" suffix='m²' value={getTotalPrimaryArea()} />
            </div>
          </>
        }
        <div className='bk-icon'></div>
      </div>

      <div className="module-row">
        <div className="flex-1">
          <BkButton name="UseBuildYear" type="submit" caption='Legg til tilbygg' title="Trykk her for å legge til et tilbygg" onClickHandler={newAdditionHandler} />
        </div>
        <div className='flex-5'></div>
        <div className='bk-icon'></div>
      </div>

    </>
  )
}

export default React.memo(AreaTable);