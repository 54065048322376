import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { RoutesConfig } from '../../config/routes.config';
import { actionCreators } from '../../store/auth';
import { TextField, Select, MenuItem } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import { AuthApi } from '../../api';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { renderToastifyMsg } from '../../utils';
import Form from 'react-bootstrap/Form';
import { ContactButton } from '../../components';

const SignupSchema = Yup.object().shape({
   firstName: Yup.string().required('Må fylles ut'),
   lastName: Yup.string().required('Må fylles ut'),
   email: Yup.string().email('Ugyldig epost adresse').required('Må fylles ut'),
   company: Yup.string().required('Må fylles ut'),
   roleId: Yup.number().required('Må fylles ut'),
   department: Yup.string(),
   address: Yup.string().required('Må fylles ut'),
   zipCode: Yup.string().required('Må fylles ut'),
   city: Yup.string().required('Må fylles ut'),
   phoneNumber: Yup.string().required('Må fylles ut'),
   password: Yup.string()
      .min(4, 'Minimum 4 karakterer')
      .required('Må fylles ut'),
   confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passordene må matche')
      .required('Må fylles ut'),
});



const Register: React.FC = () => {

   const history = useHistory();
   const toastIdRef = useRef<string | number>('');

   const formik = useFormik({
      initialValues: {
         roleId: 1,
         company: '',
         department: '',
         address: '',
         zipCode: '',
         city: '',
         firstName: '',
         lastName: '',
         phoneNumber: '',
         email: '',
         password: '',
         confirmPassword: '',
      },
      validationSchema: SignupSchema,
      onSubmit: (values) => {
         var dataToSend = {
            roleId: values.roleId.toString(),
            company: values.company,
            department: values.department,
            address: values.address,
            zipCode: values.zipCode,
            city: values.city,
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber,
            email: values.email,
            password: values.password,
            confirmPassword: values.confirmPassword,
         }

         AuthApi.Register(dataToSend)
            .then(
               (response) => {
                  history.push(RoutesConfig.RegisterConfirmation.path)
               },
               (error) => {
                  var msg = "Registrering feilet";
                  if (error.message != undefined) {
                     msg = error.message;
                  }

                  if (!toast.isActive(toastIdRef.current)) {
                     toastIdRef.current = toast.error(
                        renderToastifyMsg(msg, 'exclamation-triangle')
                     );
                  }
               }
            );
      },
   });


   return (
      <div className="main-container ">
         <div className="module-row">
            <div className='flex-1'></div>
            <div className='flex-5'>
               <div className="dialog-container shadow">

                  <Form onSubmit={formik.handleSubmit}>
                     <div className='module-row mb-4'><div className='register-title'>Opprett konto</div></div>
                     <div className='module-row align-items-start'>
                        <div className="flex-3">
                           <div className='dialog-title mb-1'>Brukerinformasjon</div>
                           <div className='register-content'>
                              <Form.Control type="text" placeholder="Fornavn" required className="contact-content" id="firstName"
                                 value={formik.values.firstName} onChange={formik.handleChange} />
                              <Form.Control type="text" placeholder="Etternavn" required className="contact-content" id="lastName"
                                 value={formik.values.lastName} onChange={formik.handleChange} />
                              <Form.Control type="text" placeholder="Telefon" required className="contact-content" id="phoneNumber"
                                 value={formik.values.phoneNumber} onChange={formik.handleChange} />
                              <Form.Control type="text" placeholder="E-post" required className="contact-content" id="email"
                                 value={formik.values.email} onChange={formik.handleChange} />
                              <Form.Control type="password" placeholder="Passord" required className="contact-content" id="password"
                                 value={formik.values.password} onChange={formik.handleChange} />
                              <Form.Control type="password" placeholder="Bekreft passord" required className="contact-content" id="confirmPassword"
                                 value={formik.values.confirmPassword} onChange={formik.handleChange} />
                           </div>
                        </div>

                        <div className="flex-3">

                           <div className='dialog-title mb-1'>Firmainformasjon</div>
                           <div className='register-content'>
                              <Form.Control type="text" placeholder="Firmanavn" required className="contact-content" id="company"
                                 value={formik.values.company} onChange={formik.handleChange} />
                              <Form.Control type="text" placeholder="Adresse" required className="contact-content" id="address"
                                 value={formik.values.address} onChange={formik.handleChange} />
                              <div className='module-row'>
                                 <div className='flex-1'> <Form.Control type="text" placeholder="Postnr." required className="contact-content" id="zipCode"
                                    value={formik.values.zipCode} onChange={formik.handleChange} /></div>
                                 <div className='flex-3'> <Form.Control type="text" placeholder="Sted" required className="contact-content" id="city"
                                    value={formik.values.city} onChange={formik.handleChange} /></div>
                              </div>
                           </div>
                        </div>
                     </div>


                     <div className="module-row justify-end">
                        <button className="dialog-button" type="submit">Opprett konto</button>
                     </div>
                  </Form>
               </div>
            </div>
            <div className='flex-1'></div>
         </div>
      </div>
   );
};

const mapStateToProps = (state: IApplicationState) => state.auth;

export default connect(mapStateToProps, actionCreators)(Register);

