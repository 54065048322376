import React, { useState, useEffect } from 'react';
import { TextLineInput, NumericInput, SelectInput } from '../../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons'

interface AreaTableRowProps {
  id: any,
  name: any,
  area: any,
  buildYears: any,
  displaySecondaryArea: boolean,
  displayDelete: boolean,
  readonly onAreaChangeCb?: (area: any) => void;
  readonly onDeleteCb?: (id: any) => void;
  readOnly?: boolean;
}

function AreaTable(props: AreaTableRowProps) {

  const [area, setArea] = useState({
    primaryArea: 0,
    secondaryArea: 0,
    buildYear: 0
  });

  useEffect(() => {
    setArea(props.area);
  }, [props.id]);

  const onYearChange = (value) => {
    var intVal = parseInt(value);
    setArea(area => ({ ...area, buildYear: intVal }));

    var toBeUpdated = area;
    area.buildYear = intVal;

    props.onAreaChangeCb(toBeUpdated);
  }

  const handleChange = (value, id, name) => {
    var intVal = parseInt(value);
    if (isNaN(intVal)) {
      intVal = 0;
    }
    setArea(area => ({ ...area, [name]: intVal }));
    var toBeUpdated = area;
    toBeUpdated[name] = intVal;
    props.onAreaChangeCb(toBeUpdated);
  }

  const deleteArea = () => {
    props.onDeleteCb(props.id);
  }
 
  return (
    <>
      <div className='module-row'>
        <div className='flex-2'> <TextLineInput readOnly disabled value={props.name} /></div>
        <div className='flex-1'> <SelectInput selectedValue={area.buildYear} valueKey='year' nameKey='name' options={props.buildYears} onValueChangeCb={onYearChange} /></div>
        <div className='flex-1'>

          <NumericInput readOnly={props.readOnly} name="primaryArea" onValueChangeCb={handleChange} placeholder="0" suffix='m²' value={area.primaryArea} />
        </div>

        {props.displaySecondaryArea &&
          <>
            <div className='flex-1'>
              <NumericInput readOnly={props.readOnly} name="secondaryArea" onValueChangeCb={handleChange} placeholder="0" suffix='m²' value={area.secondaryArea} />
            </div>
            <div className='flex-1'>
              <NumericInput readOnly={props.readOnly} disabled placeholder="0" suffix='m²' value={area.primaryArea + area.secondaryArea} />
            </div>
          </>
        }
        {props.displayDelete &&
          <FontAwesomeIcon icon={faTimes} className="bk-icon delete-icon" name="deleteAddon" type="submit" onClick={() => deleteArea()} />
        }
        {!props.displayDelete &&
          <div className='bk-icon'></div>
        }
      </div>

      {/* <div className="display-flex area-table-row">
        <div className="flex-3 area-table-col area-table-border-left"><div>{props.name}</div></div>
        <div className="flex-2 area-table-col-input " >
          {getYearSelectorWithCurrentValue(area.buildYear, 0, optionsBuildYears)}
        </div>
        
        <div className="flex-2 ">
          <div className="display-flex">
            <div className="flex-1 area-table-col-input">
              <div className="input-icon area-table-header-input-icon-right inline-block">
                <i>m&#178;</i>
                <input className="form-control area-table-header-input" id='area-table-header-input-no-border' name="primaryArea" onChange={onNumericChange} onBlur={onNumericBlur} placeholder="0" type="text" value={displayNumericValue(area.primaryArea)} />
              </div>
            </div>
          </div>
        </div>
        
        {props.displaySecondaryArea &&
        <>
          <div className="flex-2">
            <div className="display-flex">
              <div className="flex-1 area-table-col-input">
                <div className="input-icon area-table-header-input-icon-right inline-block">
                  <i>m&#178;</i>
                  <input className="form-control area-table-header-input" id='area-table-header-input-no-border' name="secondaryArea" onChange={onNumericChange} onBlur={onNumericBlur} placeholder="0" type="text" value={displayNumericValue(area.secondaryArea)} />
                </div>
              </div>
            </div>
          </div>
          <div className="flex-2 ">
            <div className="display-flex">
              <div className="flex-1 area-table-right-column">
                <div>
                  {formatValue(area.primaryArea + area.secondaryArea,0,' m²')}
                </div>
              </div>
            </div>
          </div>  
        </>
        }
             
          <div className="flex-1 area-table-delete-btn">
          {props.displayDelete &&
            <FontAwesomeIcon icon={faXmark} className=" ms-3 delete-addon-button fa-1x" name="deleteAddon" type="submit" onClick={()=>deleteArea()}/>
          }
         
        </div>
        {!props.displaySecondaryArea &&
            <div className="flex-3"></div>
          }
      </div> */}

    </>
  )
}

export default React.memo(AreaTable);