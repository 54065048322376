import React, { useState, useEffect } from 'react';
import './CategoryHeader.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight, faCircle, faCircleNotch } from '@fortawesome/free-solid-svg-icons'


interface CategoryHeaderProps {
   title: string,
   input?: JSX.Element,
   menu?: JSX.Element,
   displayShow?: boolean,
   onClickCb?: (show: boolean) => void,
   onStatusChanged?,
   initialValue?: boolean,
   tag?: string,
   status?: number
}


export const CategoryHeader: React.FC<CategoryHeaderProps> = ({ title, input, menu, displayShow, onClickCb, initialValue, tag, onStatusChanged, status }) => {

   const [show, setShow] = useState(true);
   const [helpText, setHelpText] = useState('Skjul');
   //const [statusId, setStatusId] = useState(status);

   useEffect(() => {
      setShow(initialValue); //null is false
   }, [initialValue])

   const onHeaderClicked = () => {
      setHelpText(show ? 'Vis' : 'Skjul');
      setShow(!show);
      onClickCb(!show);
   }

   const onInputClicked = () => {
      if (!show) {
         setHelpText(show ? 'Vis' : 'Skjul');
         setShow(!show);
         onClickCb(!show);
      }

   }

   const onStatusClick = () => {
      if (status < 3) {
         onStatusChanged(status + 1)
      }
      else {
         onStatusChanged(0)
      }

   }

   const getStatusColor = () => {
      switch (status) {
         case 0:
            return "status-icon-transparent"
         case 1:
            return "status-icon-yellow"
         case 2:
            return "status-icon-red"
         case 3:
            return "status-icon-green"
      }
   }

   if (displayShow != null && displayShow == true) {
      if (input !== null && input !== undefined) {
         return <>
            <div className='section-header-module category-header module-row cursor-pointer' title={helpText}>
               {show ?
                  <div className='bk-icon category-header-icon'><FontAwesomeIcon icon={faCaretDown} onClick={onHeaderClicked} /></div>
                  :
                  <div className='bk-icon category-header-icon'><FontAwesomeIcon icon={faCaretRight} onClick={onHeaderClicked} /></div>
               }
               <div className="description-medium" onClick={onHeaderClicked}>{title || ''} </div>
               <div className="input-field" onClick={onInputClicked}>{input || ''} </div>
               <div className='flex-2'></div>
               <div className="tag">{tag}</div>
               <FontAwesomeIcon onClick={() => onStatusClick()} icon={faCircle} className={getStatusColor()} />
               <div className="action-menu">{menu}</div>
            </div>
         </>
      }
      return <>
         <div className='section-header-module category-header module-row cursor-pointer' title={helpText}>
            {show ?
               <div className='bk-icon category-header-icon'><FontAwesomeIcon icon={faCaretDown} onClick={onHeaderClicked} /></div>
               :
               <div className='bk-icon category-header-icon'><FontAwesomeIcon icon={faCaretRight} onClick={onHeaderClicked} /></div>
            }
            <div className="description-medium" onClick={onHeaderClicked}>{title || ''}</div>
            <div className='flex-2'></div>
            <div className="tag">{tag}</div>
            <div className="action-menu">{menu}</div>
         </div>
      </>
   }
   else {
      if (input !== null && input !== undefined && title != '') {
         return <>
            <div className="section-header-module category-header module-row">
               <div className="description-medium">{title || ''} </div>
               <div className="input-field">{input || ''} </div>
               <div className='flex-2'></div>
               <div className="tag">{tag}</div>
               <div className="action-menu">{menu}</div>
            </div>
         </>

      }
      if (input !== null && input !== undefined) {
         return <>
            <div className="section-header-module category-header module-row">
               <div className="input-field">{input || ''} </div>
               <div className='flex-2'></div>
               <div className="tag">{tag}</div>
               <div className="action-menu">{menu}</div>
            </div>
         </>

      }

      if (tag !== null && tag !== undefined) {
         return <>
            <div className='section-header-module category-header module-row'>
               <div className="description-medium">{title || ''} </div>
               <div className='flex-2'></div>
               <div className="tag">{tag}</div>
               <div className="action-menu">{menu}</div>
            </div>
         </>
      }

      if (menu !== null && menu !== undefined) {
         return <>
            <div className='section-header-module category-header module-row'>
               <div className="description-medium">{title || ''} </div>
               <div className="action-menu">{menu}</div>
            </div>
         </>
      }

      return <>
         <div className='section-header-module category-header module-row'>
            <div className="description-medium">{title || ''} </div>
         </div>
      </>
   }


}