import React from 'react';
import ReactTooltip from 'react-tooltip';
import {renderToString } from 'react-dom/server'
import {formatValue } from '../../../utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight, faPenToSquare, faPrint, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'

const TotalSum = ({area, totalCost, reductionPercent}) => {

  const getHelpTextTotal = function(section) {

    switch(section) {
      case 0:
        return (<> <h5><b>Sum antall BRA</b></h5><div className="helptext-list-item"><p>I denne posten fremkommer det totale antall BRA for bygningen.</p></div></>);
      case 1:
        return (<> <h5><b>Gjennomsnittspris pr m&#178; BRA</b></h5><div className="helptext-list-item"><p>I denne posten fremkommer gjennomsnittsprisen pr. m&#178; ut fra valgene gjort i de foregående postene.</p></div></>);
      case 2:
        return (<> <h5><b>Sum byggekostnad</b></h5><div className="helptext-list-item"><p>I denne posten fremkommer den totale byggekostnaden ut fra de valgene som har blitt gjort i de forgående postene.</p></div><div className="helptext-list-item"><p>Byggekostnad er over ferdig planert tomt.</p></div><div className="helptext-list-item"><p>Kostnad for kjøp av tomt er ikke medregnet.</p></div></>);
      case 3:
        return (<> <h5><b>Verdireduksjon</b></h5><div className="helptext-list-item"><p>I denne posten fremkommer samlet verdireduksjon ut fra de valgene som er gjort under verdireduksjonsberegningene.</p></div></>);
      case 4:
        return (<> <h5><b>Nåværende teknisk verdi etter fradrag</b></h5><div className="helptext-list-item"><p>I denne posten fremkommer den totale byggekostnaden ut fra de valgene som har blitt gjort i de forgående postene.</p></div><div className="helptext-list-item"><p>Byggekostnad er over ferdig planert tomt.</p></div><div className="helptext-list-item"><p>Kostnad for kjøp av tomt er ikke medregnet.</p></div><div className="helptext-list-item"><p>Summen har fratrekk for verdireduksjon.</p></div></>);
      }
  }

  return (
    <>
      
        <div className="module-row">
          <div className="flex-2">
            Sum antall BRA
          </div>
          <div className="flex-1">
            {formatValue(area, 0 , ' m²')}
          </div>
          <div className="flex-05">
            <FontAwesomeIcon className='bk-icon' icon={faQuestionCircle} data-tip={renderToString(getHelpTextTotal(0))} data-for='tid0'/>
            <ReactTooltip id='tid0' place="right" type="light" html={true} effect="solid" className="helptext-custom"/>
          </div>
          <div className="flex-5"></div>
        </div>
        <div className="module-row">
          <div className="flex-2">
            Gjennomsnittspris pr m² BRA
          </div>
          <div className="flex-1">
            {formatValue((area>0?totalCost/area:0),0,' kr')}
          </div>
          <div className="flex-05">
            <FontAwesomeIcon className='bk-icon' icon={faQuestionCircle} data-tip={renderToString(getHelpTextTotal(1))} data-for='tid1'/>
            <ReactTooltip id='tid1' place="right" type="light" html={true} effect="solid" className="helptext-custom"/>
          </div>
          <div className="flex-5"></div>
        </div>
        <div className="module-row">
          <div className="flex-2">
            Byggekostnad iht. gjeldende TEK
          </div>
          <div className="flex-1">
            {formatValue(totalCost,0,' kr')}
          </div>
          <div className="flex-05">
            <FontAwesomeIcon className='bk-icon' icon={faQuestionCircle} data-tip={renderToString(getHelpTextTotal(2))} data-for='tid2'/>
            <ReactTooltip id='tid2' place="right" type="light" html={true} effect="solid" className="helptext-custom"/>
          </div>
          <div className="flex-5"></div>
        </div>
        <div className="module-row">
          <div className="flex-2">
            Verdireduksjon ({formatValue(reductionPercent,2,'%')})
          </div>
          <div className="flex-1">
            {formatValue((reductionPercent * totalCost / 100), 0, ' kr')}
          </div>
          <div className="flex-05">
            <FontAwesomeIcon className='bk-icon' icon={faQuestionCircle} data-tip={renderToString(getHelpTextTotal(3))} data-for='tid3'/>
            <ReactTooltip id='tid3' place="right" type="light" html={true} effect="solid" className="helptext-custom"/>
          </div>
          <div className="flex-5"></div>
        </div>
        <div className="module-row">
          <div className="flex-2">
            Teknisk verdi etter fradrag
          </div>
          <div className="flex-1">
            {formatValue((totalCost - (reductionPercent * totalCost / 100)), 0, ' kr')}
          </div>
          <div className="flex-05">
            <FontAwesomeIcon className='bk-icon' icon={faQuestionCircle} data-tip={renderToString(getHelpTextTotal(4))} data-for='tid4'/>
            <ReactTooltip id='tid4' place="right" type="light" html={true} effect="solid" className="helptext-custom"/>
          </div>
          <div className="flex-5"></div>
        </div>
    </>
  )
}


export default React.memo(TotalSum);