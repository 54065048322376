import React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import NavbarButton from './Buttons/NavbarButton/NavbarButton';
import NavbarButtonSp from './Buttons/NavbarButtonSp/NavbarButtonSp';
import NavbarButtonLogin from './Buttons/NavbarButtonLogin/NavbarButtonLogin';
import { IApplicationState } from '../store';
import { actionCreators } from '../store/auth';
import { RoutesConfig, Route } from '../config/routes.config';
import UserMenu from './Menu/UserMenu';

import { useHistory } from "react-router-dom";

type NavbarProps = typeof actionCreators &
{
  readonly isAuthenticated: boolean;
  readonly role: number;
  readonly userName: string;
  readonly isAdmin: boolean;
};


const Navbar_action: React.FC<NavbarProps> = ({
  isAuthenticated,
  userName,
  role,
  isAdmin,
  logoutUserRequest
}) => {

  const history = useHistory();

  const navRoutes: Route[] = Object.keys(RoutesConfig).reduce((acc, key) => {
    const route = RoutesConfig[key];
    if (route.showInNav && route.loggedIn === isAuthenticated && (!route.isAdmin || isAdmin)) {
      acc.push(route);
    };
    return acc;
  }, [] as Route[]);

  const handleNavBarClick = function (path) {
    history.push(path);
  }


  const navbar = navRoutes.map((route, index) => {
    return <>
      <NavbarButton caption={route.displayName} arg={route.path} onClickHandler={handleNavBarClick} key={index} />
    </>
  });


  return (
    <div className='navbar-container'>

      <div onClick={() => handleNavBarClick("/")}><img className='navbar-logo' src="\Content\images\design\Logo_vector_blue.svg" /></div>
      <div className='navbar-button-container'>
        {navbar}
        <UserMenu logoutUserRequest={logoutUserRequest} userName={userName} isLoggedIn={isAuthenticated} />
      </div>
    </div>

    // <Navbar expand="md" expanded={show}>
    // <Container>
    //         <Navbar.Brand href="/" className=" col-5 col-md-2 mb-3 align-items center"><Image fluid src="\Content\images\design\Logo_vector_blue.svg" className="w-75"/></Navbar.Brand>
    //         <Navbar.Toggle aria-controls="offcanvasNavbar-expand-md" onClick={navToggle}/>
    //         <Navbar.Offcanvas placement="end" onHide={handleClose} className="navbar-offcanvas offcanvas-md"  aria-labelledby="offcanvasNavbar-expand-md">
    //         <Offcanvas.Header closeButton className='justify-content-end mt-3 me-2' ></Offcanvas.Header>
    //         <Offcanvas.Body className='justify-content-end'>
    //         <Nav className='' onClick={handleClose}>
    //           { 
    //             navRoutes.map((route, index) => {
    //               switch (route.displayName) {
    //                 case "Opprett konto":
    //                   return ( 
    //                     <Nav.Link className='mx-1 justify-content-end' onClick={handleClose}>
    //                     <NavbarButtonSp caption={route.displayName} arg={route.path} onClickHandler={handleNavBarClick} key={index}/>
    //                     </Nav.Link>
    //                     );
    //                 case "Logg inn":
    //                   return ( 
    //                     <Nav.Link className='mx-1 justify-content-end'>
    //                     <NavbarButtonLogin caption={route.displayName} arg={route.path} onClickHandler={handleNavBarClick} key={index}/>
    //                     </Nav.Link>
    //                     );
    //                 default:
    //                   return (
    //                     <Nav.Link className=''>
    //                     <NavbarButton caption={route.displayName} arg={route.path} onClickHandler={handleNavBarClick} key={index}/>
    //                     </Nav.Link>
    //                   );} 
    //             })
    //           }
    //           <UserMenu isLoggedIn={isAuthenticated}/>
    //         </Nav>
    //         </Offcanvas.Body>
    //          </Navbar.Offcanvas>
    //     </Container>
    //   </Navbar>

  );
};

const mapStateToProps = (state: IApplicationState) => ({
  isAuthenticated: state.auth.isAuthenticated,
  role: state.auth.role,
  userName: state.auth.userName,
  isAdmin: state.auth.isAdmin
});

export default connect(mapStateToProps, actionCreators)(Navbar_action);

