import React, { useState, useEffect } from 'react';
import { actionCreators } from '../../store/auth';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { useHistory, useParams } from 'react-router-dom'
import { AdminApi, AuthApi, ReportApi } from '../../api';
import AdminMenu from './child-components/AdminMenu'
import { BkButton, BkButtonSmall, TextLineInput } from '../../components';
import { ConfirmationDialog, AlertDialog, NumberDialog } from '../../components/Dialogs'
import { formatDateTime } from '../../utils/formatDate'
import DatePicker from "react-datepicker";
import { StatisticsBox, Spinner } from '../../components';
import { ProjectApi, ProjectsApi, CountyApi, SubscriptionApi } from '../../api';
import SearchIndicator from '../../components/SearchIndicator'
import CustomProjectRow from './child-components/CustomProjectRow'
import CustomReportRow from './child-components/CustomReportRow'
import ShareCard from './child-components/ShareCard'
import CustomerStatistics from './child-components/CustomerStatistics'
import AdminReportFilter from './child-components/AdminReportFilter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faTrashCan } from '@fortawesome/free-solid-svg-icons'

import "react-datepicker/dist/react-datepicker.css";

type CustomerProps = typeof actionCreators &
{
   readonly isAuthenticated: boolean;
   readonly isAdmin: boolean;
};

const keyPressTimer = 1000;
let inputTimer = null;
const defaultTake = 30;
let searchTimer = null;



const Customer: React.FC<CustomerProps> = ({
   isAuthenticated,
   isAdmin,
   logoutUser
}) => {

   const [customer, setCustomer] = useState({
      created: null,
      updated: null,
      userId: 0,
      firstName: '',
      lastName: '',
      company: '',
      address: '',
      zipCode: '',
      city: '',
      county: '',
      email: '',
      phoneNumber: '',
      lastLogin: null,
      registeredDate: null,
      roleId: 0,
      registered: false,
      organization: '',
      department: '',
      organizationNumber: '',
      invoiceAddress: '',
      invoiceZipCode: '',
      invoiceCity: '',
      invoiceEmail: '',
      userTypeId: 0,
      paid: false,
      active: false,
      acceptedDate: null,
      invoiceDate: null,
      validTo: null,
      activeTo: null,
      reportModuleSubscriptionActive: true,
      reportModuleSubscriptionRemaining: 0,
      aiModuleActive: false,
      ehf: false,
      companyEmployees: [{
         employeeId: 0,
         firstName: '',
         lastName: '',
         company: ''
      }]
   });

   const [displayForgotPasswordDialog, setDisplayForgotPasswordDialog] = useState(false);
   const [displayChangeUserStatusDialog, setDisplayChangeUserStatusDialog] = useState(false);
   const [displayChangeRemainingDialog, setDisplayChangeRemainingDialog] = useState(false);
   const [acceptedDate, setAcceptedDate] = useState(null);
   const [invoiceDate, setInvoiceDate] = useState(null);
   const [validTo, setValidTo] = useState(null);
   const [activeTo, setActiveTo] = useState(null);
   const [reportTypes, setReportTypes] = useState([]);
   const [selectedReportType, setSelectedReportType] = useState(0);

   const history = useHistory();
   const params = useParams();
   const id = (params as any).id;
   let searchTimer = null;

   useEffect(() => {
      fetchData();
   }, []);

   const fetchData = async () => {
      AdminApi.getCustomer(id)
         .then(
            (response) => {
               setCustomer(response.data);
               if (response.data.acceptedDate != null) {
                  setAcceptedDate(new Date(response.data.acceptedDate));
               }
               if (response.data.invoiceDate != null) {
                  setInvoiceDate(new Date(response.data.invoiceDate));
               }
               if (response.data.validTo != null) {
                  setValidTo(new Date(response.data.validTo));
               }
               if (response.data.activeTo != null) {
                  setActiveTo(new Date(response.data.activeTo));
               }
            },
            (error) => {
               console.log(error);
            });
   }

   const [loading, setLoading] = useState(true);
   const [statistics, setStatistics] = useState({
      /*acceptedUsers: {},
      projects: {},
      services: {},*/
      otherCalculations: [],
      calculations: [],
      reports: []
   });

   useEffect(() => {
      fetchstatData();
      fetchReportTypes();
   }, [])

   const fetchstatData = async () => {
      AdminApi.getUserStatistics(id)
         .then(
            (response) => {
               setStatistics(response.data);
               setLoading(false);
            },
            (error) => {
               console.log(error);
            });
   }

   if (!isAuthenticated) {
      logoutUser();
      history.push("/login");
   }

   if (!isAdmin) {
      history.push("/projects");
   }


   const handleDeactivateCustomer = () => {
      setDisplayChangeUserStatusDialog(true)
   }

   const handleForgotPassword = () => {
      setDisplayForgotPasswordDialog(true);
   }

   const handleSetPassword = () => {
      var data = {
         id: customer.userId
      }
      AdminApi.resetPassword(data)
         .then(
            (response) => {
               history.push("/ResetPassword?email=" + customer.email + "&code=" + response.data);
            },
            (error) => {
               console.log(error);
            });
   }

   const handleForgotPasswordDialogClose = (id) => {
      setDisplayForgotPasswordDialog(false);
      if (id > 0) {
         var data = {
            email: customer.email
         }
         AuthApi.ForgotPassword(data)
            .then(
               (response) => {
                  alert("mail sendt til kunde");
               },
               (error) => {
                  console.log(error);
               });
      }
   }

   const handleChangeUserStatusDialogClose = (id) => {
      setDisplayChangeUserStatusDialog(false);
      if (id > 0) {
         var data = {
            id: customer.userId,
         }
         if (customer.active) {
            AdminApi.deactivateUser(data)
               .then(
                  (response) => {
                     setCustomer(customer => ({ ...customer, active: false }));

                  },
                  (error) => {
                     console.log(error);
                  });
         }
         else {
            AdminApi.activateUser(data)
               .then(
                  (response) => {
                     setCustomer(customer => ({ ...customer, active: true }));

                  },
                  (error) => {
                     console.log(error);
                  });
         }

      }
   }

   const EhfStatusChange = () => {
      var data = {
         id: customer.userId,
      }
      AdminApi.changeEhfStatus(data)
      .then(
         (response) => {
            setCustomer(customer => ({ ...customer, ehf: response.data }));

         },
         (error) => {
            console.log(error);
         });
   }

   const UpdateCustomer = (data) => {
      AdminApi.updateCustomer(data)
         .then(
            (response) => {
               //setCustomer(customer => ({...customer,active:true}));
            },
            (error) => {
               console.log(error);
            });
   }


   const UpdateReportSubscription = (data) => {
      ReportApi.updateSubscription(data)
         .then(
            (response) => {
            },
            (error) => {
               console.log(error);
            });
   }

   const onTextChange = (e) => {
      const { name, value } = e.target;
      setCustomer(customer => ({ ...customer, [name]: value }));
      var copy = customer;
      copy[name] = value;
      if (inputTimer !== null) {
         clearTimeout(inputTimer);
      }
      inputTimer = setTimeout(() => {
         UpdateCustomer(copy);;
      }, keyPressTimer);
   }

   const onTextBlur = (e) => {
      const { name, value } = e.target;
      clearTimeout(inputTimer);
      setCustomer(customer => ({ ...customer, [name]: value }));
      var copy = customer;
      copy[name] = value;
      UpdateCustomer(copy);
   }

   const updateAcceptedDate = (date) => {
      setAcceptedDate(date);
      var copy = customer;
      copy.acceptedDate = date;
      UpdateCustomer(copy);
   }

   const updateInvoiceDate = (date) => {
      setInvoiceDate(date);
      var copy = customer;
      copy.invoiceDate = date;
      UpdateCustomer(copy);
   }

   const updateValidTo = (date) => {
      setValidTo(date);
      var copy = customer;
      copy.validTo = date;
      UpdateCustomer(copy);
   }

   const updateActiveTo = (date) => {
      setActiveTo(date);
      var copy = customer;
      copy.activeTo = date;
      UpdateCustomer(copy);
   }

   const handleAdd50 = () => {
      var value = customer.reportModuleSubscriptionRemaining + 50;
      setCustomer(customer => ({ ...customer, reportModuleSubscriptionRemaining: value }));

      var data = {
         remaining: value,
         userId: customer.userId,
         active: customer.reportModuleSubscriptionActive
      }
      UpdateReportSubscription(data);
   }

   const handleChangeRemainingClicked = () => {
      // open dialog
      setDisplayChangeRemainingDialog(true);
   }


   const handleChangeRemainingDialogClose = (value) => {
      setDisplayChangeRemainingDialog(false);
      setCustomer(customer => ({ ...customer, reportModuleSubscriptionRemaining: value }));

      var data = {
         remaining: value,
         userId: customer.userId,
         active: customer.reportModuleSubscriptionActive
      }
      UpdateReportSubscription(data);
   }

   const handleChangeActivation = () => {
      var value = !customer.reportModuleSubscriptionActive;
      setCustomer(customer => ({ ...customer, reportModuleSubscriptionActive: value }));

      var data = {
         remaining: customer.reportModuleSubscriptionRemaining,
         userId: customer.userId,
         active: value
      }
      UpdateReportSubscription(data);
   }

   const handleBackClick = () => {
      history.push("/Customers/0");
   }

   const [isLoading, _setIsLoading] = useState(true);
   const [isMount, setIsMount] = useState(true);
   const [projects, setProjects] = useState([]);
   const [reports, setReports] = useState([]);
   const [searchText, setSearchText] = useState('');
   const [openNew, setOpenNew] = React.useState(false);
   const [projectData, setProjectData] = useState({
      projectId: 0,
      address: '',
      zipCode: '',
      city: '',
      gnr: '',
      bnr: '',
      fnr: '',
      snr: '',
      lnr: '',
      andelsnr: '',
      state: 0,
      countyId: 0,
      county: '',
      imageUrl: ''
   });
   const [sortField, setSortField] = useState("ModifiedDate");
   const [ascending, setAscending] = useState(false);

   const [counties, setCounties] = useState([]);
   const [searchModel, _setSearchModel] = useState({
      UserId: id,
      SearchText: '',
      index: 0,
      take: 20,
      sortField: "ModifiedDate",
      sortAscending: false
   })

   const searchModelRef = React.useRef(searchModel);
   const setSearchModel = (data) => {
      _setSearchModel(data);
   };

   const isLoadingRef = React.useRef(isLoading);
   const setIsLoading = data => {
      isLoadingRef.current = data;
      _setIsLoading(data);
   };


   if (!isAuthenticated) {
      logoutUser();
      history.push("/login");
   }

   useEffect(() => {
      setSearchModel(searchModel => ({ ...searchModel, take: defaultTake }));
      setSearchText('');
      fetchCounties();
   }, []);

   useEffect(() => {
      searchModelRef.current = searchModel;
      // First to skip the initialising phase. 
      // because of security reasons we have to check user role before fetching
      if (isMount) {
         setIsMount(false);
         return;
      }
      // Fetch data based on the changes in the search model
      fetchprojData();
   }, [searchModel])


   const fetchprojData = async () => {
      setIsLoading(true);
      AdminApi.getProjects(searchModel)
         .then(
            (response) => {
               if (searchModel.index > 0) {
                  setProjects([...projects, ...response.data]);
               }
               else {
                  setProjects(response.data);
               }
               setIsLoading(false);
            },
            (error) => {
               setIsLoading(false);
            });
   }

   const fetchCounties = async () => {
      CountyApi.getCounties()
         .then(
            (response) => {
               setCounties(response.data);
            },
            (error) => {
               console.log(error);
            });
   }

   const handleProjectSearch = function (value, id, name) {
      var searchstring = value.toLowerCase();
      setSearchText(searchstring);

      doProjectSearch(searchstring)

   }

   const doProjectSearch = function (searchstring) {
      if (searchstring.length > 2) {
         setSearchModel({ ...searchModel, searchText: searchstring, index: 0 });
      }
      else {
         setSearchModel({ ...searchModel, searchText: "", index: 0 });
      }
   }


   const handleSort = (field) => {
      var direction = ascending;
      if (field == sortField) {
         direction = !direction;
      }
      else {
         direction = false;
      }
      setSearchModel({ ...searchModel, sortAscending: direction, sortField: field, index: 0 });

      setAscending(direction);
      setSortField(field);
   }



   // const handleProjectDelete = function (id) {
   //    ProjectApi.deleteProject(id)
   //       .then(
   //          (response) => {
   //             setProjects(projects.filter(p => p.projectId !== id));
   //          },
   //          (error) => {
   //             console.log(error);
   //          });
   // }
   // const handleReportDelete = function (id) {
   //    ProjectApi.deleteProject(id)
   //       .then(
   //          (response) => {
   //             setProjects(projects.filter(p => p.projectId !== id));
   //          },
   //          (error) => {
   //             console.log(error);
   //          });
   // }

   const fetchReportTypes = async () => {
      AdminApi.getReportTypes()
         .then(
            (response) => {
               setReportTypes(response.data);
            },
            (error) => {
               console.log(error);
            });
   }


   const ChangeAiModule = async () => {
      var data = { userId: customer.userId, subscriptionType: 1 }
      if (customer.aiModuleActive) {

         SubscriptionApi.unsubscribe(data)
            .then((response) => {
               setCustomer(customer => ({ ...customer, aiModuleActive: false }));
            },
               (error) => {
                  console.log(error);
               });

      }
      else {
         SubscriptionApi.subscribe(data)
            .then((response) => {
               setCustomer(customer => ({ ...customer, aiModuleActive: true }));
            },
               (error) => {
                  console.log(error);
               });
      }

   }

   // const handleProjectCopy = function (id) {
   //    //Todo
   // }
   // const handleReportCopy = function (id) {
   //    //Todo
   // }


   let projectRows = null;
   if (projects.length > 0) {
      projectRows = projects.map((project, index) =>
         <CustomProjectRow project={project} index={index}></CustomProjectRow>
      );
   }

   // let reportRows = null;
   // if (reports.length > 0) {
   //    reportRows = reports.map((report, index) =>
   //       <CustomReportRow report={report} index={index} onCopyCb={handleReportCopy} onDeleteCb={handleReportDelete}></CustomReportRow>
   //    );
   // }

   const ActivationStatusBtnText = () => {
      if (customer.active) {
         return "Aktiv"
      }
      else {
         return "Deaktiv"
      }

   }
   const ActivationStatusText = () => {
      if (customer.active) {
         return "Aktivt"
      }
      else {
         return "Deaktivert"
      }

   }


   const EhfActiveText = (active) => {
      if (active) {
         return "Ja"
      }
      else {
         return "Nei"
      }
   }

   const ActiveText = (active) => {
      if (active) {
         return "Aktiv"
      }
      else {
         return "Deaktiv"
      }
   }


   const handleScroll = function (e) {

      const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 2;
      if (bottom && !isLoading) {
         var index = searchModelRef.current.index + searchModelRef.current.take;
         setSearchModel(searchModel => ({ ...searchModel, take: defaultTake, index: index }));

      }

   }

   const reportTypeSelected = (reportTypeId) => {
      setSelectedReportType(reportTypeId);
      setSearchModel({ ...searchModel, reportTypeId: reportTypeId, index: 0 });
   }


   const handleEmployeeClick = (userId) => {
      const url = "/customer/" + userId;
      history.push(url);
   }

   let employeeRows = null;
   if (customer.companyEmployees.length > 0) {
      employeeRows = customer.companyEmployees.map((employee, index) =>
         <div className='main-table-data-row white-row module-row' onClick={() => handleEmployeeClick(employee.employeeId)}>
            <div className='flex-1'>{employee.firstName}</div>
            <div className='flex-1'>{employee.lastName}</div>
            <div className='text-trim flex-1'>{employee.company}</div>
         </div>
      );
   }

   return (
      <>
         <div className="admin-container" onScroll={handleScroll}>
            <AdminMenu activeMenuItem={'Kunder'}></AdminMenu>

            <div className='customer-container'>
               <div className='module-row align-items-top'>
                  <div className='flex-2'>
                     <div className='module-row'>
                        <div className="flex-2 bk-text-blue-big">{customer.firstName + ' ' + customer.lastName}</div>
                        <div className='flex-1 bk-text-blue'>Kundenr: <span className='bold'>{customer.userId}</span></div>
                        <div className="flex-1 bk-text-blue">Sist innlogget: {formatDateTime(customer.lastLogin)}</div>
                     </div>
                     <hr></hr>
                  </div>
               </div>

               <div className='module-row align-items-top'>
                  <div className="flex-4">
                     <div className="customer-card-info-row">
                        <span className='bk-text-blue'>Fornavn:</span>
                        <input name='firstName' placeholder='' className="form-control" id="form-control-thin-input" value={customer.firstName} onChange={onTextChange} onBlur={onTextBlur} />
                     </div>

                     <div className="customer-card-info-row">
                        <span className='bk-text-blue'>Etternavn:</span>
                        <input name='lastName' placeholder='' className="form-control" id="form-control-thin-input" value={customer.lastName} onChange={onTextChange} onBlur={onTextBlur} />
                     </div>

                     <div className="customer-card-info-row">
                        <span className='bk-text-blue'>Telefon:</span>
                        <input name='phoneNumber' placeholder='' className="form-control" id="form-control-thin-input" value={customer.phoneNumber} onChange={onTextChange} onBlur={onTextBlur} />
                     </div>

                     <div className="customer-card-info-row">
                        <span className='bk-text-blue'>Epost:</span>
                        <input name='email' placeholder='' className="form-control" id="form-control-thin-input" value={customer.email} onChange={onTextChange} onBlur={onTextBlur} />
                     </div>



                     <div className="customer-card-info-row">
                        <span className='bk-text-blue'>Adresse:</span>
                        <input name='address' placeholder='' className="form-control" id="form-control-thin-input" value={customer.address} onChange={onTextChange} onBlur={onTextBlur} />
                     </div>

                     <div className="customer-card-info-row">
                        <span className='bk-text-blue'>Postnr.:</span>
                        <input name='zipCode' placeholder='' className="form-control" id="form-control-thin-input" value={customer.zipCode} onChange={onTextChange} onBlur={onTextBlur} />
                     </div>
                     <div className="customer-card-info-row">
                        <span className='bk-text-blue'>Poststed:</span>
                        <input name='city' placeholder='' className="form-control" id="form-control-thin-input" value={customer.city} onChange={onTextChange} onBlur={onTextBlur} />
                     </div>
                     <div className="customer-card-info-row">
                        <span className='bk-text-blue'>Fylke:</span>
                        <input name='county' placeholder='' className="form-control" id="form-control-thin-input" value={customer.county} onChange={onTextChange} onBlur={onTextBlur} />
                     </div>

                  </div>

                  <div className="flex-4">
                     <div className="customer-card-info-row-long"><span className='bk-text-blue'>Firmanavn:</span>
                        <input name='company' placeholder='' className="form-control " id="form-control-thin-input" value={customer.company} onChange={onTextChange} onBlur={onTextBlur} /></div>
                     <div className="customer-card-info-row-long"><span className='bk-text-blue'>Org.nr.:</span>
                        <input name='organizationNumber' placeholder='' className="form-control" id="form-control-thin-input" value={customer.organizationNumber} onChange={onTextChange} onBlur={onTextBlur} /></div>

                     <div className=" customer-card-info-row-long"><span className='bk-text-blue'>Firmaadresse:</span>
                        <input name='invoiceAddress' placeholder='' className="form-control" id="form-control-thin-input" value={customer.invoiceAddress} onChange={onTextChange} onBlur={onTextBlur} /></div>

                     <div className="customer-card-info-row-long"><span className='bk-text-blue'>Postnr:</span>
                        <input name='invoiceZipCode' placeholder='' className="form-control" id="form-control-thin-input" value={customer.invoiceZipCode} onChange={onTextChange} onBlur={onTextBlur} /></div>
                     <div className="customer-card-info-row-long"><span className='bk-text-blue'>Poststed:</span>
                        <input name='invoiceCity' placeholder='' className="form-control" id="form-control-thin-input" value={customer.invoiceCity} onChange={onTextChange} onBlur={onTextBlur} /></div>
                     <div className="customer-card-info-row-long"><span className='bk-text-blue'>Fakturaepost:</span>
                        <input name='invoiceEmail' placeholder='' className="form-control" id="form-control-thin-input" value={customer.invoiceEmail} onChange={onTextChange} onBlur={onTextBlur} /></div>
                     <div className="customer-card-info-row-long"><span className='bk-text-blue'>Faktura - EHF:</span>
                        <div className="ms-1"> <BkButtonSmall color={customer.ehf == true ? '' : 'red'} onClickHandler={EhfStatusChange} className='customer-shared-list-button' caption={EhfActiveText(customer.ehf)} /></div> </div>
                  </div>

                  <div className='flex-4'>
                     <div className="customer-card-info-row-long"><span className='bk-text-blue'>Opprettet:</span>
                     <span className='customer-register-date'>{formatDateTime(customer.created)}</span></div>
                     <div className="customer-card-info-row-long"><span className='bk-text-blue'>Akseptert:</span>
                        <DatePicker dateFormat="dd-MM-yyyy" className="form-control" id="form-control-thin-input" selected={acceptedDate} onChange={(date) => updateAcceptedDate(date)} isClearable={true} /></div>
                     <div className="customer-card-info-row-long"><span className='bk-text-blue'>Fakturert:</span>
                        <DatePicker dateFormat="dd-MM-yyyy" className="form-control" id="form-control-thin-input" selected={invoiceDate} onChange={(date) => updateInvoiceDate(date)} isClearable={true} /></div>


                     <div className="customer-card-info-row-long"><span className='bk-text-blue'>Gratis til:</span>
                        <DatePicker dateFormat="dd-MM-yyyy" className="form-control" id="form-control-thin-input" selected={validTo} onChange={(date) => updateValidTo(date)} /></div>
                     {/*<div className="col-md-3 col-6  mt-3">Aktiv til:
                              <DatePicker dateFormat="dd-MM-yyyy"  className="form-control" id="form-control-thin-input"  selected={activeTo} onChange={(date) => updateActiveTo(date)} /></div>*/}


                     <div className='customer-card-info-row-long'>
                        <span className='bk-text-blue'>Yrkesgruppe:</span>
                        <select className='form-control' id="form-control-thin-input">
                           <option value="0">Takstmann</option>
                           <option value="1">Byggmester</option>
                           <option value="2">Student</option>
                        </select>
                     </div>

                     <div className='customer-card-info-row-long'>
                        <span className='bk-text-blue'>Admin/ vanlig:</span>
                        <select className="form-control" id="form-control-thin-input" >
                           <option value="0">Bruker</option>
                           <option value="1">Admin</option>
                        </select>
                     </div>

                     <div className='customer-card-info-row-long'>
                        <span className='bk-text-blue'>Type ansatt:</span>
                        <select className="form-control" id="form-control-thin-input" >
                           <option value="0">Arbeider</option>
                           <option value="1">Bedriftsleder</option>
                           <option value="2">Sekretær</option>
                        </select>
                     </div>


                  </div>
               </div>

               <div className='module-row mt-2'>

                  <div className='flex-1 customer-shared-list'>
                     <div className='customer-shared-list-header'>Abonnement:</div>

                     <div className='customer-shared-list-content'>

                        <div className=" module-row customer-btn-row">
                           <div className="flex-1 ">Bruker tilgang </div>
                           {/* <span className=''>{customer.reportModuleSubscriptionActive ? '(Aktiv)' : '(Deaktivert)'}</span> */}

                           <BkButtonSmall  color={'blue'} caption='Sett passord' onClickHandler={handleSetPassword}></BkButtonSmall>
                           <BkButtonSmall  color={'blue'} caption='Glemt passord' onClickHandler={handleForgotPassword}></BkButtonSmall>
                           <BkButtonSmall  color={customer.active == true ? '' : 'red'} caption={ActivationStatusBtnText()} onClickHandler={handleDeactivateCustomer}></BkButtonSmall>
                        </div>

                        <div className=" module-row customer-btn-row">
                           <div className="text-trim flex-1 ">Rapporter <span className=''>{"(" + customer.reportModuleSubscriptionRemaining + ")"}</span></div>

                           <BkButtonSmall color={'blue'} caption='Legg til: 50' onClickHandler={handleAdd50}></BkButtonSmall>
                           <BkButtonSmall color={'blue'} caption='Endre antall' onClickHandler={handleChangeRemainingClicked}></BkButtonSmall>
                           <BkButtonSmall disabled caption={'...'} />
                        </div>
                        <div className=" module-row customer-btn-row">
                           <div className="text-trim flex-1 ">Kalkyler</div>
                           <BkButtonSmall disabled caption={'...'} />
                        </div>
                        <div className=" module-row customer-btn-row">
                           <div className="text-trim flex-1 ">Kontorstøtte</div>
                           <BkButtonSmall disabled caption={'...'} />
                        </div>
                        <div className=" module-row customer-btn-row">
                           <div className="text-trim flex-1 ">AI Modul</div>
                           <BkButtonSmall color={customer.active == true ? '' : 'red'} caption={ActiveText(customer.aiModuleActive)} onClickHandler={ChangeAiModule} />
                        </div>
                     </div>
                  </div>

                  <div className='flex-1'><ShareCard userId={customer.userId} /></div>

                  <div className='flex-1 customer-shared-list'>
                     <div className='customer-shared-list-header'>Firmaansatte ({customer.company}):</div>

                     <div className='customer-shared-list-content'>
                        {employeeRows}
                     </div>
                  </div>

               </div>



               <div className='module-row'>
                  <div className='flex-3'>
                     <div className='admin-statistics-header'> Kontaktens forbruksoversikt</div>

                     <div className='customer-statistics-row mx-auto'>

                        <StatisticsBox title={'Rapporter'} data={statistics.reports}></StatisticsBox>

                        <StatisticsBox title={'Tekniske kalkulasjoner - Privat'} data={statistics.calculations}></StatisticsBox>

                        <StatisticsBox title={'Andre kalkulasjoner'} data={statistics.otherCalculations}></StatisticsBox>

                     </div>
                  </div>
                  <div className='flex-2'></div>
               </div>

               <div className='module-row'>
                  <div className='flex-3'>
                  <div className='admin-statistics-header'> Kontaktens prosjektoversikt</div>
                     <div className='module-row'>
                        <div className='flex-1'><AdminReportFilter filters={reportTypes} selectedFilter={selectedReportType} reportTypeSelectedCb={reportTypeSelected}></AdminReportFilter></div>
                        <div className='flex-1'><TextLineInput placeholder="Søk" name="ProsjektSøk" value={searchText} onValueChangeCb={handleProjectSearch} /></div>
                        <div className='flex-1'></div>
                     </div>

                     <div className='customer-projects-card'>

                        <div className="main-table-header-row module-row" id='module-row-no-margin'>
                           <div className="flex-1 search-cursor" onClick={() => handleSort("CreatedDate")}>Opprettet<SearchIndicator visible={sortField == "CreatedDate"} ascending={ascending}></SearchIndicator></div>
                           <div className="flex-1 search-cursor" onClick={() => handleSort("ModifiedDate")}>Endret<SearchIndicator visible={sortField == "ModifiedDate"} ascending={ascending}></SearchIndicator></div>
                           <div className="flex-3"> Adresse </div>
                        </div>
                        <div className='main-table-data-container'>
                           {projectRows}
                        </div>
                     </div>

                  </div>
                  <div className='flex-2'></div>
               </div>





            </div >
         </div >

         <ConfirmationDialog
            title="Glemt passord"
            text={"Vil du sende en e-post til " + customer.email + " med link til siden for å nullstille passord?"}
            open={displayForgotPasswordDialog}
            id={id}
            onClose={handleForgotPasswordDialogClose}
         />

         <ConfirmationDialog
            title={(customer.active ? "Deaktivere" : "Aktivere") + " bruker"}
            text={"Er du sikker på at du vil " + (customer.active ? "deaktivere" : "aktivere") + " brukeren?"}
            open={displayChangeUserStatusDialog}
            id={id}
            onClose={handleChangeUserStatusDialogClose}
         />

         <NumberDialog
            title={"Sett antall gjenstående rapporter"}
            initialValue={customer.reportModuleSubscriptionRemaining}
            open={displayChangeRemainingDialog}
            onClose={handleChangeRemainingDialogClose}
         />
      </>
   );
}

const mapStateToProps = (state: IApplicationState) => ({
   isAuthenticated: state.auth.isAuthenticated,
   isAdmin: state.auth.isAdmin
});

export default connect(mapStateToProps, actionCreators)(Customer);