import React, { useState, useEffect, useRef } from 'react'

type NumericInputProps = {
  readonly value: number;
  readonly class?: string;
  readonly id?: number;
  readonly name?: string;
  readonly key?: string;
  readonly readOnly?: boolean;
  readonly disabled?: boolean;
  readonly decimals?: number;
  readonly placeholder?: string;
  readonly noSpaces?: boolean;
  readonly suffix?: string;
  readonly prefix?: string;
  readonly sum?: boolean;
  readonly onValueChangeCb?: (value: number, id?: number, name?: string) => void;
};


const keyPressTimer = 2000;
let valueTimer = null;

function NumericInput(props: NumericInputProps) {

  const [value, setValue] = useState(0);
  const [id, setId] = useState(0);
  const [decimals, setDecimals] = useState(0);
  const [name, setName] = useState('');
  const [showBlank, setShowBlank] = useState(false);

  useEffect(() => {
    setValue(props.noSpaces ? noSpaces(round(props.value, props.decimals)) : addSpaces(round(props.value, props.decimals)));

    if (props.decimals != null) {
      setDecimals(props.decimals);
    }

    if (props.id != null) {
      setId(props.id);
    }

    if (props.name != null && props.name != '') {
      setName(props.name);
    }
  }, [props]);

  const addSpaces = num => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  const noSpaces = num => num.toString();
  const removeNonNumericWithDecimals = num => num.toString().replace(/[^0-9\.]/g, "");
  const removeNonNumeric = num => num.toString().replace(/[^0-9]/g, "");

  const handleChange = (e) => {
    var { floatValue, val } = handleInput(e.target.value);
    if (isNaN(floatValue)) return;
    setShowBlank(true);
    setValue(addSpaces(val));
    if (valueTimer !== null) {
      clearTimeout(valueTimer);
    }
    valueTimer = setTimeout(() => {
      if (props.onValueChangeCb != null)
        props.onValueChangeCb(floatValue, id, props.name);
    }, keyPressTimer);
  }

  const handleBlur = (e) => {
    if (valueTimer == null) {
      //No pending changes
      return;
    }
    setShowBlank(false);
    clearTimeout(valueTimer);
    var { floatValue, val } = handleInput(e.target.value);
    if (isNaN(floatValue)) floatValue = 0;
    setValue(props.noSpaces ? noSpaces(val) : addSpaces(val));
    if (props.onValueChangeCb != null)
      props.onValueChangeCb(floatValue, id, name);
  }

  const handleInput = (input) => {
    input = input.replace(",", ".");
    var val = input;
    if (decimals > 0) {
      val = removeNonNumericWithDecimals(input)
    }
    else {
      val = removeNonNumeric(input)
    }
    var floatValue = parseFloat(val);
    if (val == '') {
      floatValue = 0;
    }
    return { floatValue, val }
  }

  const display = (val) => {
    if (val === '0' && (decimals === 0 || showBlank == false)) {
      return ""
    }
    return val;
  }

  return (
    <div className='bk-input-container'>
      {props.prefix && <div className="bk-input-prefix">{props.prefix}</div>}
      <input type="text" key={props.key} readOnly={props.readOnly} disabled={props.disabled} placeholder={props.placeholder} className={"bk-input-field align-right" + ' ' + (props.sum ? 'bk-input-field-sum' : '') + ' ' + (props.class ? props.class : '')} value={display(value)} onChange={handleChange} onBlur={handleBlur} />
      {props.suffix && <span className="bk-input-suffix">{props.suffix}</span>}
    </div>
  );
}

const round = (value, precision) => {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

export default NumericInput;