import { AxiosResponse } from 'axios';
import { BaseService } from './base.service';

class AdminService extends BaseService {
   private static _adminService: AdminService;

   private constructor(controllerName: string) {
      super(controllerName,10000);
   }

   public static get Instance(): AdminService {
      return this._adminService || (this._adminService = new this('admin'));
   }

   public async resyncMailMojo(): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.post('MailMojoRefresh', {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });
      
      return axiosResponse;
   }

   public async getCustomers(data): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.post('GetCustomers',data, {withCredentials: true})
      .then((response) => {
      axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });
   
      return axiosResponse;
   }

   
   public async getCustomersSimple(data): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.post('GetCustomersSimple',data, {withCredentials: true})
      .then((response) => {
      axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });
   
      return axiosResponse;
   }



   public async getCustomer(id): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.get('GetCustomer/' + id, {withCredentials: true})
      .then((response) => {
      axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });
   
      return axiosResponse;
   }

   public async getCustomerSharing(id): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.get('GetCustomerSharing/' + id, {withCredentials: true})
      .then((response) => {
      axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });
   
      return axiosResponse;
   }

   public async getProjects(model): Promise<AxiosResponse> {
      var axiosResponse;
  
      await this.$http.post('Projects',model, {withCredentials: true})
      .then((response) => {           
        axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });
      return axiosResponse;
    }

   public async updateCustomer(data): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.post('UpdateCustomer', data, {withCredentials: true})
      .then((response) => {
      axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });
   
      return axiosResponse;
   }


   public async getStatistics(): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.get('GetStatistics/', {withCredentials: true})
      .then((response) => {
      axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });
   
      return axiosResponse;
   }
   
   public async getUserStatistics(userId): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.get('GetUserStatistics/'+ userId, {withCredentials: true})
      .then((response) => {
      axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });
   
      return axiosResponse;
   }

   public async deactivateUser(data): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.post('DeactivateUser',data, {withCredentials: true})
      .then((response) => {
      axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });
   
      return axiosResponse;
   }

   public async activateUser(data): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.post('ActivateUser',data, {withCredentials: true})
      .then((response) => {
      axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });
   
      return axiosResponse;
   }

   public async changeEhfStatus(data): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.post('ChangeEhfStatus',data, {withCredentials: true})
      .then((response) => {
      axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });
   
      return axiosResponse;
   }

   public async resetPassword(data): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.post('ResetPassword',data, {withCredentials: true})
      .then((response) => {
      axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });
   
      return axiosResponse;
   }
   public async setUpdatesSeen(data): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.post('SetUpdatesSeen',data, {withCredentials: true})
      .then((response) => {
         axiosResponse = response;
         }, 
         (error) => {this.handleError(error); });
      return axiosResponse;
   }


   public async getMessages(): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.get('GetMessages/', {withCredentials: true})
      .then((response) => {
      axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });
   
      return axiosResponse;
   }
   public async getUpdateMessages(): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.get('GetUpdateMessages/', {withCredentials: true})
      .then((response) => {
      axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });
   
      return axiosResponse;
   }

   public async getMessageCount(): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.get('GetMessageCount/', {withCredentials: true})
      .then((response) => {
      axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });
   
      return axiosResponse;
   }
   public async getInvoiceCount(): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.get('GetInvoiceCount/', {withCredentials: true})
      .then((response) => {
      axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });
   
      return axiosResponse;
   }

   public async getUpdatesCount(name): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.get('GetUpdatesCount/'+ name, {withCredentials: true})
      .then((response) => {
      axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });
   
      return axiosResponse;
   }


   public async checkoutMessage(data): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.post('CheckoutMessage',data, {withCredentials: true})
      .then((response) => {
      axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });
   
      return axiosResponse;
   }

   public async getSsbIndexes(): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.get('GetSsbIndexes/', {withCredentials: true})
      .then((response) => {
      axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });
   
      return axiosResponse;
   }
   
   public async activateSsbIndex(indexId): Promise<AxiosResponse> {
      var axiosResponse;
      var data = {
         indexId:indexId
      };
      await this.$http.post('ActivateIndex',data, {withCredentials: true})
      .then((response) => {
      axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });
   
      return axiosResponse;
   }

   public async addCustomerSharing(data): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.post('AddCustomerSharing', data, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });
     
      return axiosResponse;
    }

    public async getReportTypes(): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.get('GetReportTypes', {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });
     
      return axiosResponse;
    }

   public async deleteCustomerSharing(data): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.post('DeleteCustomerSharing', data, {withCredentials: true})
      .then((response) => {
        axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });
     
      return axiosResponse;
    }

   public async ConvertSimpleCalculation(): Promise<AxiosResponse> {
      var axiosResponse;
      await this.$http.get('ConvertSimpleCalculation/', {withCredentials: true})
      .then((response) => {
      axiosResponse = response;
      }, 
      (error) => {this.handleError(error); });
   
      return axiosResponse;
   }

}


export const AdminApi = AdminService.Instance;