import React, { useState, useEffect, useRef, } from 'react';
import { useHistory } from 'react-router-dom'
import { AdminApi, AuthApi, ReportApi } from '../../../api';
import { toast } from 'react-toastify';
import { renderToastifyMsg } from '../../../utils';
import { faCheck } from '@fortawesome/free-solid-svg-icons'

const IndexRow = ({ ssbindex, activateIndex, latestindex, countIndex }) => {

   const history = useHistory();
   const toastIdRef = useRef<string | number>('');

   const [SsbIndex, setSsbIndex] = useState([ssbindex.value]);



   const activatebtn = () => {
      if (ssbindex.id == latestindex) {
         if (ssbindex.activated == null) {
            return <div className=''><div className='index-btn' id="activatebtn" onClick={activateIndex}> Aktiver</div></div>
         }
         else return <div className=''><span>{ssbindex.activated}</span></div>

      }
      else return <div className=''><span>{ssbindex.activated}</span></div>
   }

   const formatValue = (value) => {
      if (value == 0) {
         return '-'
      }
      else
         return value + ' %'
   }

   const getRowClass = function () {
      if (countIndex % 2 === 0) {
        return "statistics-data-row white-row";
      }
      else {
        return "statistics-data-row grey-row";
      }
    }
  


   return (
      <>
         <div id="module-row-no-margin" className={[getRowClass(), 'module-row'].join(' ')}>

            <div className='flex-1'>{ssbindex.name}</div>
            <div className='flex-1'>{ssbindex.indexDate}</div>
            <div className='flex-1'>{ssbindex.value} %</div>
            <div className='flex-1'>{formatValue(ssbindex.change)}</div>
            <div className='flex-1'>{activatebtn()}</div>
         </div>
      </>
   )
}

export default React.memo(IndexRow);