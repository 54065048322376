import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { actionCreators } from '../../store/auth';
import { useHistory } from 'react-router-dom'
import { TextField, Button } from '@material-ui/core'
import { HomeApi } from '../../api';
import { RoutesConfig } from '../../config/routes.config';
import { BkButton, SectionHeader } from '../../components';
import { ContactButton } from '../../components';
import parse from 'html-react-parser';
import { consoleLog } from '../../utils/consoleHelper';

type AcceptProps = typeof actionCreators &
{
   readonly isAuthenticated: boolean;
   readonly role: number;
   readonly userName: string;
};


const Accept: React.FC<AcceptProps> = ({
   isAuthenticated,
   userName,
   role,
   logoutUser
}) => {

   const [message, setMessage] = useState({
      messageId: 0,
      messageText: '',
      content: '',
      heading: '',
      termsHeading: '',
      termsContent: ''
   });

   useEffect(() => {
      const fetchData = async () => {
         HomeApi.getAcceptMessage()
            .then(
               (response) => {
                  setMessage(response.data);
               },
               (error) => {
                  console.log(error);
                  logoutUser();
                  history.push(RoutesConfig.Home.path);
               });
      }

      fetchData();
   }, []);


   const history = useHistory();
   if (!isAuthenticated) {
      logoutUser();
      history.push(RoutesConfig.Login.path);
   }


   const handleAccept = () => {
      HomeApi.sendAcceptResponse(message.messageId, true)
         .then(
            (response) => {
               history.push(RoutesConfig.Invoice.path);
            },
            (error) => {
               console.log(error);
            });
   }

   const handleDecline = () => {
      HomeApi.sendAcceptResponse(message.messageId, false)
         .then(
            (response) => {
               logoutUser();
               history.push("/login");
            },
            (error) => {
               console.log(error);
            });
   }

   var contentHtml = parse(message.content);
   var termsHtml = parse(message.termsContent);

   return (
      <>
         <div className="main-container">

            <div className="module-row">
               <div className='bk-text-blue-big flex-4'>
                  {message.termsHeading}
               </div>
            </div>

            <div className="report-content">
               <div className="bk-text-blue">
                  {termsHtml}
               </div>


               <div className="bk-text-blue-big mt-5 mb-3">
                  {message.heading}
               </div>

               <div className="bk-text-blue">{contentHtml}</div>

               <div className="module-row justify-content-end">
                  <div className='flex-10'></div>
                  <div className='flex-1'> <ContactButton caption='Aksepter' onClickHandler={handleAccept} /></div>
                  <div className='flex-1'> <BkButton caption='Avslå' onClickHandler={handleDecline} /></div>
               </div>

            </div>
         </div>
      </>
   )
}

const mapStateToProps = (state: IApplicationState) => ({
   isAuthenticated: state.auth.isAuthenticated,
   role: state.auth.role,
   userName: state.auth.userName,
});

export default connect(mapStateToProps, actionCreators)(Accept);