import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'
import { formatValue, formatDateTime } from '../../../utils/'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons'
import {Menu} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import { CalculationApi } from '../../../api';
import {ConfirmationDialog} from '../../../components/Dialogs'

const CalculationRow = ({calculation, index, onDeleteCb, onCopyCb}) => {
  const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);

  const history = useHistory();

  const getRowClass = function(index) {
    if (index % 2 === 0) {
      return "white-row "
    }
    else {
      return "grey-row "
    }
  }

  const formatDescription = function(description) {
    if (description.length < 17) {
      return description;
    } else {
        return description.substring(0, 15) + '...';
    }
  }

  const id = calculation.calculationId;
  const handleEditCalculation = event => {
    event.preventDefault();
    const url = "/calculation/" + id;
    history.push(url);
  }

  const handleCopyCalculation = event => {
    if (id > 0) {
      onCopyCb(id);
    }
  }

  const handleDeleteCalculation = event => {
    setDisplayConfirmDialog(true);
  }

  const handleConfirmDialogClose = function(id) {
    if (id > 0) {
      onDeleteCb(id);
    }
    setDisplayConfirmDialog(false);
  }



  const handlePrintCalculation = event => {
    event.preventDefault();
    CalculationApi.getPdf(calculation.calculationId).then (
      (response) => {
      },
      (error) => {
        console.log(error);
      }
    );
  }

  const valueReduction = function(reduction,percent) {
    if (percent > 0) {
      return formatValue(calculation.reductionTotal, 0 , ' kr', '-')  + ' (' + formatValue(calculation.reductionPercent, 2 , ' %', '') + ')';
    }
    else {
      return formatValue(calculation.reductionTotal, 0 , ' kr', '-')
    }
  }

  function ActionMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
    
      return <div aria-controls="fade-menu" aria-haspopup="true" className='navbar-profile'  >
          <FontAwesomeIcon icon={faBars}  onClick={handleClick}/>
          <Menu
            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            className="navbar-dropdown-menu"
          >
            <MenuItem onClick={handleEditCalculation}>Rediger</MenuItem>
            <MenuItem onClick={handlePrintCalculation}>Skriv ut</MenuItem>
            <MenuItem onClick={handleCopyCalculation}>Kopier</MenuItem>
            <MenuItem onClick={handleDeleteCalculation}>Slett</MenuItem>
          </Menu>
        </div>;

  }



  return (
    <>
      <div className ={[getRowClass(index), 'module-row main-table-data-row'].join(' ')} id='module-row-no-margin'>
        <div className='flex-01'></div>
        <div className="flex-1" onClick={handleEditCalculation}>{formatDateTime(calculation.created)}</div>
        <div className="flex-1" onClick={handleEditCalculation}>{formatDateTime(calculation.updated)}</div>
        <div className="flex-1" onClick={handleEditCalculation}>{calculation.calculationType}</div>
        <div className="flex-1" title={calculation.description} onClick={handleEditCalculation}>{formatDescription(calculation.description)}</div>
        <div className="flex-1" onClick={handleEditCalculation}>{calculation.buildYear>0?calculation.buildYear : '-'}</div>
        <div className="flex-1" onClick={handleEditCalculation}>{formatValue(calculation.grossArea, 0 , ' m²')}</div>
        <div className="flex-2" onClick={handleEditCalculation}>{formatValue(calculation.totalCost, 0 , ' kr')}</div>
        <div className="flex-2" onClick={handleEditCalculation}>{valueReduction(calculation.reductionTotal, calculation.reductionPercent)}</div>
        <div className="flex-1" onClick={handleEditCalculation}>{formatValue(calculation.totalCost - calculation.reductionTotal, 0 , ' kr')}</div>
        <div className='flex-05'><div className="bk-icon"><ActionMenu /></div>
        </div>
      </div>
    

      <ConfirmationDialog
          title="Slette?"
          text="Er du sikker på at du vil slette kalkulasjonen"
          open={displayConfirmDialog}
          id={id}
          onClose={handleConfirmDialogClose}
      />
    </>
  )
}
CalculationRow.displayName = 'Kalkyle';

export default React.memo(CalculationRow);