import React, {useState, useEffect} from 'react'
import {TextLineInput} from '../components'


type ContactRowProps = {
   readonly refId: number;
   readonly name: string;
   readonly phone: string;
   readonly email: string;
   readonly comment?: string;
   readonly heading?: string;
   readonly displayHeading?: boolean;
   readonly displayComment?: boolean;
   readonly readOnly?: boolean;
   readonly displayHeader:boolean,
   readonly onContactClickedCb?: () => void;
   readonly onCommentChangeCb?: (id:number, value: string) => void;
 };

function ContactInformationRow(props:ContactRowProps) {

   const [comment, setComment] = useState('');

   useEffect(() => {
      setComment(props.comment);
   }, [props]);

   const handleChange = (value, id, name) => {
      setComment(value);
      props.onCommentChangeCb(props.refId, value);
   }

   const onInputClicked = () => {
      props.onContactClickedCb();
   }
  
   return <>
      <div className='module-row '>
         <div className='flex-2 module-row-header'>
            { props.displayHeader &&
               props.heading
            }
            </div>
         <div className='flex-4' onClick={onInputClicked}>
            <TextLineInput class='cursor-pointer' readOnly={true} placeholder="" name='policyHolder' value={props.name} />
         </div>
         <div className='flex-2' onClick={onInputClicked}>
            <TextLineInput class='cursor-pointer' readOnly={true} placeholder="" name='phone' value={props.phone}/>                 
         </div>
         <div className='flex-3' onClick={onInputClicked}>
            <TextLineInput class='cursor-pointer' readOnly={true} placeholder="" name='email' value={props.email}/>                 
         </div>
         <div className='flex-2'>
            <TextLineInput readOnly={props.readOnly}  placeholder="" name='comment' value={comment} onValueChangeCb={handleChange}/>                 
         </div>
      </div>     
   </>
}
  
export default ContactInformationRow;