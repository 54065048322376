import React, {useEffect, useState} from 'react'
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';

const GreenCheckbox = withStyles({
   root: {
     color: 'rgba(0,0,0,0.5)',
     '&$checked': {
       color: 'rgba(106,157,158,1)',
     },
   },
   checked: {},
 })((props: CheckboxProps) => <Checkbox color="default" {...props} />);

function RadioBox(props) {

   const [selectedValue, setSelectedValue] = useState(0);

   useEffect(() => {
      setSelectedValue(props.value);
   }, [props])

   const handleChange=(e)=>{
      const { value } = e.target;
      var valueToSet = value;
      if (value == selectedValue) {
         valueToSet="0";
      }
      setSelectedValue(valueToSet);
      props.onValueChangeCb(parseInt(valueToSet,10));
   }

   return <>
      <GreenCheckbox value="1" className="no-padding" checked={selectedValue == 1} onClick={handleChange} />
      <GreenCheckbox value="2" className="no-padding" checked={selectedValue == 2} onClick={handleChange} />

      { props.numberOfOptions > 2 &&
         <GreenCheckbox value="3" className="no-padding" checked={selectedValue == 3} onClick={handleChange} />
      }
   </>
}
  
export default RadioBox;