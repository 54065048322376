import React, { useState, useEffect } from 'react';
import { actionCreators } from '../../store/auth';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { useHistory, useParams } from 'react-router-dom'
import { AdminApi } from '../../api/admin.service';
import AdminMenu from './child-components/AdminMenu'
import CustomerRow from './child-components/CustomerRow'
import { SelectInput, Spinner, TextLineInput } from '../../components'

type CustomersProps = typeof actionCreators &
{
   readonly isAuthenticated: boolean;
   readonly isAdmin: boolean;
};

const keyPressTimer = 1000;
let inputTimer = null;
const defaultTake = 50;

const Customers: React.FC<CustomersProps> = ({
   isAuthenticated,
   isAdmin,
   logoutUser
}) => {
   const [isLoading, _setIsLoading] = useState(true);
   const [isMount, setIsMount] = useState(true);
   const [customers, setCustomers] = useState([]);
   const [customerTypes, setCustomersTypes] = useState([]);
   const [totalCount, setTotalCount] = useState(0);
   const [adminssearch, setAdminsearch] = useState(false)

   const [searchModel, setSearchModel] = useState({
      take: 10,
      skip: 0,
      sortBy: 'LastLogin',
      searchString: '',
      ascending: false,
      filterByDeactivated: false,
      filterBySubscribers: false,
      filterByNonPayers: false,
      filterByFreeUsers: false,
      userType: 0
   })

   const [filterid, setFilterId] = useState(0)


   const history = useHistory();
   const params = useParams();
   const filter = (params as any).filter;

   const isLoadingRef = React.useRef(isLoading);
   const setIsLoading = data => {
      isLoadingRef.current = data;
      _setIsLoading(data);
   };

   useEffect(() => {
      setSearchModel(searchModel => ({ ...searchModel, take: defaultTake }));
   }, []);


   useEffect(() => {
      // First to skip the initialising phase. 
      // because of security reasons we have to check user role before fetching
      if (isMount) {
         setIsMount(false);
         return;
      }
      // Fetch data based on the changes in the search model
      fetchData();
   }, [searchModel])

   const fetchData = async () => {
      setIsLoading(true);
      AdminApi.getCustomers(searchModel)
         .then(
            (response) => {
               if (searchModel.skip > 0) {
                  setCustomers([...customers, ...response.data.customers]);
               }
               else {
                  setCustomers(response.data.customers);
                  setTotalCount(response.data.totalCount)
               }
               setIsLoading(false);
            },
            (error) => {
               console.log(error);
               setIsLoading(false);
            });
   }


   if (!isAuthenticated) {
      logoutUser();
      history.push("/login");
   }

   if (!isAdmin) {
      history.push("/projects");
   }


   const handleScroll = function (e) {
      const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 50;
      if (bottom && !isLoading) {
         var skip = searchModel.skip + searchModel.take;
         setSearchModel(searchModel => ({ ...searchModel, take: defaultTake, skip: skip }));

      }

   }

   const doTextSearch = function (searchText) {
      setSearchModel(searchModel => ({ ...searchModel, take: defaultTake, skip: 0, searchString: searchText }));
   }

   const onSearchTextChanged = function (value, id, name) {

      if (inputTimer !== null) {
         clearTimeout(inputTimer);
      }

      inputTimer = setTimeout(() => {
         doTextSearch(value);
      }, keyPressTimer);
   }

   const onSelectChanged = (value, id, name) => {

      if (value == 0) {
         setSearchModel(searchModel => ({ ...searchModel, take: defaultTake, skip: 0, userType: 0 }));
      }
      else if (value == 1) {
         setSearchModel(searchModel => ({ ...searchModel, take: defaultTake, skip: 0, filterBySubscribers: true, userType: 0 }));
      }
      else if (value == 2) {
         setSearchModel(searchModel => ({ ...searchModel, take: defaultTake, skip: 0, filterByDeactivated: true, userType: 0 }));
      }
      else if (value == 3) {
         setSearchModel(searchModel => ({ ...searchModel, take: defaultTake, skip: 0, filterByDeactivated: true, filterBySubscribers: true, userType: 0 }));
      }
      else if (value == 4) {
         setSearchModel(searchModel => ({ ...searchModel, take: defaultTake, skip: 0, filterByNonPayers: true, userType: 0 }));
      }
      else if (value == 5) {
         setSearchModel(searchModel => ({ ...searchModel, take: defaultTake, skip: 0, filterByFreeUsers: true, userType: 0 }));
      }
      else if (value == 6) {
         setSearchModel(searchModel => ({ ...searchModel, take: defaultTake, skip: 0, userType: 4 }));
      }
      else if (value == 7) {
         setSearchModel(searchModel => ({ ...searchModel, take: defaultTake, skip: 0, userType: 2 }));
      }
   }
   const [userStatistics, setUserStatistics] = useState({
      /*acceptedUsers: {},
      projects: {},
      services: {},*/
      otherCalculations: [],
      calculations: [],
      reports: []
   });


   const fetchstatData = async (id) => {
      AdminApi.getUserStatistics(id)
         .then(
            (response) => {
               setUserStatistics(response.data);
            },
            (error) => {
               console.log(error);
            });

      return userStatistics
   }

   let customerRows = null;
   if (customers && customers.length > 0) {
      customerRows = customers.map((customer, index) => {
         return (
            <CustomerRow customer={customer} index={index}  ></CustomerRow>
         )
      });
   }

   var filterOptions = [
      { key: 1, value: 1, name: 'Abonnenter' },
      { key: 2, value: 2, name: 'Deaktiverte' },
      { key: 3, value: 3, name: 'Abonnenter - Deaktivert' },
      { key: 4, value: 4, name: 'Ikke abonnenter' },
      { key: 5, value: 5, name: 'Gratis til' },
      { key: 6, value: 6, name: 'Studenter' },
      { key: 7, value: 7, name: 'Admin' }
   ]

   // <option key={1} value={1}>Abonnenter</option>
   // <option key={2} value={2}>Deaktiverte</option>
   // <option key={3} value={3}>Abonnenter - Deaktivert</option>
   // <option key={4} value={4}>Ikke abonnenter</option>
   // <option key={5} value={5}>Gratis til</option>
   // <option key={6} value={6}>Studenter</option>
   // <option key={7} value={7}>Admin</option>   

   return (
      <>
         <div className="admin-container">

            <AdminMenu activeMenuItem={'Kunder'} activeSubIndex={filter}></AdminMenu>

            <div>
               <div className='module-row my-3'>
                  <div className='flex-1'><TextLineInput value={searchModel.searchString} placeholder="Søk ..." onValueChangeCb={onSearchTextChanged}></TextLineInput></div>
                  <div className='flex-05'><SelectInput defaultOption='Alle kunder' selectedValue={0} options={filterOptions} valueKey='value' nameKey='name' onValueChangeCb={onSelectChanged}></SelectInput></div>
                  {/* <div className="">
                        <select className='form-control' >
                           <option key={0} value={0}>Alle kunder</option>
   
                        </select>
                     </div> */}
                  <div className='bk-text-blue flex-1'>(Antall: {totalCount})</div>
                  <div className='flex-3'></div>
               </div>

               <div className="main-table-header-row module-row" id='module-row-no-margin'>
                  <div className="flex-015 ms-1">Nr</div>
                  <div className="flex-025">Status</div>
                  <div className="flex-05">Kundenr</div>
                  <div className="flex-1">Etternavn</div>
                  <div className="flex-1">Fornavn</div>
                  <div className="flex-1">Firmanavn</div>
                  <div className="flex-05">Registrert</div>
                  <div className="flex-05">Siste login</div>
                  <div className="flex-025 align-right">Prosj.</div>
                  <div className="flex-025 align-right">Kalk.</div>
                  <div className="flex-025 align-right">Rapp.</div>
                  <div className="flex-015"></div>
               </div>

               <div className="main-table-data-container" onScroll={handleScroll}>
                  {customerRows}
               </div>


            </div>
            <Spinner isLoading={isLoading} />
         </div>
      </>
   );
}

const mapStateToProps = (state: IApplicationState) => ({
   isAuthenticated: state.auth.isAuthenticated,
   isAdmin: state.auth.isAdmin
});

export default connect(mapStateToProps, actionCreators)(Customers);