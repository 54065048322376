import React, { useState, useEffect, useRef } from 'react'
import { FinanceTableApi } from '../api';
import { FinanceTable } from '.'
import { ConfirmationDialog, AlertDialog } from './Dialogs'
import { NumericInput, SectionHeader, CategoryHeader } from '.'

type FinanceTablesProps = {
   readonly moduleId: number;
   readonly subId: number;
   readonly descriptionTitle: string;
   readonly heading?: string;
   readonly valueTitle: string;
   readonly sumTitle: string;
   readonly infoText: string;
   readonly hideInput?: boolean;
   readonly showComment?: boolean;
   readonly allowMultiple?: boolean;
   readonly budget?: boolean;
   readonly budgetTables?: any[];
   readonly onValueChangeCb?: (value: number) => void;
};

function FinanceTables(props: FinanceTablesProps) {

   const [financeTables, setFinanceTables] = useState([]);
   const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
   const [displayAlertDialog, setAlertConfirmDialog] = useState(false);
   const [idToDelete, setIdToDelete] = useState(0);

   useEffect(() => {
      //Fetch comments for this module
      loadData()
   }, [props]);


   const loadData = () => {
      FinanceTableApi.getAll(props.moduleId, props.subId)
         .then(
            (response) => {
               setFinanceTables(response.data);
            },
            (error) => {
               console.log(error);
            })

   }
   const onValueChange = () => {
      loadData()
   }

   const onTableChanged = (id, heading) => {
      let newTable = financeTables.map((table, index) => {
         if (table.financeTableId == id) {
            table = { ...table, heading: heading };
         }
         return table
      });

      setFinanceTables(newTable);

   }

   const onTableAdded = (id) => {
      var data = {
         moduleId: props.moduleId,
         subId: findNextSubId(),
         financeTableId: 0,
         heading: null
      }
      FinanceTableApi.update(data)
         .then(
            (response) => {
               setFinanceTables([...financeTables, response.data])
            },
            (error) => {
               console.log(error);
            })
   }

   const onTableDeleted = (id) => {

      if (financeTables.length <= 1) {
         setAlertConfirmDialog(true);
         return;
      }
      setIdToDelete(id);
      setDisplayConfirmDialog(true);

   }

   const findNextSubId = () => {
      var subId = 1;
      financeTables.map((table, index) => {
         if (table.subId >= subId) {
            subId = table.subId + 1;
         }
      });
      return subId;
   }

   const handleAlertDialogClose = function () {
      setAlertConfirmDialog(false);
      return;
   }

   const handleConfirmDialogClose = function () {
      if (idToDelete > 1) {
         FinanceTableApi.delete(idToDelete)
            .then(
               (response) => {
                  setFinanceTables(financeTables.filter(table => table.financeTableId !== idToDelete));
                  //Todo if this was the last one
               },
               (error) => {
                  console.log(error);
               })
      }
      setDisplayConfirmDialog(false);
   }

   const calcSingleRowSum = function (array) {
      var sum = 0
      if (array != null) {
         array.map((row, index) => {
            sum += row.value
         })
      }
      return sum

   }

   const calcTotalSum = () => {
      var totalSum = 0
      financeTables.map((table) => {
         totalSum += calcSingleRowSum(table.rows)
      })
      return totalSum
   }

   var tablesHtml = null;
   if (financeTables) {
      tablesHtml = financeTables.map((table, index) => {
         return <>
            <FinanceTable
               moduleId={table.moduleId}
               subId={table.subId}
               descriptionTitle={props.descriptionTitle}
               valueTitle={props.valueTitle}
               sumTitle={props.sumTitle}
               showHeader={true}
               header={props.heading ? props.heading : 'Kalkulasjon:'}
               displayIcons={true}
               infoText={props.infoText}
               order={index}
               hideInput={props.hideInput}
               showComment={props.showComment}
               allowMultiple={props.allowMultiple}
               onValueChangeCb={onValueChange}
               onTableChangedCb={onTableChanged}
               onTableAddedCb={onTableAdded}
               onTableDeletedCb={onTableDeleted}
            />
         </>
      });
   }

   var budgetTablesHtml = null;
   if (props.budget) {
      budgetTablesHtml = props.budgetTables.map((table, index) => {
         if (table.description != '' && table.rowId != 0) {
            return <>
               <FinanceTable
                  moduleId={table.moduleId}
                  subId={index + 1}
                  descriptionTitle={table.description}
                  valueTitle='Kostnad inkl. mva'
                  sumTitle='Sum faktura:'
                  onValueChangeCb={onValueChange}
                  allowBudget={true}
                  budget={table.price}
                  budgetTitle='Budsjett:'
                  budgetResultTitle='Gjenstående:'
                  enableCount={true}
                  countNumber={index + 1}
               ></FinanceTable>
            </>
         }
      });
   }

   var sumsHtml = null;
   if (financeTables) {
      sumsHtml = financeTables.map((table, index) => {
         return <>
            <div className="module-row">
               <div className="flex-10 module-row-title-big "><span className='me-3'>{table.heading != null ? 'Kalkulasjon: ' + table.heading : 'Kalkulasjon: '}</span></div>
               <div className="flex-2 module-row-title">
                  <NumericInput suffix=',-' disabled={true} value={calcSingleRowSum(table.rows)} placeholder={'0'} class={'align-right'} ></NumericInput>
               </div>
               <div className='bk-icon delete-icon'></div>
            </div>
         </>
      })
   }

   const calculateSumBudget = () => {
      if (props.budget) {
         var sumBudget = 0;
         props.budgetTables.map((table) => {
            sumBudget += table.price
         })
         return sumBudget
      }
   }
   const calculateSumInvoices = () => {
      if (financeTables) {
         var sumInvoices = 0;
         financeTables.map((table) => {
            sumInvoices += calcSingleRowSum(table.rows)
         })
         return sumInvoices
      }
   }
   const calculateSumRemaining = () => {
      if (props.budget) {
         if (financeTables) {
            var sumRemaining = calculateSumBudget() - calculateSumInvoices()
            return sumRemaining
         }
      }
   }

   var rowsWithData = financeTables;
   if (props.budget && props.budgetTables != null) {
      rowsWithData = props.budgetTables.filter(row => row.description != '' || row.price != 0);
   }

   return <>
      {props.budget ?
         <>
            <SectionHeader title='Fakturaoppfølging'></SectionHeader>
            <div className='content-frame'>
               {budgetTablesHtml}
            </div>
         </>
         :
         tablesHtml
      }

      {props.budget ?
         financeTables.length > 1 && rowsWithData.length > 1 ?
            <>
               <div className='category-header-module'>Sammendrag-Totaloversikt</div>
               <div className='content-frame'>
                  <div className="module-row" key={"finance-table-sum"}>
                     <div className="flex-015"></div>
                     <div className="flex-10 module-row-title-big  align-right bold"></div>
                     <div className="flex-2 module-row-title">
                        <div className='module-row-title bold'>
                           Kostnad inkl. mva
                        </div>
                     </div>
                     <div className='bk-icon delete-icon'></div>
                  </div>
                  <div className="module-row" key={"finance-table-sum"}>
                     <div className="flex-015"></div>
                     <div className="flex-10 module-row-title-big  align-right bold"><span className='padding-right-20'>Sum faktura:</span></div>
                     <div className="flex-2 module-row-title">
                        <NumericInput suffix=',-' disabled={true} value={calculateSumInvoices()} placeholder={'0'} class={'align-right'} ></NumericInput>

                     </div>
                     <div className='bk-icon delete-icon'></div>
                  </div>
                  <div className="module-row" key={"finance-table-sum"}>
                     <div className="flex-015"></div>
                     <div className="flex-10 module-row-title-big  align-right bold"><span className='padding-right-20'>Sum budsjett:</span></div>
                     <div className="flex-2 module-row-title">
                        <NumericInput suffix=',-' disabled={true} value={calculateSumBudget()} placeholder={'0'} class={'align-right'} ></NumericInput>

                     </div>
                     <div className='bk-icon delete-icon'></div>
                  </div>
                  <div className="module-row" key={"finance-table-sum"}>
                     <div className="flex-015"></div>
                     <div className="flex-10 module-row-title-big  align-right bold"><span className='padding-right-20'>Sum gjenstående:</span></div>
                     <div className="flex-2 module-row-title">
                        <NumericInput suffix=',-' disabled={true} value={calculateSumRemaining()} placeholder={'0'} class={'align-right'} ></NumericInput>

                     </div>
                     <div className='bk-icon delete-icon'></div>
                  </div>
               </div>
            </>
            :
            <></>
         :
         financeTables.length > 1 ?
            <>

               <div className='module-section'>
                  <SectionHeader title={"Oppsummering av kalkulasjoner "}></SectionHeader>
                  <div className='content-frame'>

                     {sumsHtml}

                     <div className="module-row">
                        <div className="flex-10 module-row-title-big align-right bold"><span className='me-3'>{props.sumTitle}</span></div>
                        <div className="flex-2 module-row-title">
                           <NumericInput suffix=',-' disabled={true} value={calcTotalSum()} placeholder={'0'} class={'align-right bold-important'} ></NumericInput>
                        </div>

                        <div className='bk-icon delete-icon'></div>
                     </div>
                     <div className="module-row">
                        <div className='flex-10 module-row-title-small align-right bold'>{props.infoText}</div>
                        <div className='bk-icon'></div>
                     </div>
                  </div>
               </div>

            </>
            :
            <></>
      }



      <ConfirmationDialog
         title="Slette?"
         text="Er du sikker på at du vil slette kalkulasjonen"
         open={displayConfirmDialog}
         id={idToDelete}
         onClose={handleConfirmDialogClose}
      />

      <AlertDialog
         title="Kan ikke slette kalkulasjon"
         text="Det må alltid være minst en kalkulasjon i denne rapporten"
         open={displayAlertDialog}
         onClose={handleAlertDialogClose}
      />
   </>
}

export default FinanceTables;