import React, {useState, useEffect, useCallback, useRef, useLayoutEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CameraDialog } from './'
import { useDropzone } from 'react-dropzone'
import { UploadApi } from '../../api';
import { BkButton, BkButtonSmall, ContactButton } from '../'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons'

export interface UploadDialogProps {
   url:string;
   open: boolean;
   enableCamera?: boolean;
   handleCancelCb: () => void;
   handleImageUrlUpdatedCb: (imageUrl: string) => void;
 }

const UploadDialog = (props: UploadDialogProps) => {
   const [openDialog, setOpenDialog] = useState(false);
   const [imageUrl, setImageUrl] = useState(null);
   const [rotation, setRotation] = useState(0);
   const [translateX, setTranslateX] = useState(0);
   const [translateY, setTranslateY] = useState(0);
   const [imageWidth, setImageWidth] = useState(0);
   const [imageHeight, setImageHeight] = useState(0);
   const [paddingBottom, setPaddingBottom] = useState(0);
   const [marginLeft, setMarginLeft] = useState(0);

   const [imgWidth, setImgWidth] = useState(0);
   const [imgHeight, setImgHeight] = useState(0);
   const [containerWidth, setContainerWidth] = useState(0);
   const [containerHeight, setContainerHeight] = useState(0);

   const imageRef = useRef(null)
   const imageContainerRef = useRef(null)
   const [openCameraDialog, setOpenCameraDialog] = useState(false);


   useEffect(() => {
      setImageUrl(props.url);
   }, [props.url]);


   useEffect(() => {
      setOpenDialog(props.open);
      setImageUrl(props.url);
   }, [props.open]);

   const onDrop = useCallback(acceptedFiles => {
      setRotation(0);
      var data = new FormData();
  
      if (acceptedFiles.length > 0) {
        data.append("file", acceptedFiles[0]);
      } else {
          return;
      }
     
      UploadApi.upload(data)
      .then(
         (response) => {
            setImageUrl(response.data);
         },
         (error) => {
            console.log(error);
         }
      )
   }, []);
 
   const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

   const handleCloseEdit = () => {
      reset(imageUrl);
      props.handleCancelCb();
   };

   const handleSaveAndCloseEdit= () => {   
      if (rotation > 0) {
         UploadApi.Transform(imageUrl,rotation)
            .then(
               (response) => {
                  reset(response.data);
                  props.handleImageUrlUpdatedCb(response.data);
            },
            (error) => {
               console.log(error);
            }
         )
      }
      else {
         props.handleImageUrlUpdatedCb(imageUrl);
      }
     
   }

   const reset= function(url) {
      setContainerHeight(0);
      setContainerWidth(0);
      setImgHeight(0);
      setImgWidth(0);
      setRotation(0);
      setPaddingBottom(0);
      setMarginLeft(0);
      setImageUrl(url);
      setTranslateX(0);
      setTranslateY(0);
      if (imageRef.current != null) {
        imageRef.current.style.width='';
        imageRef.current.style.height='';
      }
   }

   const handleDeleteImage = function(e) {
      reset(null);
   }


   const handleImageRotate = function() {
      var cHeight = containerHeight;
      var cWidth = containerWidth;
      var iHeight = imgHeight;
      var iWidth = imgWidth;

      // Get images
      if (imageContainerRef.current && cHeight == 0) {
         cHeight = imageContainerRef.current.clientHeight;
         cWidth = imageContainerRef.current.clientWidth;
         setContainerHeight(cHeight);
         setContainerWidth(cWidth);
      }

      if (imageRef.current && iHeight == 0) {
         iHeight = imageRef.current.clientHeight;
         iWidth = imageRef.current.clientWidth;
         setImgHeight(iHeight);
         setImgWidth(iWidth);
      }

      // Set the rotation
      if (rotation == 270) {
        setRotation(0);
      }
      else {
        setRotation(rotation + 90);
      }


      // Allign image
      if (rotation == 0) {
        setTranslateX(0);
        setTranslateY(-100);

        var heigh = 100;
        var scale = 1;
        if (iWidth > iHeight) {
         scale = iWidth / iHeight;
         setImageWidth(cHeight);
         setImageHeight(cHeight/scale);
         setPaddingBottom(iHeight - iHeight/scale);
         setMarginLeft((cWidth - cHeight/scale) / 2);
      }
      else if (iHeight > iWidth) {
         var a = cWidth/iHeight;
         scale = iWidth / iHeight;
         setImageWidth(cWidth * scale);
         setImageHeight(cWidth);
         setPaddingBottom(0);
         setMarginLeft(0);
        }

      }
      else if (rotation == 90) {
        setTranslateX(-100);
        setTranslateY(-100);
        setImageWidth(iWidth);
        setImageHeight(iHeight);
        setPaddingBottom(0);
        setMarginLeft(0);
      }
      else if (rotation == 180) {
        setTranslateX(-100);
        setTranslateY(0);
        var scale = 1;
        if (iWidth > iHeight) {
         scale = iWidth / iHeight;
         setImageWidth(cHeight);
         setImageHeight(cHeight/scale);
         setPaddingBottom(iHeight - iHeight/scale);
         setMarginLeft((cWidth - cHeight/scale) / 2);
        }

      }
      else if (rotation == 270) {
        setTranslateX(0);
        setTranslateY(0);
        setImageWidth(iWidth);
        setImageHeight(iHeight);
        setPaddingBottom(0);
        setMarginLeft(0);
      }
   }

   const onCameraClicked = () => {
      setOpenCameraDialog(true);
   }

   const handleCameraCancel = () => {
      setOpenCameraDialog(false);
   }

   const handleCameraSave = (uri) => {
      setOpenCameraDialog(false);

      //Save image
      var data= {
         uri:uri
      }

      UploadApi.SaveImage(data)
      .then(
         (response) => {
            setImageUrl(response.data);
         },
         (error) => {
            console.log(error);
         }
      )

   }

   const getPicture = function() {
      if (imageUrl !== null && imageUrl !== 'undefined' && imageUrl !== '') {
        return (
            <div  className="upload-dialog-image-container" {...getRootProps()}>
               <input {...getInputProps()} />
               {/*  */}
               
               <div className="upload-dialog-image" style={{paddingBottom:paddingBottom + "px"}}>
                  <img ref={imageRef} src={"/upload/" + imageUrl} 
                        style={{transform: "rotate(" + rotation + "deg) translate(" + translateX + "%," + translateY + "%)", width: imageWidth>0 ?imageWidth + "px":null, height:imageHeight>0 ? imageHeight + "px":null, marginLeft:marginLeft + "px"}} 
                        onError={({ currentTarget }) => {
                           currentTarget.onerror = null; // prevents looping
                           currentTarget.src="/images/Hus_linje_ill.jpg";
                         }}
                  />
               </div>
            </div>
        )
      }
      else {
        return ( 
          <div className="upload-dialog-border" {...getRootProps()}>
            <input {...getInputProps()} />
            <div className="bk-text-blue bold">Dra og slipp</div>
            <div className="bk-text-blue">eller klikk her for å laste opp bilde</div>
          </div>);
      }
    }

  const getPictureHelpText = function() {
    if (imageUrl !== null && imageUrl !== 'undefined' && imageUrl !== '') {
      return (
        <div className="bk-text-blue module-row align-items-center justify-content-center">
          <span>Klikk på bildet eller dra inn et nytt bilde for å laste opp</span>
          <div className="upload-dialog-transform-buttons">
            <BkButtonSmall caption='Roter' onClickHandler={handleImageRotate}/>
          </div>
        </div>
      )
    }
    else {
      return ''
    }
  }

   return (
   <>
      <Dialog open={openDialog} onClose={handleCloseEdit} aria-labelledby="form-dialog-title" keepMounted={true}>
         <DialogTitle className="dialog-header">Legg til bilde
         { props.enableCamera && 
            <span className="upload-dialog-camera-icon"><FontAwesomeIcon icon={faCamera} className="assessment-instruction-icon" title="Åpne kamera"  onClick={onCameraClicked}/></span>
         }
         </DialogTitle>
         <DialogContent>
            {getPictureHelpText()}
            
            <div ref={imageContainerRef} className="upload-dialog-frame" >
            {getPicture()}
            </div>
         </DialogContent>
         <DialogActions  className="upload-buttons">

            <BkButton caption='Slett' onClickHandler={handleDeleteImage}/>
            <ContactButton caption='Avbryt' onClickHandler={handleCloseEdit}/>
            <BkButton caption='Lagre'  onClickHandler={handleSaveAndCloseEdit} />

         </DialogActions>
      </Dialog>

      <CameraDialog
         open={openCameraDialog}
         handleSaveCb = {handleCameraSave}
         handleCancelCb = {handleCameraCancel}
      />

   </>
  )
}

export default React.memo(UploadDialog);