import React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { BkButton } from '../'
import { ContactButton } from '../'
import Dialog from '@material-ui/core/Dialog';
import parse from 'html-react-parser';
export interface ConfirmationDialogProps {
   title: string;
   open: boolean;
   grades: any,
   instructions: any,
   onClose: (tgId: number) => void;
}

export default function TgDialog(props: ConfirmationDialogProps) {
   const { onClose, open, title, grades, instructions } = props;

   const handleCancel = () => {
      onClose(-1);
   };

   const handleReset = () => {
      onClose(0);
   };

   const handleSelected = (tgId, showInDialog) => {
      if (!showInDialog) {
         return;
      }
      onClose(tgId);
   };

   // const isDisabled = (tgId) => {
   //    var details = getDetails(tgId);
   //    if (details.toLowerCase().indexOf("anvendes ikke") >= 0){
   //       return true;
   //    }
   //    return false;
   // }

   const getDetails = (gradeId) => {
      if (instructions != null) {
         var instruction = instructions.find(i => i.gradeId == gradeId);
         if (instruction != null) {
            return instruction.instructions;
         }
      }
      var grade = grades.find(i => i.gradeId == gradeId);
      if (grade != null) {
         return grade.details;
      }
      return "";
   }

   var gradesHtml = null;
   if (grades) {
      gradesHtml = grades.map((grade, index) => {
         var showInDialog = true
         if (instructions != null) {
            var instruction = instructions.find(i => i.gradeId == grade.gradeId);
            if (instruction != null) {
               showInDialog = instruction.displayInDialog;
            }
         }

         return <>
            <div className="module-row mt-4 tg-row">
               <div className='flex-05 justify-center'>
                  <div className={ (showInDialog ? "tg-button" : " tg-button-disabled")}
                     style={{ backgroundColor: grade.colorCode, borderColor: grade.colorCode, boxShadow: "6px 5px white, 6px 5px 0 1.5px" + grade.colorCode }}
                     onClick={() => handleSelected(grade.gradeId, showInDialog)}>{grade.name}
                  </div>
               </div>

               {instructions == null || instructions.length == 0 &&
                  <div className="flex-1">{grade.description}</div>
               }

               <div className={"flex-2"}>{parse(getDetails(grade.gradeId))}</div>

            </div>
         </>
      });

   }

   return (
      <Dialog
         maxWidth="md"
         aria-labelledby="tg-dialog-title"
         open={open}
         onClose={handleCancel}
      >
         <DialogTitle className="dialog-header">{title}</DialogTitle>
         <DialogContent>
            {gradesHtml}
         </DialogContent>
         <DialogActions className="dialog-actions">
            <ContactButton caption='Avbryt' onClickHandler={handleCancel} />
            <BkButton caption='Nullstill' onClickHandler={handleReset} />
         </DialogActions>
      </Dialog>
   );
}
