import React, { useState, useEffect, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faExclamation, faExclamationCircle, faMicrochip } from '@fortawesome/free-solid-svg-icons'
import { PhraseDialog } from '../Dialogs'
import { toXmlSafeText } from '../../utils';
import { AiDialog, } from '../../components/Dialogs'
import { Spinner } from '../../components'
import { toast } from 'react-toastify';
import { renderToastifyMsg } from '../../utils';

type AutoTextBoxProps = {
   readonly text: string;
   readonly defaultRows?: number;
   readonly lineHeight?: number;
   readonly phraseRegisterKey?: string;
   readonly class?: string;
   readonly id?: number;
   readonly focus?: boolean;
   readonly readOnly?: boolean;
   readonly addPadding?: boolean;
   readonly smallIcon?: boolean;
   readonly disabled?: boolean;
   readonly name?: string;
   readonly hardRows?: number;
   readonly onValueChangeCb?: (value: string, id?: number, name?: string) => void;
   readonly onSelectedChangeCb?: (value: string) => void;
   readonly enableAi?: boolean;
};

const keyPressTimer = 2000;
let textTimer = null;

function AutoTextBox(props: AutoTextBoxProps) {

   const [rows, setRows] = useState(2);
   const [text, setText] = useState('');
   const [name, setName] = useState('');
   const [selectedText, setSelectedText] = useState('');
   const [lineHeight, setLineHeight] = useState(21);
   const [openPhraseDialog, setOpenPhraseDialog] = useState(false);
   const textBoxRef = useRef(null);
   const [key, setKey] = useState('');
   const [id, setId] = useState(0);
   const [readOnly, setReadOnly] = useState(false);
   const [aiDialog, setAiDialog] = useState(false);

   const toastIdRef = useRef<string | number>('');


   let init = 0

   useEffect(() => {
      setText(props.text);
      setKey(props.phraseRegisterKey);

      if (props.defaultRows != null) {
         setRows(props.hardRows ? props.hardRows : props.defaultRows);
      }
      if (props.lineHeight != null) {
         setLineHeight(props.lineHeight);
      }
      setText(props.text)
      if (props.id != null && props.id != undefined) {
         setId(props.id);
      }
      if (props.name) {
         setName(props.name);
      }
      if (props.readOnly != null) {
         setReadOnly(props.readOnly);
      }
   }, [props]);


   useEffect(() => {
      if (!init) {
         if (!props.hardRows) {
            const previousRows = textBoxRef.current.rows;
            textBoxRef.current.rows = props.defaultRows;

            const currentRows = ~~(textBoxRef.current.scrollHeight / lineHeight);
            if (currentRows === previousRows) {
               textBoxRef.current.rows = currentRows;
            }
            setRows(currentRows);
            init = 1;
         }
         else {
            setRows(props.hardRows);
         }

      }
      if (props.focus) {
         textBoxRef.current.focus();
      }
   });

   const handleChange = (e) => {
      const minRows = props.defaultRows;
      const { value } = e.target;
      setText(toXmlSafeText(value));

      const previousRows = e.target.rows;
      e.target.rows = minRows; // reset number of rows in textarea 

      const currentRows = ~~(e.target.scrollHeight / lineHeight);

      if (currentRows === previousRows) {
         e.target.rows = currentRows;
      }

      setRows(currentRows);

      if (textTimer !== null) {
         clearTimeout(textTimer);
      }
      textTimer = setTimeout(() => {
         props.onValueChangeCb(toXmlSafeText(value), id, name);
      }, keyPressTimer);
   }


   const handleBlur = (e) => {
      if (textTimer !== null && props.onValueChangeCb != null) {
         clearTimeout(textTimer);
         const { value } = e.target;
         if (value != null) {
            props.onValueChangeCb(toXmlSafeText(value), id, name);
         }
      }
   }

   const openPhraseregister = () => {
      if (!readOnly) {
         let cursorStart = textBoxRef.current.selectionStart;
         let cursorEnd = textBoxRef.current.selectionEnd;
         if (cursorEnd > cursorStart) {
            setSelectedText(text.substring(cursorStart, cursorEnd));
         }
         else {
            setSelectedText(text);
         }
         setOpenPhraseDialog(true);
      }
   }


   const handlePhraseSave = (value) => {
      setOpenPhraseDialog(false);
      if (value == '') {
         return;
      }
      if (text == null || text == undefined || text == '') {
         setText(value);
         props.onValueChangeCb(value, id, name);
      }
      else {
         setText(text + '\r\n' + value);
         props.onValueChangeCb(text + '\r\n' + value, id, name);
      }
   }


   const handlePhraseCancel = () => {
      setOpenPhraseDialog(false);
   }

   const onClickAiDialog = () => {
      if (text != '' && text != null && text.length > 50) {
         setAiDialog(true);
      }
      else if (text != '' && text != null && text.length < 50) {
         if (!toast.isActive(toastIdRef.current)) {
            toastIdRef.current = toast.info(
               renderToastifyMsg("Teksten er for kort", faExclamationCircle)
            );
         }
      }
      else {
         if (!toast.isActive(toastIdRef.current)) {
            toastIdRef.current = toast.info(
               renderToastifyMsg("Skriv inn tekst inn i feltet", faExclamationCircle)
            );
         }
      }
   }

   const handleAiSave = (text) => {
      setAiDialog(false);
      setText(text);
      props.onValueChangeCb(text);
   }

   const handleAiCancel = () => {
      setAiDialog(false);
   }

   const handleMouseUp = () => {
      if (props.onSelectedChangeCb != null) {
         let cursorStart = textBoxRef.current.selectionStart;
         let cursorEnd = textBoxRef.current.selectionEnd;
         var selected = '';
         if (cursorEnd > cursorStart) {
            selected = text.substring(cursorStart, cursorEnd)
         }

         if (selected != selectedText) {
            setSelectedText(selected);
            props.onSelectedChangeCb(selected);
         }
      }

   }

   return <>
      <div className="auto-text-box-frame" key={id}>

         <textarea ref={textBoxRef} name={name} readOnly={readOnly} disabled={props.disabled} className={"bk-input-field " + props.class} rows={rows} value={text} onChange={handleChange} onBlur={handleBlur} onMouseUp={handleMouseUp} />

         {(props.smallIcon) &&
            <>
               <div className="bk-icon" >
                  <FontAwesomeIcon icon={faClipboardList} onClick={openPhraseregister} />
               </div>
            </>
         }

         {(!props.smallIcon && props.phraseRegisterKey != null && props.phraseRegisterKey != '' && !readOnly) &&
            <>
               <div className="bk-icon" >
                  <FontAwesomeIcon icon={faClipboardList} onClick={openPhraseregister} />
               </div>
               {(props.enableAi) &&
                  <div className="bk-icon" >
                     <img src='/Content/images/AiIcon.svg' onClick={onClickAiDialog} style={{ width: '1rem', marginBottom: '0.2rem' }} />
                  </div>
               }
            </>
         }

         {(props.addPadding) &&
            <>
               <div className="bk-icon" ></div>
            </>
         }

      </div>

      <PhraseDialog
         open={openPhraseDialog}
         phraseKey={key}
         text={selectedText}
         handleSaveCb={handlePhraseSave}
         handleCancelCb={handlePhraseCancel}
      />

      <AiDialog
         open={aiDialog}
         handleSaveCb={handleAiSave}
         handleCancelCb={handleAiCancel}
         text={text}
      />

   </>
}

export default AutoTextBox;