import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { actionCreators } from '../../store/auth';
import { ProjectApi, ProjectsApi, CountyApi } from '../../api';
import { useHistory } from 'react-router-dom'
import { ProjectRow, EditProjectDialog } from './child-components'
import SearchIndicator from '../../components/SearchIndicator'
import { isNullOrEmpty } from '../../utils'
import { BkButton, Spinner, BkButtonSmall, SelectInput, TextLineInput } from '../../components'
import ReportFilter from './child-components/ReportFilter';
import OwnerFilter from './child-components/OwnerFilter';

type ProjectProps = typeof actionCreators &
{
   readonly isAuthenticated: boolean;
   readonly role: number;
   readonly userName: string;
   readonly userId: string;
};

const defaultTake = 40;

const Projects: React.FC<ProjectProps> = ({
   isAuthenticated,
   userName,
   role,
   logoutUser
}) => {
   const [isLoading, _setIsLoading] = useState(true);
   const [isMount, setIsMount] = useState(true);
   const [projects, setProjects] = useState([]);
   const [sharedUsers, setSharedUsers] = useState([]);
   const [usersFilter, setUsersFilter] = useState([]);
   const [loggedUserId, setLoggedUserId] = useState(0);
   const [searchText, setSearchText] = useState('');
   const [openNew, setOpenNew] = React.useState(false);
   const [addressData, setAddressData] = useState({
      projectId: 0,
      address: '',
      zipCode: '',
      city: '',
      gnr: '',
      bnr: '',
      fnr: '',
      snr: '',
      lnr: '',
      andelsnr: '',
      countyId: 0,
   });
   const [sortField, setSortField] = useState("ModifiedDate");
   const [ascending, setAscending] = useState(false);

   const [counties, setCounties] = useState([]);
   const [reportTypes, setReportTypes] = useState([]);
   const [selectedReportType, setSelectedReportType] = useState(0);
   const [searchModel, _setSearchModel] = useState({
      SearchText: '',
      index: 0,
      take: defaultTake,
      sortField: "ModifiedDate",
      sortAscending: false,
      reportTypeId: 0,
      userId: -1,
   })

   const searchModelRef = React.useRef(searchModel);
   const setSearchModel = (data) => {
      _setSearchModel(data);
   };

   const isLoadingRef = React.useRef(isLoading);
   const setIsLoading = data => {
      isLoadingRef.current = data;
      _setIsLoading(data);
   };

   const history = useHistory();

   if (!isAuthenticated) {
      logoutUser();
      history.push("/login");
   }

   useEffect(() => {
      setSearchModel(searchModel => ({ ...searchModel, take: defaultTake }));
      setSearchText('');
      fetchCounties();
      // fetchCustomerSharing()    
      fetchReportTypes();
      /*const handleScroll = e => {
         if (!isLoadingRef.current && (window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight) { 
            var index = searchModelRef.current.index + searchModelRef.current.take;
            setSearchModel(searchModel=> ({...searchModel,take:defaultTake, index:index}));
         }
      }*/

      /*document.addEventListener("scroll", handleScroll)
      return () => document.removeEventListener("scroll", handleScroll)*/

   }, []);

   useEffect(() => {
      searchModelRef.current = searchModel;
      // First to skip the initialising phase. 
      // because of security reasons we have to check user role before fetching
      if (isMount) {
         setIsMount(false);
         return;
      }
      // Fetch data based on the changes in the search model
      fetchData();
      fetchReportTypes();
   }, [searchModel])


   const fetchData = async () => {
      setIsLoading(true);
      ProjectsApi.getProjects(searchModel)
         .then(
            (response) => {
               if (searchModel.index > 0) {
                  setProjects([...projects, ...response.data.projects]);
               }
               else {
                  setProjects(response.data.projects);
                  setSharedUsers(response.data.sharedUsers);
                  setUsersFilter(response.data.usersFilter);
                  setLoggedUserId(response.data.loggedUserId);
               }
               setIsLoading(false);
            },
            (error) => {
               console.log(error);
               setIsLoading(false);
            });
   }
   
   const fetchCounties = async () => {
      CountyApi.getCounties()
         .then(
            (response) => {
               setCounties(response.data);
            },
            (error) => {
               console.log(error);
            });
   }

   const fetchReportTypes = async () => {
      ProjectsApi.getReportTypes(searchModel.userId)
         .then(
            (response) => {
               setReportTypes(response.data);
            },
            (error) => {
               console.log(error);
            });
   }

   const handleProjectSearch = function (value, id, name) {
      var searchstring = value.toLowerCase();
      setSearchText(searchstring);
      doProjectSearch(searchstring)
   }

   const doProjectSearch = function (searchstring) {
      if (searchstring.length > 2) {
         setSearchModel({ ...searchModel, searchText: searchstring, index: 0 });
      }
      else {
         setSearchModel({ ...searchModel, searchText: "", index: 0 });
      }
   }


   const handleSort = (field) => {
      var direction = ascending;
      if (field == sortField) {
         direction = !direction;
      }
      else {
         direction = false;
      }
      setSearchModel({ ...searchModel, sortAscending: direction, sortField: field, index: 0 });

      setAscending(direction);
      setSortField(field);
   }



   const handleProjectDelete = function (id) {
      ProjectApi.deleteProject(id)
         .then(
            (response) => {
               setProjects(projects.filter(p => p.projectId !== id));
            },
            (error) => {
               console.log(error);
            });
   }


   const handleProjectCopy = function (id) {
      var data = {
         projectId: id,
         calculationId: id
      }
      setIsLoading(true);
      ProjectApi.copyProject(data)
         .then(
            (response) => {
               //setProjects(projects.filter(p =>p.projectId !== id));
               setIsLoading(false);
               fetchData();
            },
            (error) => {
               console.log(error);
               setIsLoading(false);
            });
   }

   const handleClickOpenNew = () => {
      // reset project data.
      setAddressData({
         ...addressData, projectId: 0, address: '',
         zipCode: '',
         city: '',
         gnr: '',
         bnr: '',
         fnr: '',
         snr: '',
         lnr: '',
         andelsnr: '',
         countyId: 0,
      });

      setOpenNew(true);
   };

   const handleCloseNew = (address, userId) => {
      var data = {
         userId: userId,
         address: address
      };
      if (address != null) {
         ProjectApi.addProject(data)
            .then(
               (response) => {
                  fetchData();
                  //project.id = response.data;
                  //setProjects(dataProjects => [...dataProjects, project]);
               },
               (error) => {
                  console.log(error);
               }
            );
      }
      setOpenNew(false);
   };


   const reportTypeSelected = (reportTypeId) => {
      setSelectedReportType(reportTypeId);
      setSearchModel({ ...searchModel, reportTypeId: reportTypeId, index: 0 });
   }

   const ownerFilterSelected = (userId) => {
      setSelectedReportType(0);
      setSearchModel({ ...searchModel, userId: userId, reportTypeId: 0, index: 0 });
   }

   let projectRows = null;
   if (projects.length > 0) {
      projectRows = projects.map((project, index) =>
         <ProjectRow loginName={userName} project={project} index={index} onCopyCb={handleProjectCopy} onDeleteCb={handleProjectDelete}></ProjectRow>
      );
   }

   const handleScroll = function (e) {
      const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 2;
      if (bottom && !isLoading) {
         var index = searchModelRef.current.index + searchModelRef.current.take;
         setSearchModel(searchModel => ({ ...searchModel, take: defaultTake, index: index }));

      }

   }


   const showOwner = function () {
      if (projects.filter((p) => p.userName != userName).length != 0) {
         return 'Bygningssakkyndig'
      }
   }

   return (
      <div className="main-container">

         <div className="title-row">

            <div className='title-btn-group'>
               <div className="bk-text-blue-big">Prosjektoversikt</div>
               <div className='flex-1-5'><BkButton caption='Legg til prosjekt' onClickHandler={handleClickOpenNew} /></div>
            </div>

            <div className='filter-search-group module-row'>
               <div className='filter'>
                  {sharedUsers != null && sharedUsers != undefined && sharedUsers.length != 0 ?
                     <SelectInput selectedValue={searchModel.userId} headerStyle valueKey='userId' nameKey='name' options={usersFilter} onValueChangeCb={ownerFilterSelected} /> : ''}
                     <SelectInput selectedValue={searchModel.reportTypeId} headerStyle valueKey='projectTypeId' defaultOption='Alle tjenester' nameKey='name' options={reportTypes} onValueChangeCb={reportTypeSelected} />
               </div>
               <TextLineInput class='search-bar' placeholder="Søk" name="ProsjektSøk" value={searchText} onValueChangeCb={handleProjectSearch} />
            </div>

         </div>

         <div>
            <div className="main-table-header-row module-row" id='module-row-no-margin'>
               <div className="flex-1"></div>
               <div className="flex-05">Status</div>
               <div className="flex-1 align-center search-cursor" onClick={() => handleSort("CreatedDate")}>Opprettet <SearchIndicator visible={sortField == "CreatedDate"} ascending={ascending}></SearchIndicator></div>
               <div className="flex-1 align-center search-cursor" onClick={() => handleSort("ModifiedDate")}>Endret <SearchIndicator visible={sortField == "ModifiedDate"} ascending={ascending}></SearchIndicator></div>
               <div className="flex-3 search-cursor" onClick={() => handleSort("Address")}>Adresse <SearchIndicator visible={sortField == "Address"} ascending={ascending}></SearchIndicator></div>
               <div className="flex-05">Gnr</div>
               <div className="flex-05">Bnr</div>
               <div className="flex-05">Fnr</div>
               <div className="flex-05">Snr</div>
               <div className="flex-05">Lnr</div>
               <div className="flex-1 text-trim">Aksje / Anr</div>
               <div className="flex-2">{showOwner()}</div>
               <div className="flex-01"></div>
               <div className="flex-05"></div>
            </div>
            <div className="main-table-data-container" onScroll={handleScroll}>
               {projectRows}
            </div>
         </div>

         <EditProjectDialog
            title="Legg til prosjekt"
            open={openNew}
            onClose={handleCloseNew}
            address={addressData}
            userId={0}
            counties={counties}
            sharedUsers={sharedUsers}
         />

         <Spinner isLoading={isLoading} />
      </div>
   )
}

const mapStateToProps = (state: IApplicationState) => ({
   isAuthenticated: state.auth.isAuthenticated,
   role: state.auth.role,
   userName: state.auth.userName,
});

export default connect(mapStateToProps, actionCreators)(Projects);
