import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'

function SearchIndicator(props) {

      if (props.visible) {
         if (props.ascending) {
            return (
               <div className="search-indicator-box"><FontAwesomeIcon icon={faCaretUp} className="stroke-current text-2xs" /></div>
            )      
         }
         else {
            return (
               <div className="search-indicator-box"><FontAwesomeIcon icon={faCaretDown} className="stroke-current text-2xs" /></div>
            )      
         }
      }
      // Add a hidden element (that takes up the same amount of space) so that styling is not messed up
      return (<div className="search-indicator-box" style={{display:'none'}}><FontAwesomeIcon icon={faCaretDown} className="stroke-current text-2xs" /></div>)
}
  
export default SearchIndicator;