import React, { useState, useEffect, useRef } from 'react';
import { actionCreators } from '../../store/auth';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { useHistory, useParams } from 'react-router-dom'
import { AdminApi, AuthApi, ReportApi } from '../../api';
import AdminMenu from './child-components/AdminMenu'
import IndexRow from './child-components/IndexRow'
import SubscriberMessageRow from './child-components/SubscriberMessageRow'
import Table from 'react-bootstrap/Table';
import { toast } from 'react-toastify';
import { renderToastifyMsg } from '../../utils';
import { faCheck } from '@fortawesome/free-solid-svg-icons'


type SsbIndexProps = typeof actionCreators &
{
   readonly isAuthenticated: boolean;
   readonly isAdmin: boolean;
};

const SsbIndex: React.FC<SsbIndexProps> = ({
   isAuthenticated,
   isAdmin,
   logoutUser
}) => {

   const history = useHistory();

   const [SsbIndex, setSsbIndex] = useState([]);
   const [lastCheck, setlastCheck] = useState([]);


   useEffect(() => {
      fetchData();
   }, []);

   const fetchData = async () => {
      AdminApi.getSsbIndexes()
         .then(
            (response) => {
               setSsbIndex(response.data.indexes.slice(0).reverse());
               setlastCheck(response.data.lastChecked);
            },
            (error) => {
               console.log(error);
            });
   }


   if (!isAuthenticated) {
      logoutUser();
      history.push("/login");
   }

   if (!isAdmin) {
      history.push("/projects");
   }

   var indexIdarr = SsbIndex.map(a => a.id)

   var lastindex = indexIdarr[0]
   const toastIdRef = useRef<string | number>('');

   const activateIndex = () => {

      AdminApi.activateSsbIndex(lastindex)
         .then(
            (response) => {
            },
            (error) => {
               console.log(error);
            });

      var currentDate = new Date();
      var dd = String(currentDate.getDate()).padStart(2, '0');
      var mm = String(currentDate.getMonth() + 1).padStart(2, '0');
      var yyyy = currentDate.getFullYear();

      const btn = document.getElementById("activatebtn")
      var date = dd + '-' + mm + '-' + yyyy;

      btn.replaceWith(date);

      if (!toast.isActive(toastIdRef.current))
         toastIdRef.current = toast.success(
            renderToastifyMsg('Index er oppdatert', faCheck)
         );

   }

   let indexRows = null;
   indexRows = SsbIndex.map((ind, index) => {
      return <IndexRow ssbindex={ind} countIndex={index} activateIndex={activateIndex} latestindex={lastindex} />
   });



   return (
      <>
         <div className="admin-container">
            <AdminMenu activeMenuItem={'SSB-Indeks'}></AdminMenu>

            <div className="">

               <div className='module-row'>
                  <div className='flex-025'></div>
                  <div className='flex-3 '>
                     <div className='bk-row-header bk-text-blue-big'>SSB - Index</div>
                     <div className='bk-text-blue'> {lastCheck} </div>
                     <div className='ssb-container'>
                        <div id="module-row-no-margin" className='statistics-header-row module-row'>
                           <div className='flex-1'><span>Periode</span></div>
                           <div className='flex-1'><span>Oppdatert</span></div>
                           <div className='flex-1'><span>Index (%)</span></div>
                           <div className='flex-1'><span>Endring (%)</span></div>
                           <div className='flex-1'><span>Aktivert</span></div>
                        </div>

                        {indexRows}</div>
                  </div>
                  <div className='flex-1'></div>

               </div>
            </div>
         </div>

      </>
   );
}

const mapStateToProps = (state: IApplicationState) => ({
   isAuthenticated: state.auth.isAuthenticated,
   isAdmin: state.auth.isAdmin
});

export default connect(mapStateToProps, actionCreators)(SsbIndex);