import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { actionCreators } from '../../store/auth';
import { PlotApi, ProjectApi } from '../../api';
import { useHistory } from 'react-router-dom'
import { useParams } from "react-router-dom";
import { PlotGroup, PlotSum } from './child-components'
import { ProjectLink, SectionHeader } from '../../components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight, faPenToSquare, faPrint } from '@fortawesome/free-solid-svg-icons'
import { TextLineInput } from '../../components'

type PlotProps = typeof actionCreators &
{
   readonly isAuthenticated: boolean;
   readonly role: number;
   readonly userName: string;
};


const keyPressTimer = 1000;
let descriptionTimer = null;

const Plot: React.FC<PlotProps> = ({
   isAuthenticated,
   userName,
   role
}) => {
   const [project, setProject] = useState({
      address: {
         address: '',
         zipCode: '',
         city: ''
      }
   });
   const [groups, setGroups] = useState([]);
   const [plot, setPlot] = useState({
      projectId: 0,
      calculationId: 0,
      description: '',
      area: 0,
      rawCost: 0,
      totalCost: 0,
   });

   const params = useParams();
   const calculationId = (params as any).id;
   const history = useHistory();

   let user_data = JSON.parse(localStorage.getItem('user')) == null ? [] : JSON.parse(localStorage.getItem('user'));

   if (user_data === null || user_data.length === 0) {
      actionCreators.logoutUser();
      history.push("/login");
   }

   useEffect(() => {
      const fetchData = async () => {
         PlotApi.getPlot(calculationId)
            .then(
               (response) => {
                  setPlot(response.data);
                  setGroups(response.data.groups);
                  getProject(response.data.projectId);
               },
               (error) => {
                  console.log(error);
                  actionCreators.logoutUser();
                  history.push("/login");
               });
      }
      fetchData();
      window.scrollTo(0, 0)
   }, []);


   const getProject = function (projectId) {
      ProjectApi.getProject(projectId)
         .then(
            (response) => {
               setProject(response.data.data);
            },
            (error) => {
               console.log(error);
               actionCreators.logoutUser();
               history.push("/login");
            });
   }


   const handleDescriptionChange = function (value,id,name) {
      setPlot(plot => ({ ...plot, description: value }));
      delayedDescriptionUpdate(plot.calculationId, value);
   }

   const delayedDescriptionUpdate = function (calculationId, description) {
      var data = {
         calculationId: parseInt(calculationId),
         description: description
      };
      PlotApi.updateDescription(data)
         .then(
            (response) => {
            },
            (error) => {
               console.log(error);
            }
         );
   }

   const checkInputValue = function (value) {
      if (value === "")
         return 0;
      let intVal = parseInt(value);
      if (isNaN(intVal) || intVal < 0) {
         //Invalid input
         return NaN;
      }
      return intVal;
   }


   const handlePrintCalculationClick = function () {

      PlotApi.getPdf(calculationId).then(
         (response) => {
         },
         (error) => {
            console.log(error);
         }
      );

   }


   const handlePlotGroupChange = function () {
      // property has changed. Get the updatet price
      PlotApi.getCost(calculationId).then(
         (response) => {
            setPlot(plot => ({ ...plot, rawCost: response.data.rawCost }));
            setPlot(plot => ({ ...plot, totalCost: response.data.totalCost }));
         },
         (error) => {
            console.log(error);
         }
      );
   }

   const handleProjectClick = function () {
      history.push('/project/' + plot.projectId);
   }

   let groupsHtml = null;
   if (groups) {
      groupsHtml = groups.map((group, index) => {
         return (
            <PlotGroup calculationId={calculationId} group={group} onChanged={handlePlotGroupChange} />
         )
      })
   }

   return (
      <div className="main-container">
         <div className="module-row mb-2">

            <div className="bk-text-blue-big flex-4">
               Byggekostnader Tomt
            </div>

            <ProjectLink id={plot.projectId} address={project.address.address} zipCode={project.address.zipCode} city={project.address.city} />

            <div className="flex-015"><FontAwesomeIcon className='printer-icon bk-icon' icon={faPrint} onClick={handlePrintCalculationClick} /></div>
         </div>

         <div className='report-content'>

            <div className="module-row">
               <div className="flex-05">
                  <div className="bk-text-blue">Merknad:</div>
               </div>
               <div className="flex-3">
                  <TextLineInput value={plot.description} onValueChangeCb={handleDescriptionChange} />
               </div>
               <div className="flex-5"></div>
            </div>

            <SectionHeader title={'Sammendrag'}></SectionHeader>
            <div className="content-frame summary-content">
               <PlotSum rawCost={plot.rawCost} totalCost={plot.totalCost} ></PlotSum>
            </div>

            <SectionHeader title={'Byggekostnader Tomt'}></SectionHeader>
            <div className="content-frame">
               {groupsHtml}
            </div>

         </div>
      </div>
   )
}

const mapStateToProps = (state: IApplicationState) => ({
   isAuthenticated: state.auth.isAuthenticated,
   role: state.auth.role,
   userName: state.auth.userName,
});

export default connect(mapStateToProps, actionCreators)(Plot);