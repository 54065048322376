import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCoins, faSquareCheck, faHammer, faEllipsis, faHouse, faClipboardList, faMapLocationDot, faHandshake, faMagnifyingGlassDollar, faFileLines, faEnvelope, faHouseChimneyCrack, faMoneyBill, faHatCowboy, faCommentDollar, faCalculator, faMapMarked, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons'
import { Menu } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
//import AlertDialog from '../../../components/Dialogs';

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ProjectApi } from '../../../api';
import { ConfirmationDialog } from '../../../components/Dialogs'
import { Spinner } from '../../../components'
// import Zoom from 'react-medium-image-zoom'
// import 'react-medium-image-zoom/dist/styles.css'
import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";

const CustomProjectRow = ({ project, index }) => {
  const [open, setOpen] = useState(false);
  const [zoom, setZoom] = useState(false);
  const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const history = useHistory();

  const serviceIcons = function (services) {

    var iconList = [
      faCoins, //Kalkulasjon(er)
      faClipboardList, //Arealskjema
      faMapLocationDot, //Festetomt
      faHandshake, //Forhåndstakst
      faHouse, //Tilstandsrapport - NS 3600
      faMagnifyingGlassDollar, //Verdivurdering
      faFileInvoiceDollar, //Verditakst
      faSquareCheck, //Tilstandsrapport - NS 3424
      faFileLines,  //Arealrapport
      faEnvelope,  //Brevtakst
      faHouseChimneyCrack,  //Skaderapport
      faHammer, //Reklamasjonsrapport
      faMoneyBill, //Næringstakst
      faHatCowboy, //Landbrukstakst
      faMoneyBill, //Reklamasjonsrapport - Enkel
      faMoneyBill, //Reklamasjonstakst
      faCommentDollar, //Tilbudsbrev
      faCalculator, //Detaljert Kalkulasjon
      faMapMarked, //Tomtetakst
      faFileLines] //Arealrapport - Enkel

    if (services.length > 0) {
      if (services.length > 5) {

        let iconServicesList = []
        let extraServicesList = []

        iconServicesList = services.filter((_, i) => i <= 4)

        services.filter((_, i) => i > 4).map((service, index) =>
          extraServicesList.push(service.name)
        );

        let iconHtml = null
        iconHtml = iconServicesList.map((icon, index) => {
          if (iconList[icon.id] != null) {
            return <FontAwesomeIcon className='mx-1' icon={iconList[icon.id]} title={icon.name} />
          }
        }
        );

        return <>
          {iconHtml}
          <FontAwesomeIcon className='mx-1' icon={faEllipsis} title={extraServicesList.join(", ")} />
        </>
      }

      else {
        return services.map((icon, index) =>
          <FontAwesomeIcon className='mx-1' icon={iconList[icon.id]} title={icon.name} />
        );
      }

    }
  }


  const formatDateTime = function (date) {
    if (date === undefined || date === null) {
      // Could be for a new record
      date = new Date();
    }
    let thedate = new Date(date);
    var options = { year: 'numeric', month: '2-digit', day: '2-digit' } as const;
    return thedate.toLocaleDateString('nb-NO', options);
  }

  const formatAddress = function (project) {
    return project.address + ', ' + project.zipCode + ' ' + project.city;
  }


  const getRowClass = function (index) {
    if (index % 2 === 0) {
      return "main-table-data-row module-row white-row"
    }
    else {
      return "main-table-data-row module-row grey-row"
    }
  }

  const id = project.projectId;

  const handleEditProject = event => {
    event.preventDefault();
    const url = "/project/" + id;
    history.push(url);
  }

  return (
    <>
      <div className={getRowClass(index)}>
        <div className="flex-1" onClick={handleEditProject}>{formatDateTime(project.created)}</div>
        <div className="flex-1" onClick={handleEditProject}>{formatDateTime(project.updated)}</div>
        <div className="flex-3 text-trim" onClick={handleEditProject}>{formatAddress(project)}</div>
      </div>


      {/* <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Tjenesten kommer snart
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            OK
          </Button>
          {/* <Button onClick={handleClose} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog> */}

      {/* <ConfirmationDialog
        title="Slette?"
        text="Er du sikker på at du vil slette prosjektet"
        open={displayConfirmDialog}
        id={id}
        onClose={handleConfirmDialogClose}
      /> */}

      <Spinner
        isLoading={isLoading}
      />
    </>
  )
}

CustomProjectRow.displayName = 'CustomProjectRow';

export default React.memo(CustomProjectRow);