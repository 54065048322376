import React, { useState, useEffect, useRef } from 'react'

type SelectInputProps = {
  readonly options: any[];
  readonly valueKey: string;
  readonly nameKey: string;
  readonly defaultOption?: string;
  readonly headerStyle?: boolean
  readonly name?: string
  readonly id?: number
  readonly selectedValue: number;
  readonly onValueChangeCb?: (value: number, id: number, name?: string) => void;
};

function SelectInput(props: SelectInputProps) {

  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(props.options);
  }, [props]);

  const onChanged = function (e) {

    if (!e) {
      return;
    }

    let { value } = e.target;
    value = parseInt(value);

    if (value >= 0) {
      props.onValueChangeCb(value, (props.id != null && props.id),(props.name != '' ? props.name : ''));
    }

  }

  var optionsHtml = null;
  optionsHtml = options.map((option) => {
    return <option key={option[props.valueKey]} value={option[props.valueKey]}>{option[props.nameKey]}</option>;
  });

  return (
    <select value={props.selectedValue} className={'bk-input-field' + ' ' + (props.headerStyle ? 'bk-input-field-select-big' : 'bk-input-field-select') } onChange={(e) => onChanged(e)}>
      {props.defaultOption && <option key={0} value={0}>{props.defaultOption}</option>}
      {optionsHtml}
    </select>
  );
}

export default SelectInput;