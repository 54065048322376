import React, {useState, useEffect} from 'react';
import { actionCreators} from '../../store/auth';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { useHistory, useParams } from 'react-router-dom'
import {AdminApi, AuthApi, ReportApi} from '../../api';
import AdminMenu from './child-components/AdminMenu'
import Table from 'react-bootstrap/Table';


type TextProps = typeof actionCreators &
{
  readonly isAuthenticated: boolean;
  readonly isAdmin: boolean;
};

const Text: React.FC<TextProps> = ({ 
   isAuthenticated, 
   isAdmin,
   logoutUser
 }) => {

   const history = useHistory();
   
   const [systemMessages, setSystemMessages] = useState([]); 
   const [archivedMessages, setArchivedMessages] = useState([]); 
   const [subscriptionMessages, setSubscriptionMessages] = useState([]); 
   const [subscriberMessages, setSubscriberMessages] = useState([]); 
   const [showArchived, setShowArchived] = useState(false);  

   const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);  
   const [idToArchive, setIdToArchive] = useState(0);


   useEffect(() => {
      fetchData();
   }, []);

   const fetchData = async () => {
      AdminApi.getMessages()
      .then(
      (response) => {    
         setSystemMessages(response.data.messages);
         setArchivedMessages(response.data.archived);
         setSubscriptionMessages(response.data.subscriptionMessages);
         setSubscriberMessages(response.data.newSubscribers);
      },
      (error) => {
         console.log(error);
      });
   }


   if(!isAuthenticated) {
      logoutUser();
      history.push("/login");
   }

   if(!isAdmin) {
      history.push("/projects");
   }
    
   return (
      <>
           <div className="admin-container">
               <AdminMenu activeMenuItem={'SSB-Indeks'}></AdminMenu>

               <div className="container">
                <div className='row'> 
                    <div className='bk-row-header bk-text-blue-big mb-4 mx-4'>SSB - Index</div>
                </div>
                   
                <div className='row'>
                    <div className='col-11 col-centered'> 
                    <Table striped bordered className=''>
                       <thead>
                           <tr>
                               <th>Periode</th>
                               <th>Oppdatert</th>
                               <th>Index (%)</th>
                               <th>Endring (%)</th>
                               <th>Aktivert</th>
                           </tr>
                       </thead>
                       <tbody>
                           <tr>
                               
                               <td>etr</td>
                               <td>Otto</td>
                               <td>@mdo</td>
                               <td>@mdo</td>
                               <td>@mdo</td>
                           </tr>
                           <tr>
                               
                               <td>ew</td>
                               <td>Thornton</td>
                               <td>@fat</td>
                               <td>@fat</td>
                               <td>@fat</td>
                           </tr>
                           <tr>
                               
                               <td>ffe</td>
                               <td>@twitter</td>
                               <td>@twitter</td>
                               <td>@twitter</td>
                               <td>@twitter</td>
                           </tr>
                           <tr>
                               
                               <td>hdjjd</td>
                               <td>@twitter</td>
                               <td>@twitter</td>
                               <td>@twitter</td>
                               <td>@twitter</td>
                           </tr>
                       </tbody>
                   </Table>
                    </div>
                
                </div>
                
               </div>
           </div>

      </>
   );
}

const mapStateToProps = (state: IApplicationState) => ({
   isAuthenticated: state.auth.isAuthenticated,
   isAdmin: state.auth.isAdmin
 });
 
 export default connect(mapStateToProps, actionCreators)(Text);