import React from 'react';



const ForgotConfirmation: React.FC = () => (
  <div className="container">
    <div className="module-row">
      <div className='flex-1'></div>
      <div className="flex-2 dialog-container shadow">
        <div className='bk-text-blue bold'>
          E-post sendt
        </div>
        <div className='bk-text-blue mt-2'>
          Dersom du er registrert hos oss vil du motta en email om kort tid

          <div className="register-subtext mt-3 mb-4">
            Om du ikke har mottatt e-posten i løpet av  noen
            <div className='bold'>få minutter vennligst sjekk "Søppelpost" mappe</div>
             eller ta kontakt med oss for hjelp..
          </div>
        </div>
      </div>
      <div className='flex-1'></div>
    </div>
  </div>
)

export default ForgotConfirmation;