import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { BkButton, ContactButton } from '../../../components'
import { formatDateTime } from '../../../utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQrcode, faShareFromSquare, faFile } from '@fortawesome/free-solid-svg-icons'

const PrintReportDialog = ({ open, latestReleaseDate, handleSelectedCb }) => {

   const handleClose = () => {
      handleSelectedCb(0);
   }

   const handlePrintDraft = () => {
      handleSelectedCb(1);
   }

   const handlePublish = () => {
      handleSelectedCb(2);
   }

   const handlePrintGenerated = () => {
      handleSelectedCb(3);
   }

   ////<BkButton caption={'Skriv ut generert rapport (' + formatDateTime(latestReleaseDate) + ')'} onClickHandler={handlePrintGenerated}/>

   function PrintButtonArrangementCheck() {
      if (latestReleaseDate != null) {
         return (
            <>
               <div className='flex-025'></div>
               <div className='flex-4'>
                  <div className="report-dialog-button report-dialog-text " onClick={handlePrintDraft}>
                     <div><FontAwesomeIcon icon={faFile} className="fa-4x" /></div>
                     <span className=''>Skriv ut utkast  <br /> (uten QR kode)</span> </div>
               </div>

               <div className='flex-4'>
                  <div className="report-dialog-button report-dialog-text" onClick={handlePublish}>
                     <div><img src="\Content\images\byggekost-qr.png" alt="QR-kode" width="65rem" className='report-dialog-QR-img' /></div>
                     <span className=''>Skriv ut rapport <br /> (med QR kode)</span> </div>
               </div>

               <div className='flex-4'>
                  <div className="report-dialog-button report-dialog-text" onClick={handlePrintGenerated}>
                     <div><FontAwesomeIcon icon={faShareFromSquare} className="fa-4x" /></div>
                     <span className=''>Skriv ut generert rapport {'(' + formatDateTime(latestReleaseDate) + ')'}</span> </div>
               </div>
               <div className='flex-025'></div>
            </>

         )
      }
      else {
         return (
            <>
               <div className='flex-025'></div>
               <div className='flex-1'>
                  <div className="report-dialog-button report-dialog-text " onClick={handlePrintDraft}>
                     <div><FontAwesomeIcon icon={faFile} className="fa-4x" /></div>
                     <span className=''>Skriv ut utkast <div>(uten QR kode)</div></span>
                  </div>
               </div>

               <div className='flex-1'>
                  <div className="report-dialog-button report-dialog-text" onClick={handlePublish}>
                     <div><img src="\Content\images\byggekost-qr.png" alt="QR-kode" width="65rem" className='' /></div>
                     <span className=''>Skriv ut rapport <div>(med QR kode)</div></span>
                  </div>
               </div>
               <div className='flex-025'></div>
            </>
         )
      }
   }


   return (
      <>
         <Dialog open={open} maxWidth={'md'} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title" className="dialog-header">Velg utskrift</DialogTitle>
            <DialogContent>
               <div className='module-row'>
                  {PrintButtonArrangementCheck()}
               </div>
            </DialogContent>
            <DialogActions>
               <ContactButton caption='Avbryt' onClickHandler={handleClose} />
            </DialogActions>
         </Dialog>

      </>
   )
}

export default React.memo(PrintReportDialog);