import React, { useState } from 'react';
import './SectionHeader.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { count } from 'console';

interface SectionHeaderProps {
   title: string,
   input?: JSX.Element
   menu?: JSX.Element,
   displayShow?: boolean,
   onClickCb?: (show: boolean) => void;
}


export const SectionHeader: React.FC<SectionHeaderProps> = ({ title, input, menu, displayShow, onClickCb }) => {


   if (input !== null && input !== undefined) {
      return <>
         <div className="section-header-module module-row" >
            <div>{title || ''} </div>
            <div className="input-field">{input || ''} </div>
            <div className='flex-6'></div>
            <div className="action-menu">{menu}</div>
         </div>
      </>
   }
   else{
      return <>
      <div className="section-header-module module-row">
         <div>{title || ''} </div>
         <div className='flex-6'></div>
         <div className="action-menu">{menu}</div>
      </div>
      </>
   }

}