import React from 'react'
import parse from 'html-react-parser';

function InstructionText(props) {

   return <>
   {props.text != undefined && props.text != '' && props.text != ' ' ?
      <>
         <div className='bk-instruction-text'>         
            <div>{parse(props.text)}</div>
         </div>
      </>
      :
      <></>
   }
   </>
}
  
export default InstructionText;