import React, { useState, useEffect, useRef } from 'react'

type TextLineInputProps = {
  readonly value: string;
  readonly class?: string;
  readonly id?: number;
  readonly name?: string;
  readonly key?: string;
  readonly readOnly?: boolean;
  readonly disabled?: boolean;
  readonly placeholder?: string;
  readonly noSpaces?: boolean;
  readonly staticDisplay?: boolean;
  readonly centeredInput?: boolean;
  readonly onValueChangeCb?: (value: string, id?: number, name?: string) => void;
};

const keyPressTimer = 2000;
let textTimer = null;

function TextLineInput(props: TextLineInputProps) {
  const [value, setValue] = useState('');
  const [id, setId] = useState(0);
  const [name, setName] = useState('');

  useEffect(() => {
    setValue(props.value);

    if (props.id != null) {
      setId(props.id);
    }

    if (props.name != null && props.name != '') {
      setName(props.name);
    }

  }, [props]);

  const handleChange = (e) => {
    const { value } = e.target;
    setValue(value);

    if (textTimer !== null) {
      clearTimeout(textTimer);
    }
    textTimer = setTimeout(() => {
      if (props.onValueChangeCb != null)
        props.onValueChangeCb(value, id, name);
    }, keyPressTimer);
  }

  const handleBlur = (e) => {
    if (textTimer == null) {
      return;
    }
    clearTimeout(textTimer);
    var { value } = e.target;
    setValue(value);
    if (props.onValueChangeCb != null) {
      props.onValueChangeCb(value, id, name);
    }
  }

  return (
    <input
      type="text"
      key={props.key}
      readOnly={props.readOnly}
      disabled={props.disabled}
      placeholder={props.placeholder}
      className={"bk-input-field " + (props.staticDisplay ? 'bk-input-field-static-display ' : '') + (props.centeredInput ? 'align-center' : '') + props.class}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      name={name}
    />
  );
};

export default TextLineInput;