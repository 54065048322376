import React from 'react';
import ReactTooltip from 'react-tooltip';
import { renderToString } from 'react-dom/server'
import { formatValue } from '../../../utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight, faPenToSquare, faPrint, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'


const PlotSum = ({ rawCost, totalCost }) => {

  const getHelpTextTotal = function (section) {

    switch (section) {
      case 0:
        return (<> <h5><b>Pris for råtomt</b></h5><div className="helptext-list-item"><p>I denne posten fremkommer selve kostnaden for tomta.</p></div></>);
      case 1:
        return (<> <h5><b>Sum opparbeidelse</b></h5><div className="helptext-list-item"><p>I denne posten fremkommer totalsum for opparbeidelse av tomta.</p></div></>);
      case 2:
        return (<> <h5><b>Sum teknisk verdi av tomt</b></h5><div className="helptext-list-item"><p>I denne posten fremkommer totale kostnaden for tomta.</p></div></>);
    }
  }

  return (
    <>

      <div className="module-row">
        <div className="flex-2 ">
          Råtomt
        </div>
        <div className="flex-1  ">{formatValue(rawCost, 0, ' kr')}
        </div>
        <div className="flex-05">
          <FontAwesomeIcon className='bk-icon' icon={faQuestionCircle} data-tip={renderToString(getHelpTextTotal(0))} data-for='id5' />
          {/* <img className="question-image" src="/Content/images/font-awsome/question.png" alt={"help"} data-tip={renderToString(getHelpTextTotal(0))} data-for='id5' /> */}
          <ReactTooltip id='id5' place="right" type="light" html={true} effect="solid" className="helptext-custom" />
        </div>
        <div className="flex-5"></div>
      </div>
      <div className="module-row">
        <div className="flex-2 ">
          Opparbeidelse
        </div>
        <div className="flex-1  ">{formatValue(totalCost - rawCost, 0, ' kr')}
        </div>
        <div className="flex-05">
          <FontAwesomeIcon className='bk-icon' icon={faQuestionCircle} data-tip={renderToString(getHelpTextTotal(1))} data-for='id0' />
          {/* <img className="question-image" src="/Content/images/font-awsome/question.png" alt={"help"} data-tip={renderToString(getHelpTextTotal(1))} data-for='id0' /> */}
          <ReactTooltip id='id0' place="right" type="light" html={true} effect="solid" className="helptext-custom" />
        </div>
        <div className="flex-5"></div>
      </div>
      <div className="module-row">
        <div className="flex-2 ">
          Sum teknisk verdi av tomt
        </div>
        <div className="flex-1  ">{formatValue(totalCost, 0, ' kr')}
        </div>
        <div className="flex-05">
          <FontAwesomeIcon className='bk-icon' icon={faQuestionCircle} data-tip={renderToString(getHelpTextTotal(2))} data-for='id3' />
          {/* <img className="question-image" src="/Content/images/font-awsome/question.png" alt={"help"} data-tip={renderToString(getHelpTextTotal(2))} data-for='id3' /> */}
          <ReactTooltip id='id3' place="right" type="light" html={true} effect="solid" className="helptext-custom" />
        </div>
        <div className="flex-5"></div>
      </div>
    </>
  )
}


export default React.memo(PlotSum);