import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'
import { formatValue, formatDateTime } from '../../../utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { Menu } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import { CalculationApi, PlotApi } from '../../../api';
import { ConfirmationDialog } from '../../../components/Dialogs'

const PropertyRow = ({ property, index, startIndex, onDeleteCb, onCopyCb }) => {
  const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
  const history = useHistory();


  const getRowClass = function (index) {
    if ((index + startIndex) % 2 === 0) {
      return "white-row "
    }
    else {
      return "grey-row "
    }
  }

  const formatDescription = function (description) {
    if (description.length < 17) {
      return description;
    } else {
      return description.substring(0, 15) + '...';
    }
  }

  const id = property.calculationId;
  const handleEditCalculation = event => {
    event.preventDefault();
    if (property.calculationTypeId === 8) {
      const url = "/plot/" + id;
      history.push(url);
    }
    else {
      const url = "/calculation/" + id;
      history.push(url);
    }
  }

  const handlePrintCalculation = event => {
    event.preventDefault();
    if (property.calculationTypeId === 8) {
      PlotApi.getPdf(id).then(
        (response) => {
        },
        (error) => {
          console.log(error);
        }
      );
    }
    else {
      CalculationApi.getPdf(id).then(
        (response) => {
        },
        (error) => {
          console.log(error);
        }
      );
    }


  }

  const handleCopyCalculation = event => {
    if (id > 0) {
      onCopyCb(id);
    }
  }

  const handleDeleteCalculation = event => {
    setDisplayConfirmDialog(true);
  }

  const handleConfirmDialogClose = function (id) {
    if (id > 0) {
      onDeleteCb(id);
    }
    setDisplayConfirmDialog(false);
  }




  function ActionMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return <div aria-controls="fade-menu" aria-haspopup="true" className='navbar-profile'  >
      <FontAwesomeIcon icon={faBars} onClick={handleClick} />
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        className="navbar-dropdown-menu"
      >
        <MenuItem onClick={handleEditCalculation}>Rediger</MenuItem>
        <MenuItem onClick={handlePrintCalculation}>Skriv ut</MenuItem>
        <MenuItem onClick={handleCopyCalculation}>Kopier</MenuItem>
        <MenuItem onClick={handleDeleteCalculation}>Slett</MenuItem>
      </Menu>
    </div>;

  }


  return (
    <>
      <div className={[getRowClass(index), 'module-row main-table-data-row'].join(' ')} id='module-row-no-margin'>
        <div className='flex-01'></div>
        <div className="flex-1" onClick={handleEditCalculation}>{formatDateTime(property.created)}</div>
        <div className="flex-1" onClick={handleEditCalculation}>{formatDateTime(property.updated)}</div>
        <div className="flex-1 text-trim" onClick={handleEditCalculation}>{property.calculationType}</div>
        <div className="flex-1" title={property.description} onClick={handleEditCalculation}>{formatDescription(property.description)}</div>
        <div className="flex-1" onClick={handleEditCalculation}></div>
        <div className="flex-1" onClick={handleEditCalculation}></div>
        <div className="flex-2" onClick={handleEditCalculation}>{formatValue(property.totalCost, 0, ' kr')}</div>
        <div className="flex-2" onClick={handleEditCalculation}></div>
        <div className="flex-1" onClick={handleEditCalculation}>{formatValue(property.totalCost, 0, ' kr')}</div>
        <div className='flex-05'><div className="bk-icon"><ActionMenu /></div></div>
      </div>

      <ConfirmationDialog
        title="Slette?"
        text="Er du sikker på at du vil slette kalkulasjonen"
        open={displayConfirmDialog}
        id={id}
        onClose={handleConfirmDialogClose}
      />
    </>
  )
}

export default React.memo(PropertyRow);