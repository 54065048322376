import React, { useState, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import { useHistory } from 'react-router-dom'
import { actionCreators } from '../../store/auth';
import { OwnershipApi, ResidentApi, ProjectApi } from '../../api';
import { formatValue, isNullOrEmpty } from '../../utils'
import { ConfirmationDialog } from '../../components/Dialogs'
import { ProjectLink, SectionHeader, CategoryHeader, SelectInput, NumericInput } from '../../components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint, faTimes } from '@fortawesome/free-solid-svg-icons'
import { TextLineInput, ModuleComment, } from '../../components'

type OwnershipProps = typeof actionCreators &
{
   readonly isAuthenticated: boolean;
   readonly role: number;
   readonly userName: string;
};


const keyPressTimer = 1000;
let ownershipTimer = null;
let residentTimer = null;


const Ownership: React.FC<OwnershipProps> = ({ isAuthenticated, userName, role }) => {

   const [ownership, setOwnership] = useState({
      ownershipId: 0,
      projectId: 0,
      calculationTypeId: 1,
      marketValue: 0,
      marketValueMonth: 0,
      value: 0,
      comment: '',
      description: ''
   });
   const [project, setProject] = useState({
      address: {
         address: '',
         zipCode: '',
         city: ''
      }
   });
   const [residents, setResidents] = useState([]);
   const [relevantResident, setRelevantResident] = useState({
      name: '',
      age: 0,
      remainingYears: 0,
      gender: 0,
      factor: 0
   });
   const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
   const [selectedResidentId, setSelectedResidentIdg] = useState(0);



   const history = useHistory();
   const params = useParams()

   const id = (params as any).id;

   var typeOptions = [];
   var genderOptions = [];
   const isFirstRun = useRef(true);

   useEffect(() => {
      const fetchData = async () => {
         OwnershipApi.get(id)
            .then(
               (response) => {
                  setOwnership(response.data);
                  getResidents(response.data);
                  getProject(response.data.projectId);
               },
               (error) => {
                  console.log(error);
                  actionCreators.logoutUser();
                  history.push("/login");
               });
      }
      fetchData();
      window.scrollTo(0, 0);
   }, []);

   useEffect(() => {
      if (isFirstRun.current) {
         isFirstRun.current = false;
         return;
      }


   }, [residents]);

   const getResidents = function (ownership) {
      ResidentApi.getResidents(ownership.ownershipId)
         .then(
            (response) => {
               setResidents(response.data);
               if (response.data.length > 0) {
                  var selected = response.data.find(r => r.residentId == ownership.relevantResidentId);

                  if (selected !== null && selected !== undefined) {
                     setRelevantResident(selected)
                  }
               }
               // Add a blank row
               addBlankResidentRow();
            },
            (error) => {
               console.log(error);
               actionCreators.logoutUser();
               history.push("/login");
            });
   }

   const getProject = function (projectId) {
      ProjectApi.getProject(projectId)
         .then(
            (response) => {
               setProject(response.data.data);
            },
            (error) => {
               console.log(error);
               actionCreators.logoutUser();
               history.push("/login");
            });
   }

   const handleResidentTextChange = function (value, id, name) {

      var newResidents = residents.map(res =>
         res.residentId === id
            ? { ...res, [name]: value }
            : res
      );

      setResidents(newResidents);
      var changedResident = newResidents.find(r => r.residentId == id);
      if (residentTimer !== null) {
         clearTimeout(residentTimer);
      }
      residentTimer = setTimeout(() => {
         updateResident(changedResident);;
      }, keyPressTimer);
   }

   const updateResident = function (resident) {

      if (resident.residentId > 0) {
         ResidentApi.updateResident(resident)
            .then(
               (response) => {
                  var newResidents = residents.map(res =>
                     res.residentId === resident.residentId
                        ? response.data
                        : res
                  );
                  setResidents(newResidents);

                  // Get the new relevant
                  getRelevantResident(newResidents);
                  // Recalculate
                  getValue();

                  checkIfBlankResidentShouldBeAdded();
               },
               (error) => {
                  console.log(error);
               })
      }
      else {
         ResidentApi.addResident(resident)
            .then(
               (response) => {
                  // Update the Id to the table
                  var newResidents = residents.map(res =>
                     res.residentId === 0
                        ? response.data
                        : res
                  );
                  setResidents(newResidents);
                  // recalculate
                  checkIfBlankResidentShouldBeAdded();
               },
               (error) => {
                  console.log(error);
               })
      }
   }

   const getRelevantResident = function (newResidents) {
      OwnershipApi.getRelevantId(id)
         .then(
            (response) => {
               var selected = newResidents.find(r => r.residentId == response.data);
               if (selected !== null && selected !== undefined) {
                  setRelevantResident(selected)
               }
            },
            (error) => {
               console.log(error);
            }
         )
   }

   const getValue = function () {
      OwnershipApi.getValue(id)
         .then(
            (response) => {
               // response data contains the value
               setOwnership(ownership => ({ ...ownership, value: parseInt(response.data) }));
            },
            (error) => {
               console.log(error);
            }
         )
   }


   const handleResidenSelectChange = function (value, residentId, name) {

      var intVal = parseInt(value);

      var newResidents = residents.map(res =>
         res.residentId === residentId
            ? { ...res, [name]: intVal }
            : res
      );
      setResidents(newResidents);
      var changedResident = newResidents.find(r => r.residentId == residentId);
      updateResident(changedResident);
   }


   const handleConfirmDialogClose = function (id) {
      if (id > 0) {
         ResidentApi.deleteResident(id)
            .then(
               (response) => {
                  // Update the Id to the table
                  setResidents(residents.filter(resident => resident.residentId !== id));

                  // Get the new relevant
                  getRelevantResident(residents);
                  // Recalculate
                  getValue();

                  // recalculate
                  checkIfBlankResidentShouldBeAdded();
               },
               (error) => {
                  console.log(error);
               })
      }
      setDisplayConfirmDialog(false);
   }



   const handleDeleteResident = function (residentId) {
      setSelectedResidentIdg(residentId);
      setDisplayConfirmDialog(true);
   }

   const checkIfBlankResidentShouldBeAdded = function () {
      var addBlank = false;
      if (residents.length > 0) {
         var lastRow = residents[residents.length - 1];
         if (!isNullOrEmpty(lastRow.name) || !(lastRow.year == 0) || !(lastRow.month == 0) || !(lastRow.day == 0) || !(lastRow.gender == -1)) {
            addBlank = true;
         }

      }
      else {
         addBlank = true;
      }

      if (addBlank) {
         addBlankResidentRow();
      }
   }

   const addBlankResidentRow = function () {
      var blankRow = {
         residentId: 0,
         ownershipId: parseInt(id),
         name: '',
         year: 0,
         month: 0,
         day: 0,
         age: 0,
         remainingYears: 0,
         gender: -1,
         factor: 0
      }
      setResidents(residents => [...residents, blankRow])
   }


   const handleOwnershipTextChange = function (value, id, name) {

      var changed = ownership;
      if (name == 'marketValue') {
         if (isNaN(value)) {
            return;
         }
         changed.marketValue = parseInt(value);
         setOwnership(ownership => ({ ...ownership, [name]: parseInt(value) }));
      }
      if (name == 'marketValueMonth') {
         if (isNaN(value)) {
            return;
         }
         changed.marketValueMonth = parseInt(value);
         setOwnership(ownership => ({ ...ownership, [name]: parseInt(value) }));
      }
      if (name == 'comment') {
         changed.comment = value;
         setOwnership(ownership => ({ ...ownership, [name]: value }));
      }

      if (name == 'description') {
         changed.description = value;
         setOwnership(ownership => ({ ...ownership, [name]: value }));
      }

      if (ownershipTimer !== null) {
         clearTimeout(ownershipTimer);
      }
      ownershipTimer = setTimeout(() => {
         updateOwnership(changed);;
      }, keyPressTimer);
   }

   const handleOwnershipChange = function (value, id, name) {

      var changed = ownership;
      if (name == 'calculationTypeId') {
         changed.calculationTypeId = parseInt(value);
      }

      setOwnership(ownership => ({ ...ownership, [name]: parseInt(value) }));
      updateOwnership(changed);

   }

   const updateOwnership = function (data) {
      OwnershipApi.updateOwnership(data)
         .then(
            (response) => {
               // response data contains the value
               setOwnership(ownership => ({ ...ownership, value: response.data }));
            },
            (error) => {
               console.log(error);
            }
         )
   }

   const handlePrint = function () {
      OwnershipApi.getPdf(id).then(
         (response) => {
         },
         (error) => {
            console.log(error);
         }
      );
   }

   const handleOwnershipCommentChange = function(e) {
      const { name, value } = e.target;

      var changed = ownership;
      
      if (name == 'comment') {
         changed.comment = value;
         setOwnership(ownership =>({...ownership, [name]:value}));
      }

      if (ownershipTimer !== null){
         clearTimeout(ownershipTimer);
       }
       ownershipTimer = setTimeout(() => {
         updateOwnership(changed);
       }, keyPressTimer);  
   }

   const getGender = function (gender) {
      if (gender === 0) {
         return "Mann";
      }
      else if (gender == 1) {
         return "Kvinne";
      }
   }

   const getSumText = function () {
      if (ownership.calculationTypeId == 1) {
         return "Verdi av borett utgjør kr.:"
      }
      else {
         return "Verdi av bruksrett utgjør kr.:"
      }
   }

   var typeOptions = [];
   typeOptions.push({ value: 1, type: 'Helårsbolig' });
   typeOptions.push({ value: 4, type: 'Fritidsbolig' });

   var genderOptions = [];
   genderOptions.push({ value: 1, gender: 'Kvinne' });
   genderOptions.push({ value: 2, gender: 'Mann' });

   var dayOptions = [];
   for (var i = 1; i <= 31; i++) {
      var dayData = {
         day: i,
         value: i
      }
      dayOptions.push(dayData);
   }

   var yearOptions = [];
   var currentYear = new Date().getFullYear();
   for (var i = 1900; i <= currentYear; i++) {
      var data = {
         year: i,
         value: i
      }
      yearOptions.push(data);
   }

   var monthOptions = [];
   monthOptions.push({ value: 1, month: 'Januar' });
   monthOptions.push({ value: 2, month: 'Februar' });
   monthOptions.push({ value: 3, month: 'Mars' });
   monthOptions.push({ value: 4, month: 'April' });
   monthOptions.push({ value: 5, month: 'Mai' });
   monthOptions.push({ value: 6, month: 'Juni' });
   monthOptions.push({ value: 7, month: 'Juli' });
   monthOptions.push({ value: 8, month: 'August' });
   monthOptions.push({ value: 9, month: 'September' });
   monthOptions.push({ value: 10, month: 'Oktober' });
   monthOptions.push({ value: 11, month: 'November' });
   monthOptions.push({ value: 12, month: 'Desember' });

   var residentsHtml = null;
   if (residents) {
      residentsHtml = residents.map((resident, index) => {
         return (
            <>
               <div key={'otr' + index} className="module-row">
                  <div className="flex-2">
                     <TextLineInput id={resident.residentId} name="name" value={resident.name} onValueChangeCb={handleResidentTextChange} />
                  </div>
                  <div className="flex-1">
                     <SelectInput name="year" id={resident.residentId} selectedValue={resident.year} valueKey='value' nameKey='year' defaultOption={'Velg år'}
                        options={yearOptions} onValueChangeCb={handleResidenSelectChange} />
                  </div>
                  <div className="flex-1">
                     <SelectInput name="month" id={resident.residentId} selectedValue={resident.month} valueKey='value' nameKey='month' defaultOption={'Velg måned'}
                        options={monthOptions} onValueChangeCb={handleResidenSelectChange} />
                  </div>
                  <div className="flex-1">
                     <SelectInput name="day" id={resident.residentId} selectedValue={resident.day} valueKey='value' nameKey='day' defaultOption={'Velg dag'}
                        options={dayOptions} onValueChangeCb={handleResidenSelectChange} />
                  </div>
                  <div className="flex-1">
                     <SelectInput name="gender" id={resident.residentId} selectedValue={resident.gender} valueKey='value' nameKey='gender' defaultOption={'Velg kjønn'}
                        options={genderOptions} onValueChangeCb={handleResidenSelectChange} />
                  </div>
                  <div className='bk-icon'>
                     <FontAwesomeIcon className={[(resident.residentId == 0) ? 'visibility-hidden' : '', 'delete-icon '].join()} title="Slett rettighetshaver" icon={faTimes} onClick={() => handleDeleteResident(resident.residentId)} />
                  </div>
               </div>


            </>
         )
      })
   }


   return (
      <>
         <div className="main-container">
            <div className="module-row mb-2">
               <div className="bk-text-blue-big flex-4">
                  Bo- og bruksrett
               </div>

               <ProjectLink id={ownership.projectId} address={project.address.address} zipCode={project.address.zipCode} city={project.address.city} />
               <div className="flex-015"><FontAwesomeIcon className='printer-icon bk-icon' icon={faPrint} onClick={handlePrint} /></div>
            </div>
            <div className='report-content'>
               <div className="module-row">
                  <div className="flex-05">
                     <div className="bk-text-blue">Merknad:</div>
                  </div>
                  <div className="flex-3">
                     <TextLineInput value={ownership.description} onValueChangeCb={handleOwnershipTextChange} />
                  </div>
                  <div className="flex-5"></div>
               </div>



               <SectionHeader title={'Rettighetshaver(e)'}></SectionHeader>
               <div className='content-frame'>
                  <div className="module-row">
                     <div className="flex-2 module-row-title">Navn</div>
                     <div className="flex-1 module-row-title align-center">År</div>
                     <div className="flex-1 module-row-title align-center">Måned</div>
                     <div className="flex-1 module-row-title align-center">Dag</div>
                     <div className="flex-1 module-row-title align-center">Kjønn</div>
                     <div className="bk-icon"></div>
                  </div>
                  {residentsHtml}
               </div>

               <SectionHeader title={'Eiendomstype'}></SectionHeader>
               <div className='content-frame'>
                  <div className="module-row">
                     <div className="flex-1">Eiendomstype:</div>
                     <div className="flex-4">
                        <SelectInput name="calculationTypeId" id={ownership.calculationTypeId} selectedValue={ownership.calculationTypeId} valueKey='value' nameKey='type'
                           options={typeOptions} onValueChangeCb={handleOwnershipChange} />
                     </div>
                     <div className="flex-6"></div>
                  </div>
               </div>



               <SectionHeader title={'Markedsvurdering'}></SectionHeader>
               <div className='content-frame'>

                  <div className="module-row">
                     <div className="flex-1 module-row-header">Markedsverdi:</div>
                     <div className="flex-2">
                        <NumericInput name="marketValue" suffix=',-' value={ownership.marketValue} onValueChangeCb={handleOwnershipTextChange} />
                     </div>
                     <div className="flex-1"></div>
                     <div className="flex-1-5 module-row-header">
                        {ownership.calculationTypeId == 1 && <span>Antatt markedsleie pr. mnd:</span>}
                     </div>
                     <div className="flex-2">
                        {ownership.calculationTypeId == 1 && <NumericInput suffix=',-' name="marketValueMonth" value={ownership.marketValueMonth} onValueChangeCb={handleOwnershipTextChange} />}
                     </div>
                  </div>

               </div>

               <SectionHeader title={'Vurdering av bo- og bruksrett'}></SectionHeader>
               <div className='content-frame'>


                  <div className="module-row-header-medium mb-1 mt-2" id='module-header-left'>Vurdering / forklaring</div>
                  <div >
                     Bo- og bruksrett er en rettighet som følger eller hefte ved eiendommen.<br></br>
                     Ved tinglyst bo- og bruksrett betyr det at bestemte personer har rett til å bo og / eller bruke eiendommen.<br></br>
                     Borett/ bruksrett er vanligvis inngått i forbindelse med overdragelse av eiendom til neste generasjon.<br></br>
                     En tinglyst bo- eller bruksrett er en verdireduserende heftelse på eiendommen, da det er uvisst når kjøper kan få full råderett over eiendommen.<br></br>
                     Verdien av bo- og bruksrett beregnes i henhold til arveavgiftslovens § 13. Denne paragrafen / beregningsmetoden er utgått fra arveloven, men metoden benyttes fremdeles for beregning av verdien av rettigheten.
                  </div>

                  <div className={(ownership.calculationTypeId == 1) ? '' : 'display-none'}>
                     <div className="module-row-header-medium mb-1 mt-2" id='module-header-left'>Helårsbolig</div>
                     <div >
                        Rettigheten på en helårsbolig vil ofte betegnes som en borett.<br></br>
                        Det tas utgangspunkt i kapitalverdien basert på årlig verdi og antatt varighet.<br></br>
                        Boretten beregnes ut fra antall år iht. arveloven og årlig forventet leieinntekt på boligen.
                     </div>
                  </div>
                  <div className={(ownership.calculationTypeId == 4) ? '' : 'display-none'}>
                     <div className="module-row-header-medium mb-1 mt-2" id='module-header-left'>Fritidsbolig</div>
                     <div >
                        Rettigheten på en helårsbolig vil ofte betegnes som en bruksrett.<br></br>
                        Merk at skattemyndighetene legger til grunn at årlig bruksperioder for fritidseiendommer utgjør 8 uker. <br></br>
                        Av disse godkjennes kun 4 uker som grunnlag for beregning av bruksretten. <br></br>
                        Dette har sammenheng med at dersom bruksrett forbeholdes i mer enn fire uker, betyr dette at giveren ikke har gitt fra seg rådigheten..<br></br>
                        Bruksretten beregnes ut fra avkastning av eiendommen og varighet.<br></br>
                        4 % avkastning og 4 ukers bruk.
                     </div>
                  </div>

               </div>

               <SectionHeader title={'Sammendrag'}></SectionHeader>
               <div className='content-frame'>


                  <div className="module-row">
                     <div className="flex-1"><b>Rettighershaver som legges til grunn</b></div>
                  </div>
                  <div className="module-row">
                     <div className="flex-1">Navn:</div>
                     <div className="flex-1">{relevantResident.name}</div>
                  </div>
                  <div className="module-row">
                     <div className="flex-1">Alder:</div>
                     <div className="flex-1">{relevantResident.age} år</div>
                  </div>
                  <div className="module-row">
                     <div className="flex-1">Antall år iht. arveloven:</div>
                     <div className="flex-1">{relevantResident.remainingYears} år</div>
                  </div>
                  <div className="module-row">
                     <div className="flex-1">Kjønn:</div>
                     <div className="flex-1">{getGender(relevantResident.gender)}</div>
                  </div>
                  <div className="module-row">
                     <div className="flex-1">Kapitaliseringsfaktor:</div>
                     <div className="flex-1">{relevantResident.factor}</div>
                  </div>
                  <div className="module-row ownership-result-borders">
                     <div className="flex-3">
                        <b>{getSumText()}</b>
                     </div>
                     <div className="flex-1 align-right">
                        {formatValue(ownership.value, 0, ',-')}
                     </div>
                  </div>


               </div>


               <SectionHeader title={'Beregning av markedsverdi'}></SectionHeader>
               <div className='content-frame'>
                  <div className="">
                     <div className="ownership-table">
                        <div className="module-row">
                           <div className="flex-3">
                              Markedsverdi for eiendom uten fradrag for rettighet:
                           </div>
                           <div className="flex-1 align-right">
                              {formatValue(ownership.marketValue, 0, ',-')}
                           </div>
                        </div>
                        <div className="module-row">
                           <div className="flex-3">
                              Fradraget for verdi av rettighet:
                           </div>
                           <div className="flex-1 align-right ">
                              {formatValue(ownership.value, 0, ',-')}
                           </div>
                        </div>
                        <div className="module-row ownership-result-borders">
                           <div className="flex-3">
                              <b>Sum markedsverdi med fradrag for rettighet</b>
                           </div>
                           <div className="flex-1 align-right">
                              {formatValue(ownership.marketValue - ownership.value, 0, ',-')}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               {/* {ownership.ownershipId != undefined && ownership.ownershipId != null && ownership.ownershipId > 0 ?
                  <>
                     <ModuleComment moduleId={ownership.ownershipId} displayFrame={true} multiComment={false} noMargin={true} readOnly={false} header={"bo- og bruksrett"} phraseKey='ownership.comments'></ModuleComment>
                  </> :
                  <>
                  </>
               } */}

               <CategoryHeader title={'Kommentarer til bo- og bruksrett'}></CategoryHeader>
               <div className='content-frame'>
                  <div className="">
                     <div className="ownership-table">
                        <div className="module-row">
                           <div className="flex-1">
                              <textarea className="form-control ownership-control-max" name="comment" rows={3} value={ownership.comment} onChange={(e) => handleOwnershipCommentChange(e)} />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div >
         <ConfirmationDialog
            title="Slette?"
            text="Er du sikker på at du vil slette rettighetshaveren"
            id={selectedResidentId}
            open={displayConfirmDialog}
            onClose={handleConfirmDialogClose}
         />
      </>
   )
}

export default Ownership;