import React, {useRef,useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { isNullOrEmpty } from '../../../utils';
import { ProjectApi } from '../../../api';
import { BkButton } from '../../../components';
import { ContactButton } from '../../../components';
import { toast } from 'react-toastify';
import { renderToastifyMsg } from '../../../utils';

export interface EditProjectDialogProps {
  address;
  userId:number;
  open: boolean;
  onClose: (addressData, userId) => void;
  counties;
  title:string;
  sharedUsers;
}

export default function EditProjectDialog(props: EditProjectDialogProps) {
  const { onClose, address, open, counties, title, sharedUsers, userId} = props;
  const [addressData, setAddressData] = useState({
    projectId:0,
    address: '',
    zipCode: '',
    city: '',
    municipality: '',
    municipalityNumber:'',
    gnr:'',
    bnr: '',
    fnr: '',
    snr: '',
    lnr: '',
    andelsnr: '',
    countyId: 0,
  });
  const [addressError, setAddressError] = useState(false);
  const [zipCodeError, setZipCodeError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [countyError, setCountyError] = useState(false);
  const [selectedCounty, setSelectedCounty] = useState(0);
  const [selectedOwner, setSelectedOwner] = useState(0);
  const [selectedMunicipalityNumber, setSelectedMunicipalityNumber] = useState('');
  const [selectedMunicipality, setSelectedMunicipality] = useState('');

  const toastIdRef = useRef<string | number>('');

  useEffect(() => {
    setAddressData(address);
    if (address.countyId > 0) {
      setSelectedCounty(address.countyId.toString());
    }
    if (address.municipalityNumber > 0) {
      setSelectedMunicipalityNumber(address.municipalityNumber);
    }
    if (userId > 0) {
      setSelectedOwner(userId);
    }
    if (!isNullOrEmpty(address.municipality)) {
    setSelectedMunicipality(address.municipality);
    }
    
  }, [address])

  const handleClose = () => {
    onClose(null, 0);
  };


  const handleSaveAndClose = () => {
    var error = false;
    if (isNullOrEmpty(addressData.address)) {
      setAddressError(true);
      error=true;
    }
    if (isNullOrEmpty(addressData.zipCode)) {
      setZipCodeError(true);
      error=true;
    }
    if (isNullOrEmpty(addressData.city)) {
      setCityError(true);
      error=true;
    }
    if (addressData.countyId <= 0) {
      setCountyError(true);
      error=true;
    }

    if (!error) {
      resetValues()
      onClose(addressData, selectedOwner);
    }
  };

  const resetValues = () => {
    setSelectedCounty(0);
    setSelectedOwner(0);
    setSelectedMunicipalityNumber("");
  }
  const handleCountyChange = function(e) {
    const { name, value } = e.target;
    if (name == "countyId" && !isNullOrEmpty(value)){
      setCountyError(false);
      var id = parseInt(value);
      setAddressData(projectData => ({ ...projectData, countyId: id }));
      setSelectedCounty(id);
    }
    else {
      setAddressData(projectData => ({ ...projectData, countyId: 0 }));
      setSelectedCounty(0);    
    }
  }
  const handleMunicipalityNumberChange = function(e) {
    const { name, value } = e.target;
    if (name == "municipalityNumber" && !isNullOrEmpty(value)){
      setCountyError(false);
      setAddressData(projectData => ({ ...projectData, municipalityNumber: value }));
      setSelectedMunicipalityNumber(value);
    }
    else {
      setSelectedMunicipalityNumber(addressData.municipalityNumber);     
    }
  }

  const handleProjectDataChange = function(e) {
    const { name, value } = e.target;
    setAddressData(projectData => ({ ...projectData, [name]: value }));

    if (name == "address" && !isNullOrEmpty(value)){
      setAddressError(false);
    }
    if (name == "zipCode" && !isNullOrEmpty(value)){
      setZipCodeError(false);

      if (value.length == 4) {
        ProjectApi.getLocationFromZipCode(value).then(
          (response) => {
            var loc = response.data;
            if (!isNullOrEmpty(loc.city)) {
              setAddressData(projectData => ({ ...projectData, city: loc.city, municipality:loc.municipality, county: loc.county, countyId: loc.countyId, municipalityNumber:loc.municipalityNumber }));
              setSelectedCounty(loc.countyId.toString());
              setSelectedMunicipalityNumber(loc.municipalityNumber);
              setSelectedMunicipality(loc.municipality);
            }
            else{
              setZipCodeError(true);
            }
          }
        )
      }
    }
    if (name == "city" && !isNullOrEmpty(value)){
      setCityError(false);
    }

    if (name == "municipality" && !isNullOrEmpty(value)){
      setAddressData(projectData => ({ ...projectData, municipality: value }));
      setSelectedMunicipality(value);
    }
    else {
      setSelectedMunicipality(addressData.municipality);     
    }

  

    // if (name != "zipCode"){
    //   if (projectData.zipCode.length == 4) {
    //     ProjectApi.getLocationFromZipCode(projectData.zipCode).then(
    //       (response) => {
    //         var loc = response.data;
    //         if (!isNullOrEmpty(loc.city)) {
    //           setProjectData(projectData => ({ ...projectData, city: loc.city, municipality:loc.municipality, county: loc.county, countyId: loc.countyId, cityNumber:loc.cityNumber }));
    //           setSelectedCounty(loc.countyId.toString());
    //         }
    //         else{
    //           setZipCodeError(true);
    //         }
    //       }
    //     )
    //   }
    // }
  }

  const handleOwnerChange = function(e) {
    const { name, value } = e.target;
    if (name == "ownerId" && !isNullOrEmpty(value) && value != 0){
      var id = parseInt(value);
      setAddressData(projectData => ({ ...projectData, userId: id }));
      setSelectedOwner(value);
    }
  }

  const useTextFieldStyles = makeStyles(() => ({
    root: {
    '& label.Mui-focused': {
      color: '#7DA868',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#7DA868',
    },
    '& .MuiInput-underline.Mui-error:after': {
      borderBottomColor: '#ff1744',
    },
    '& .MuiInputLabel-root.Mui-error': {
      color: '#ff1744',
    },
    }
  }));

  const textFieldStyles = useTextFieldStyles(false);

  let htmlCounties = null;
  if (counties.length > 0){
    htmlCounties = counties.map((county, index) =>   
      <MenuItem value={county.id.toString()}>{county.name}</MenuItem>
    );
  }
  let htmlShared = null;
  if (sharedUsers != undefined && sharedUsers != null){
    htmlShared = sharedUsers.map((customer, index) =>   
      <MenuItem value={customer.userId.toString()}>{customer.firstName + ' ' + customer.lastName}</MenuItem>
    );
  }

  return (
    <>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className="dialog-header">{title}</DialogTitle>
        <DialogContent>
          <div className="project-dialog-edit-wrapper">
            <div className="project-dialog-edit-frame">
            
              <TextField required error={addressError} className={textFieldStyles.root + ' ' + 'large-field'} autoFocus margin="dense" id="address" fullWidth name="address" label="Adresse" type="text" value={addressData.address} onChange={handleProjectDataChange}/>
              
              <TextField required error={zipCodeError} className={textFieldStyles.root + ' ' + 'small-field'} margin="dense" inputProps={{ maxLength: 4 }} id="zipCode" name="zipCode" label="Postnummer" type="text" value={addressData.zipCode} onChange={handleProjectDataChange}/>
              
              <TextField required error={cityError} className={textFieldStyles.root + ' ' + 'medium-field'} margin="dense" id="city" name="city" label="Sted" type="text" value={addressData.city} onChange={handleProjectDataChange}/>
              
              <TextField className={textFieldStyles.root + ' ' + 'small-field'} margin="dense" id="municipalityNumber" name="municipalityNumber" label="Kommunenr." type="text" value={selectedMunicipalityNumber} onChange={(e)=>handleMunicipalityNumberChange(e)}/>

              <TextField className={textFieldStyles.root + ' ' + 'medium-field'} margin="dense" id="municipality" name="municipality" label="Kommune" type="text" value={selectedMunicipality} onChange={handleProjectDataChange}/>
              
              <FormControl fullWidth className={textFieldStyles.root}>
                <InputLabel className="mt-2" id="county-id-label">Fylke *</InputLabel>
              <Select
                labelId="county-id-label"
                id="countyId"
                name="countyId"
                value={selectedCounty}
                onChange={(e)=>handleCountyChange(e)}
                required
                error={countyError}
                className='county-field'
              >
                {htmlCounties}
              </Select>
              </FormControl>
              
              <div className='project-dialog-field-row'>
              <TextField className={textFieldStyles.root + ' ' + 'small-field'} margin="dense" id="gnr" name="gnr" label="Gnr." type="text" value={addressData.gnr} onChange={(e)=>handleProjectDataChange(e)}/>
              <TextField className={textFieldStyles.root + ' ' + 'small-field'} margin="dense" id="bnr" name="bnr" label="Bnr." type="text" value={addressData.bnr} onChange={(e)=>handleProjectDataChange(e)}/>
              <TextField className={textFieldStyles.root + ' ' + 'small-field'} margin="dense" id="fnr" name="fnr" label="Fnr." type="text" value={addressData.fnr} onChange={(e)=>handleProjectDataChange(e)}/>
              <TextField className={textFieldStyles.root + ' ' + 'small-field'} margin="dense" id="snr" name="snr" label="Snr." type="text" value={addressData.snr} onChange={(e)=>handleProjectDataChange(e)}/>
              <TextField className={textFieldStyles.root + ' ' + 'small-field'} margin="dense" id="lnr" name="lnr" label="Lnr." type="text" value={addressData.lnr} onChange={(e)=>handleProjectDataChange(e)}/>
              <TextField className={textFieldStyles.root + ' ' + 'small-field'} margin="dense" id="anr" name="andelsnr" label="Aksje / Anr." type="text" value={addressData.andelsnr} onChange={(e)=>handleProjectDataChange(e)}/>
              </div>
              
              {sharedUsers != undefined && sharedUsers != null && sharedUsers.length > 0?
              <><FormControl fullWidth className={textFieldStyles.root}>
                <InputLabel id="county-id-label">Sett bygningssakkyndig</InputLabel>
              <Select
                labelId="county-id-label"
                id="ownerId"
                name="ownerId"
                value={selectedOwner}
                onChange={(e)=>handleOwnerChange(e)}
                className='large-field'
              >
                {htmlShared}
              </Select>
              </FormControl>
              </> : ''}
            </div>
          </div>
        </DialogContent>
        <DialogActions>

          <ContactButton caption='Avbryt' onClickHandler={handleClose}/>
          <BkButton caption='Lagre prosjektdata'  onClickHandler={handleSaveAndClose} />
        </DialogActions>
      </Dialog>
    </>
  );
}