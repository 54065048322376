import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import parse from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faArrowLeft, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { BkButton } from '../../components';

const Support: React.FC = () => {

   const openDocument = (id) => {
      const win = window.open("/document/" + id, "_self");
      win.focus();
   }

   const openPdf = (url) => {
      //var url =  '/docs/Veileder - Tilstandsrapport NS 3600.pdf';
      const win = window.open(url, "_blank");
      win.focus();
   }


   return (
      <div className='main-container'>
         <div className='module-row' id='module-row-no-margin'>
            <div className=" bk-text-blue-big flex-5"> Veileder </div>
            <div className='flex-1'>
               <a href="/documents" className='dialog-button'>
                  Tilbake til hovedmeny
               </a></div>
         </div>

         <div className='btn_grid'>


            <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Veileder/Veileder - 1 - Opprett et prosjekt.pdf')}>
               <FontAwesomeIcon icon={faQuestionCircle} className="fa-2x " />
               <div className='dashboard-btn-text '> Opprett et prosjekt </div>
            </div>


            <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Veileder/Veileder - 2 - Skriv inn grunndata.pdf')}>
               <FontAwesomeIcon icon={faQuestionCircle} className="fa-2x " />
               <div className='dashboard-btn-text '> Skriv inn grunndata </div>
            </div>


            <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Veileder/Veileder - 3 - Kontakter.pdf')}>
               <FontAwesomeIcon icon={faQuestionCircle} className="fa-2x " />
               <div className='dashboard-btn-text '> Kontakter </div>
            </div>



            <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Veileder/Veileder - 4 - Min side.pdf')}>
               <FontAwesomeIcon icon={faQuestionCircle} className="fa-2x " />
               <div className='dashboard-btn-text '> Min side </div>
            </div>

            <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Veileder/Veileder - 5 - Legg til tjeneste.pdf')}>
               <FontAwesomeIcon icon={faQuestionCircle} className="fa-2x " />
               <div className='dashboard-btn-text '> Legg til tjeneste </div>
            </div>

            <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Veileder/Veileder - 6 - Kopiere et prosjekt.pdf')}>
               <FontAwesomeIcon icon={faQuestionCircle} className="fa-2x " />
               <div className='dashboard-btn-text '> Kopiere et prosjekt </div>
            </div>



            <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Veileder/Veileder - 7 - Kopiere en tjeneste-rapport.pdf')}>
               <FontAwesomeIcon icon={faQuestionCircle} className="fa-2x " />
               <div className='dashboard-btn-text '> Kopiere en tjeneste-rapport </div>
            </div>

            <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Veileder/Veileder - 8 - Tilstandsrapport - NS 3600.pdf')}>
               <FontAwesomeIcon icon={faQuestionCircle} className="fa-2x " />
               <div className='dashboard-btn-text '> Tilstandsrapport - NS 3600 </div>
            </div>



            <div className="dashboard-btn" onClick={() => openDocument(6)}>
               <FontAwesomeIcon icon={faQuestionCircle} className="fa-2x " />
               <div className='dashboard-btn-text '> Bo- og Bruksrett </div>
            </div>


            <div className="dashboard-btn" onClick={() => openDocument(5)}>
               <FontAwesomeIcon icon={faQuestionCircle} className="fa-2x " />
               <div className='dashboard-btn-text '> Detaljert kalkulasjon </div>
            </div>


            <div className="dashboard-btn" onClick={() => openDocument(0)}>
               <FontAwesomeIcon icon={faQuestionCircle} className="fa-2x " />
               <div className='dashboard-btn-text '> Teknisk beregning av bygning</div>
            </div>
         </div>
      </div>
   )
}

export default Support;