import React from 'react';
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import Button from "@material-ui/core/Button";

const SubscriptionMessageRow = ({message}) => {

   const history = useHistory();


   const handleOpenCustomerCard = () => {
      history.push("/customer/" + message.userId)
   }


   return (
      <>
         <div className ="module-row customer-message">
            <div className="flex-1">
                {message.name}
               </div>
            <div className="flex-1">{message.email}</div>
            <div className="flex-1">{message.remaining}</div>
            <div className="flex-05">
               <div onClick={handleOpenCustomerCard} className="message-button">Kundekort</div>
            </div> 
         </div>

      </>
   )
}

export default React.memo(SubscriptionMessageRow);
