import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { RoutesConfig } from '../../config/routes.config';
import { actionCreators } from '../../store/auth';
import { useHistory } from 'react-router-dom'
import { AuthApi } from '../../api';
import Form from 'react-bootstrap/Form';
import { ContactButton } from '../../components';
import { toast } from 'react-toastify';
import { renderToastifyMsg } from '../../utils';
import { faTrashAlt, faPlus, faBars, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

const Forgot: React.FC = () => {
   const [values, setValues] = React.useState({
      email: ''
   });

   const history = useHistory();

   const toastIdRef = useRef<string | number>('');

   const handleChange = name => event => {
      setValues({ ...values, [name]: event.target.value });
   };

   function isValidEmail(email) {
      return /\S+@\S+\.\S+/.test(email);
   }

   const handleSubmit = () => {
      if (!isValidEmail(values.email)) {
         if (!toast.isActive(toastIdRef.current)) {
            toastIdRef.current = toast.error(
               renderToastifyMsg("Kontroller at epost adressen er gyldig")
            );
         }
         return;
      }
      var dataToSend = {
         email: values.email,
      }

      AuthApi.ForgotPassword(dataToSend)
         .then(
            (response) => {
               history.push(RoutesConfig.ForgotConfirmation.path)
            },
            (error) => {
               console.log("forgot password failed");
               history.push(RoutesConfig.ForgotConfirmation.path)
            });
   }


   return (
      <div className="container">
         <div className="module-row">
            <div className='flex-1'></div>
            <div className="flex-2 dialog-container shadow">
               <Form onSubmit={handleSubmit} className="mt-3">
                  <Form.Text className="bk-text-blue">
                     Skriv inn epost adressen knyttet til bruker
                  </Form.Text>

                  <Form.Control type="text" placeholder="E-post" required className="register-control mt-2 mb-2"
                     onChange={handleChange('email')} />

                  <Form.Text className="bk-text-blue ">
                     Du vil få en e-post med en midlertidig passord
                  </Form.Text>

                  <div className='mt-3 module-row justify-content-end'>
                     <ContactButton caption='Send' onClickHandler={handleSubmit} />
                  </div>
               </Form>
            </div>
            <div className='flex-1'></div>
         </div>
      </div>
   );
};


const mapStateToProps = (state: IApplicationState) => state.auth;

export default connect(mapStateToProps, actionCreators)(Forgot);
