import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { PriceguideApi } from '../../api';
import { formatValue, isNullOrEmpty } from '../../utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { makeStyles } from '@material-ui/core/styles';

const PriceGuide: React.FC = () => {
   const [categories, setCategories] = useState([{
      productCategoryId: 0,
      name: '**ALLE PRODUKTER**'
   }]);
   const [products, setProducts] = useState([]);

   const [selectedCategory, setSelectedCategory] = useState(0);
   const [selectedProduct, setSelectedProduct] = useState(0);
   const [categoryFilter, setCategoryFilter] = useState('');
   const [searchLimited, setSearchLimited] = useState(false);

   useEffect(() => {
      fetchCategories();
      fetchProducts('');
   }, []);

   const fetchCategories = async () => {
      PriceguideApi.getProductCategories()
         .then(
            (response) => {
               setCategories([...categories, ...response.data]);
            },
            (error) => {
               console.log(error);
            });
   }

   const fetchProducts = async (filter) => {
      PriceguideApi.getProductsFromFilter(filter)
         .then(
            (response) => {
               setProducts(response.data);
               if (response.data.length >= 500) {
                  setSearchLimited(true);
               }
               else {
                  setSearchLimited(false);
               }
            },
            (error) => {
               console.log(error);
            });
   }

   const handleCategoryClick = (id) => {
      setSelectedCategory(id);

      if (id == 0) {
         fetchProducts('');
      }
      else {
         PriceguideApi.getProductsFromCategory(id)
            .then(
               (response) => {
                  setProducts(response.data);
                  if (response.data.length >= 500) {
                     setSearchLimited(true);
                  }
                  else {
                     setSearchLimited(false);
                  }
               },
               (error) => {
                  console.log(error);
               });
      }
   }

   const handleProductClick = (id) => {
      setSelectedProduct(id);
   }

   const handleCategorySearch = function (e) {
      setCategoryFilter(e.target.value.toLowerCase());
   }

   const handleProductSearch = function (e) {
      fetchProducts(e.target.value.toLowerCase());
   }

   let htmlCategories = null;
   if (categories) {
      htmlCategories = categories.map((category, index) => {
         if (isNullOrEmpty(categoryFilter) || (category.name.toLowerCase().includes(categoryFilter))) {
            return (
               <div className={category.productCategoryId == selectedCategory ? "selected" : ""} onClick={() => handleCategoryClick(category.productCategoryId)}>{category.name}</div>
            )
         }
      })
   }



   let htmlProducts = null;
   if (products) {
      htmlProducts = products.map((product, index) => {
         return (
            <>
               <div className={product.productId == selectedProduct ? "priceguide-product-row selected" : "priceguide-product-row"} onClick={() => handleProductClick(product.productId)}>
                  <div className="flex-4">
                     <span>{product.name}</span>
                  </div>
                  <div className="flex-4">
                     <span>{product.description}</span>
                  </div>
                  <div className="flex-1">
                     <span>{product.unit}</span>
                  </div>
                  <div className="flex-1 priceguide-product-row-last">
                     <span data-bind="text:$root.formatNumber($data.UnitPrice)">{formatValue(product.unitPrice, 2, '')}</span>
                  </div>
               </div>
            </>
         )
      })
   }

   const useTextFieldStyles = makeStyles(() => ({
      root: {
         '& label.Mui-focused': {
            color: '#7DA868',
         },
         '& .MuiInput-underline:after': {
            borderBottomColor: '#7DA868',
         },
      }
   }));

   const textFieldStyles = useTextFieldStyles(false);



   return (
      <div className="main-container">
         <div className='module-row' id='module-row-no-margin'>
            <div className="bk-text-blue-big flex-5"> Materialliste med priser</div>
            <div className='flex-1'>
               <a href="/documents" className='dialog-button'>
                  Tilbake til hovedmeny 
               </a>
            </div>
         </div>

         <div className="priceguide-wrapper dialog-container">
            <div className="flex-2">
               <div className="bk-text-blue">Kategorier</div>
               <div><TextField className={textFieldStyles.root} id="standard-basic" label="Søk" onChange={handleCategorySearch} /></div>

               <div className="category-area">
                  {htmlCategories}
               </div>
            </div>
            <div className="flex-5">
               <div className="priceguide-list-header">
                  <div className="flex-1">
                     <div className="bk-text-blue">Produkter</div>
                     <div><TextField className={textFieldStyles.root} id="standard-basic" label="Søk" onChange={handleProductSearch} /></div>
                  </div>
                  <div className="flex-1">
                     <div className="bk-text-blue text-align-end">Prisliste fra Optimera</div>
                     <div className="bk-text-blue text-align-end">Veiledende priser eks. mva.</div>
                     <div className={searchLimited ? "priceguide-header-error" : "hidden"}>* Søket er begrenset til første 500 treff</div>
                  </div>
               </div>
               <div className="product-area">
                  {htmlProducts}
               </div>

            </div>

         </div>

      </div>
   )
}

export default PriceGuide;