import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Aos from "aos";
import "aos/dist/aos.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faArrowLeft, faFilePdf, faFile } from '@fortawesome/free-solid-svg-icons'


const Examples: React.FC = () => {

   const openPdf = (url) => {
      const win = window.open(url, "_blank");
      win.focus();
   }

   return (
      <div className='main-container'>
         <div className='module-row' id='module-row-no-margin'>
         <div className="bk-text-blue-big flex-5">Rapport Eksempel</div>
            <div className='flex-1'>
            <a href="/documents" className='dialog-button'>
                  Tilbake til hovedmeny 
               </a></div>
         </div>

         <div className='btn_grid'>

            <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Rapport-eksempler/Arealrapport.pdf')}>
               <FontAwesomeIcon icon={faFile} className="fa-2x  " />
               <div className='dashboard-btn-text  '> Arealrapport </div>
            </div>

            <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Rapport-eksempler/Arealrapport-Enkel.pdf')}>
               <FontAwesomeIcon icon={faFile} className="fa-2x  " />
               <div className='dashboard-btn-text  '> Arealrapport - Enkel </div>
            </div>

            <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Rapport-eksempler/Brevtakst.pdf')}>
               <FontAwesomeIcon icon={faFile} className="fa-2x  " />
               <div className='dashboard-btn-text  '> Brevtakst </div>
            </div>

            <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Rapport-eksempler/Byggelånskontroll.pdf')}>
               <FontAwesomeIcon icon={faFile} className="fa-2x  " />
               <div className='dashboard-btn-text  '> Byggelånskontroll </div>
            </div>

            <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Rapport-eksempler/Forhåndstakst.pdf')}>
               <FontAwesomeIcon icon={faFile} className="fa-2x  " />
               <div className='dashboard-btn-text  '> Forhåndstakst </div>
            </div>

            <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Rapport-eksempler/Landbrukstakst.pdf')}>
               <FontAwesomeIcon icon={faFile} className="fa-2x  " />
               <div className='dashboard-btn-text  '> Landbrukstakst </div>
            </div>
            <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Rapport-eksempler/Næringstakst.pdf')}>
               <FontAwesomeIcon icon={faFile} className="fa-2x  " />
               <div className='dashboard-btn-text  '> Næringstakst </div>
            </div>

            <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Rapport-eksempler/Reklamasjonsrapport.pdf')}>
               <FontAwesomeIcon icon={faFile} className="fa-2x  " />
               <div className='dashboard-btn-text  '> Reklamasjonsrapport </div>
            </div>

            <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Rapport-eksempler/Skaderapport.pdf')}>
               <FontAwesomeIcon icon={faFile} className="fa-2x  " />
               <div className='dashboard-btn-text  '> Skaderapport </div>
            </div>

            <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Rapport-eksempler/Skaderapport_Enkel.pdf')}>
               <FontAwesomeIcon icon={faFile} className="fa-2x  " />
               <div className='dashboard-btn-text  '> Skaderapport - Enkel </div>
            </div>

            <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Rapport-eksempler/Tilbudsbrev.pdf')}>
               <FontAwesomeIcon icon={faFile} className="fa-2x  " />
               <div className='dashboard-btn-text  '> Tilbudsbrev </div>
            </div>

            <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Rapport-eksempler/Tilstandsrapport-NS3424.pdf')}>
               <FontAwesomeIcon icon={faFile} className="fa-2x  " />
               <div className='dashboard-btn-text  '> Tilstandsrapport - NS3424 </div>
            </div>

            <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Rapport-eksempler/Tilstandsrapport-NS3600.pdf')}>
               <FontAwesomeIcon icon={faFile} className="fa-2x  " />
               <div className='dashboard-btn-text  '>Tilstandsrapport - NS3600 </div>
            </div>

            <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Rapport-eksempler/Tomtetakst.pdf')}>
               <FontAwesomeIcon icon={faFile} className="fa-2x  " />
               <div className='dashboard-btn-text  '>Tomtetakst </div>
            </div>

            <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Rapport-eksempler/Verditakst.pdf')}>
               <FontAwesomeIcon icon={faFile} className="fa-2x  " />
               <div className='dashboard-btn-text  '> Verditakst </div>
            </div>

            <div className="dashboard-btn" onClick={() => openPdf('/Content/pdf/Rapport-eksempler/Verdivurdering.pdf')}>
               <FontAwesomeIcon icon={faFile} className="fa-2x  " />
               <div className='dashboard-btn-text  '> Verdivurdering </div>
            </div>

         </div>
      </div>


   );
}
export default Examples;