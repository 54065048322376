import React, {useState} from 'react';
import { useHistory } from 'react-router-dom'

const StatisticsCard = ({stats}) => {

   const history = useHistory();

   const formatNumber = (number) => {
      if (number == 0){
         return ' ';
      }
      
      if (number != null && number != undefined) {
         return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      }
   }


   return (
      <>
         <div className="admin-card-frame">
            <div className="admin-card-title">{stats.name}</div>
            <div className="admin-card-main-number">{formatNumber(stats.total)}</div>
         </div>
      </>
   )
}


export default React.memo(StatisticsCard);
