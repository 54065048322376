import React, { useState,useEffect } from 'react';
import ShareDialog from './ShareDialog'
import { AdminApi } from '../../../api';
import CustomerSharedRow from './CustomerSharedRow'
import {ConfirmationDialog} from '../../../components/Dialogs'
import {BkButtonSmall} from '../../../components'

const ShareCard = ({ userId }) => {
   const [openShareDialog, setOpenShareDialog] = useState(false);
   const [sharedCustomers, setSharedCustomers] = useState([]);
   const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
   const [idToDelete, setIdToDelete] = useState(0);

   useEffect(() => {
      fetchData();
   }, [userId]);

   const fetchData = async () => {
      AdminApi.getCustomerSharing(userId)
         .then(
            (response) => {
               setSharedCustomers(response.data);
            },
            (error) => {
               console.log(error);
            });
   }

   const addCustomerSharing = (value) => {
      if (value != 0) {
         var data = {
           userID: userId,
           accessToUserId: value
         }
         AdminApi.addCustomerSharing(data)
           .then(
             (response) => {
               setSharedCustomers(sharedCustomers => [...sharedCustomers, response.data])
             },
             (error) => {
               console.log(error);
             });
       }
   }

   const deleteCustomerSharing = (value) => {
      if (value != 0) {
         var data = {
           userID: userId,
           accessToUserId: value
         }
            AdminApi.deleteCustomerSharing(data)
            .then(
               (response) => {
                  setSharedCustomers(sharedCustomers.filter(c =>c.userId !== data.accessToUserId));
               },
               (error) => {
                  console.log(error);
               });
       }
   }

   const handleDialogClosed = (value) => {
      setOpenShareDialog(false);
      if(value != null){
         if(!sharedCustomers.find((c) => c.userId == value)){
            addCustomerSharing(value)
         }
      }
   }

   const handleCustomerDelete = (value) => {

      setIdToDelete(value);
      setDisplayConfirmDialog(true);
   }

   const handleConfirmDialogClose = function(id) {
      deleteCustomerSharing(id)
      setDisplayConfirmDialog(false);
    }


   const openShareDialogClick = () => {
      setOpenShareDialog(true);
   }

   let customerRows = null;
   if (sharedCustomers && sharedCustomers.length > 0) {
      customerRows = sharedCustomers.map((sharedCustomer, index) => {
         return (
            <CustomerSharedRow handleDeletedCb={handleCustomerDelete} customer={sharedCustomer} index={index}></CustomerSharedRow>
         )
      });
   }


   return (
      <>
         <div className='customer-shared-list'>
            <div className='customer-shared-list-header'>Tilgang til: <BkButtonSmall onClickHandler={openShareDialogClick} caption="Legg til"/></div>
            <div className='customer-shared-list-content'>
               {customerRows} 
            </div>
         </div>

         <ConfirmationDialog
            title="Slette?"
            text="Er du sikker på at du vil fjerne tilgangen"
            open={displayConfirmDialog}
            id={idToDelete}
            onClose={handleConfirmDialogClose}
         />

         <ShareDialog
            userID={userId}
            open={openShareDialog}
            handleSelectedCb={handleDialogClosed}
         />
      </>
   )
}

export default React.memo(ShareCard);
