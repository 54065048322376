import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from '@material-ui/core'
import TextLineInput from '../Inputs/TextLineInput';
import NumericInput from '../Inputs/NumericInput';
import BkButtonSmall from '../Buttons/BkButtonSmall/BkButtonSmall';
import BkButton from '../Buttons/BkButton/BkButton';
import ContactButton from '../Buttons/ContactButton/ContactButton';

const NumberDialog = ({ open, title, initialValue, onClose }) => {
   const [value, setValue] = useState<number>(0);

   useEffect(() => {
      setValue(initialValue);
   }, [initialValue]);


   const onValueChange = (value, id, name) => {
      var intValue = parseFloat(value);
      setValue(intValue);
   }

   const handleClose = function () {
      onClose(null);
   }

   const handleSaveAndClose = function () {
      onClose(value);
   }

   return (
      <>
         <Dialog open={open} fullWidth={true} maxWidth={'xs'} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle className='dialog-header'>{title}</DialogTitle>
            <DialogContent>
               <NumericInput placeholder="0" name='value' value={value} onValueChangeCb={onValueChange}/>

            </DialogContent>
            <DialogActions>
               <ContactButton caption="Avbryt" onClickHandler={handleClose}/>
                 
               <BkButton caption="OK" onClickHandler={handleSaveAndClose}/>
            </DialogActions>
         </Dialog>
      </>
   )
}

export default React.memo(NumberDialog);