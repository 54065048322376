import React, { useState, useEffect } from 'react';
import { actionCreators } from '../../store/auth';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { useHistory, useParams } from 'react-router-dom'
import { AdminApi, AuthApi, ReportApi } from '../../api';
import AdminMenu from './child-components/AdminMenu'
import MessageRow from './child-components/MessageRow'
import SubscriptionMessageRow from './child-components/SubscriptionMessageRow'
import SubscriberMessageRow from './child-components/SubscriberMessageRow'
import { ConfirmationDialog } from '../../components/Dialogs'
import Checkbox from "@material-ui/core/Checkbox";

type MessagesProps = typeof actionCreators &
{
   readonly isAuthenticated: boolean;
   readonly isAdmin: boolean;
};

const Messages: React.FC<MessagesProps> = ({
   isAuthenticated,
   isAdmin,
   logoutUser
}) => {

   const history = useHistory();

   const [systemMessages, setSystemMessages] = useState([]);
   const [archivedMessages, setArchivedMessages] = useState([]);
   const [subscriptionMessages, setSubscriptionMessages] = useState([]);
   const [subscriberMessages, setSubscriberMessages] = useState([]);
   const [showArchived, setShowArchived] = useState(false);

   const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
   const [idToArchive, setIdToArchive] = useState(0);


   useEffect(() => {
      fetchData();
   }, []);

   const fetchData = async () => {
      AdminApi.getMessages()
         .then(
            (response) => {
               setSystemMessages(response.data.messages);
               setArchivedMessages(response.data.archived);
               setSubscriptionMessages(response.data.subscriptionMessages);
               setSubscriberMessages(response.data.newSubscribers);
            },
            (error) => {
               console.log(error);
            });
   }


   if (!isAuthenticated) {
      logoutUser();
      history.push("/login");
   }

   if (!isAdmin) {
      history.push("/projects");
   }

   const handleMessageAnswered = (msg) => {
      window.location.href = "mailto:" + msg.email + "?subject=Re: " + msg.title + "&body=" + msg.message;
   }

   const handleMessageArchived = (id) => {
      setIdToArchive(id);
      setDisplayConfirmDialog(true);
   }

   const handleConfirmDialogClose = (id) => {
      setDisplayConfirmDialog(false);

      if (id > 0) {
         var data = {
            id: idToArchive
         }

         AdminApi.checkoutMessage(data)
            .then(
               (response) => {
                  setSystemMessages(systemMessages => (systemMessages.filter(msg => msg.userMessageId !== idToArchive)));
               },
               (error) => {
                  console.log(error);
               });
      }


   }

   const handleArchivedChange = () => {
      setShowArchived(!showArchived);
   }

   let messagesRows = null;
   if (systemMessages && systemMessages.length > 0) {
      messagesRows = systemMessages.map((msg) => {
         return <MessageRow message={msg} onMessageAnswered={handleMessageAnswered} onMessageArchived={handleMessageArchived} archive={false} />
      });
   }

   let subscriptionRows = null;
   if (subscriptionMessages && subscriptionMessages.length > 0) {
      subscriptionRows = subscriptionMessages.map((msg) => {
         return <SubscriptionMessageRow message={msg} />
      });
   }

   let archivedRows = null;
   if (archivedMessages && archivedMessages.length > 0) {
      archivedRows = archivedMessages.map((msg) => {
         return <MessageRow message={msg} onMessageAnswered={handleMessageAnswered} onMessageArchived={handleMessageArchived} archive={true} />
      });
   }

   let subscriberRows = null;
   if (subscriberMessages && subscriberMessages.length > 0) {
      subscriberRows = subscriberMessages.map((msg) => {
         return <SubscriberMessageRow message={msg} />
      });
   }

   return (
      <>
         <div className="admin-container">
            <AdminMenu activeMenuItem={'Meldinger'}></AdminMenu>

            <div className="message-container">
               <div className="module-row">
                  <div className="bk-text-blue admin-checkbox">Vis arkiverte
                     <Checkbox checked={showArchived} onChange={handleArchivedChange} className="admin-checkbox-box" />
                  </div>
               </div>

               <div className="module-row">
                  <div className="flex-1 bk-text-blue">Dato/Fra</div>
                  <div className="flex-1 bk-text-blue">Tittel</div>
                  <div className="flex-3 bk-text-blue">Beskrivelse</div>
                  <div className="flex-1 "></div>
               </div>

               {messagesRows}


               {showArchived &&
                  <>
                     <div className="bk-text-blue-medium bold mt-4">Arkiverte</div>
                     <hr></hr>
                     <div className="module-row">
                        <div className="flex-1 bk-text-blue">Dato/Fra</div>
                        <div className="flex-1 bk-text-blue message-mobile">Tittel</div>
                        <div className="flex-3 bk-text-blue message-mobile">Beskrivelse</div>
                        <div className="flex-1 bk-text-blue message-mobile">Sjekket ut av</div>
                     </div>
 
                     {archivedRows}
                  </>
               }


            </div>
         </div>

         <ConfirmationDialog
            title="Arkivere?"
            text="Vil du arkivere meldingen?"
            open={displayConfirmDialog}
            id={idToArchive}
            onClose={handleConfirmDialogClose}
         />

      </>
   );
}

const mapStateToProps = (state: IApplicationState) => ({
   isAuthenticated: state.auth.isAuthenticated,
   isAdmin: state.auth.isAdmin
});

export default connect(mapStateToProps, actionCreators)(Messages);