import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { Menu } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import { ContactDialog } from '.';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { ConfirmationDialog } from '../../../components/Dialogs'

const GreenCheckbox = withStyles({
  root: {
    color: 'rgba(106,157,158,1)',
    '&$checked': {
      color: 'rgba(106,157,158,1)',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);



const ContactRow = ({ contactData, index, mode, handleSelectedCb, handleChangeCb, handleDeleteCb, displaySelect = false }) => {
  const [openEdit, setOpenEdit] = React.useState(false);
  const [contact, setContact] = useState({
    contactId: 0,
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    address: '',
    zipCode: '',
    city: '',
    checked: false
  });
  const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);

  useEffect(() => {
    if (contactData.contactId != contact.contactId ||
      contactData.firstName != contact.firstName ||
      contactData.lastName != contact.lastName ||
      contactData.email != contact.email ||
      contactData.mobile != contact.mobile ||
      contactData.address != contact.address ||
      contactData.zipCode != contact.zipCode ||
      contactData.city != contact.city ||
      contactData.checked != contact.checked) {

      setContact(contactData);
    }
  }, [contactData]);

  const getRowClass = function (index) {
    if (index % 2 === 0) {
      return "main-table-data-row white-row";
    }
    else {
      return "main-table-data-row grey-row";
    }
  }

  const handleClick = function () {
    if (mode === 0) {
      setOpenEdit(true);
    }
    else {
      contact.checked = !contact.checked;
      handleSelectedCb(contact);
    }
  }

  const handleEdit = function () {
    // Fire dialog
    setOpenEdit(true);
  }

  const handleDelete = function () {
    // Fire dialog
    setDisplayConfirmDialog(true);
  }


  const handleConfirmDialogClose = function (id) {
    if (id > 0) {
      handleDeleteCb(id);
    }
    setDisplayConfirmDialog(false);
  }


  const handleContactUpdated = function (contact) {
    handleChangeCb(contact);
    setOpenEdit(false);
  }


  function ActionMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return <div aria-controls="fade-menu" aria-haspopup="true" className='navbar-profile'  >
      <FontAwesomeIcon icon={faBars} onClick={handleClick} />
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        className="navbar-dropdown-menu"
      >
        <MenuItem onClick={handleEdit}>Rediger</MenuItem>
        <MenuItem onClick={handleDelete}>Slett</MenuItem>
      </Menu>
    </div>;
  }

  let checkboxHtml = null;
  if (displaySelect) {
    checkboxHtml =
      <div className='bk-icon'>
        <GreenCheckbox className="no-padding" checked={contact.checked} onClick={handleClick} />
      </div>
  }

  return (
    <>
      <div className={[getRowClass(index), 'module-row bk-project-row-text'].join(' ')} id='module-row-no-margin'>
        <div className="flex-025 text-trim">{checkboxHtml}</div>
        <div className="flex-1-5 text-trim" onClick={handleClick}>{contact.firstName} {contact.lastName}</div>
        <div className="flex-1 text-trim" onClick={handleClick}>{contact.email}</div>
        <div className="flex-05 text-trim" onClick={handleClick}>{contact.mobile}</div>
        <div className="flex-1-5 text-trim" onClick={handleClick}>{contact.address} {contact.zipCode} {contact.city}</div>
        <div className="flex-05 text-trim">
          <div className="bk-icon"><ActionMenu /></div>
        </div>
      </div>

      <ContactDialog
        contactData={contact}
        open={openEdit}
        handleChangeCb={handleContactUpdated}
      />

      <ConfirmationDialog
        title="Slette?"
        text="Er du sikker på at du vil slette kontakten"
        open={displayConfirmDialog}
        id={contact.contactId}
        onClose={handleConfirmDialogClose}
      />
    </>
  )
}

export default React.memo(ContactRow);