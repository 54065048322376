import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faClipboardList } from '@fortawesome/free-solid-svg-icons'
import { Menu, Divider } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { AutoTextBox, BkButtonSmall } from '../'

const GreenCheckbox = withStyles({
   root: {
      color: 'rgba(106,157,158,1)',
      '&$checked': {
         color: 'rgba(106,157,158,1)',
      },
   },
   checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);


export interface PhraseRowProps {
   phraseId: number;
   index: number;
   order: number;
   text: string;
   defaultText: string;
   isSystem: boolean;
   hasFocus: boolean;
   key: string;
   editMode: boolean;
   handleUpdatedCb: (phraseId: number, text: string) => void;
   handleDeleteCb: (phraseId: number) => void;
   handleMoveUpCb: (phraseId: number, toTop: boolean) => void;
   handleMoveDownCb: (phraseId: number) => void;
   handleSelectedCb: (phraseId: number, selected: boolean) => void;
}

const PhraseRow = (props: PhraseRowProps) => {
   const [phrase, setPhrase] = useState({
      text: '',
      checked: false,
      editMode: false
   });

   useEffect(() => {
      setPhrase(phrase => ({ ...phrase, text: props.text }));
   }, [props.phraseId]);

   useEffect(() => {
      setPhrase(phrase => ({ ...phrase, editMode: props.editMode }));
   }, [props.editMode]);

   const handleDelete = (phraseId) => {
      props.handleDeleteCb(phraseId);
   };

   const handleEdit = (phraseId) => {
      var newVal = !phrase.editMode
      setPhrase(phrase => ({ ...phrase, editMode: newVal }));
   };

   const handleMoveUp = (phraseId, toTop) => {
      setPhrase(phrase => ({ ...phrase, editMode: false }));
      props.handleMoveUpCb(phraseId, toTop);
   };

   const handlMoveDown = (phraseId) => {
      setPhrase(phrase => ({ ...phrase, editMode: false }));
      props.handleMoveDownCb(phraseId);
   };

   const handleClick = () => {
      var newVal = !phrase.checked
      setPhrase(phrase => ({ ...phrase, checked: newVal }));
      props.handleSelectedCb(props.phraseId, newVal);
   }

   const handleTextChange = (text) => {
      if (text != phrase.text) {
         setPhrase(phrase => ({ ...phrase, text: text }));
         props.handleUpdatedCb(props.phraseId, text);
      }
   }

   const handleCopyText = () => {
      setPhrase(phrase => ({ ...phrase, text: props.defaultText }));
      props.handleUpdatedCb(props.phraseId, props.defaultText);
   }

   function ActionMenu(props) {
      const [anchorEl, setAnchorEl] = React.useState(null);
      const open = Boolean(anchorEl);

      const handleClick = (event) => {
         setAnchorEl(event.currentTarget);
      };

      const handleClose = () => {
         setAnchorEl(null);
      };

      return <div aria-controls="fade-menu" aria-haspopup="true" className='navbar-profile'  >
         <FontAwesomeIcon icon={faBars} onClick={handleClick} />
         <Menu
            elevation={0}
            transformOrigin={{
               vertical: 'top',
               horizontal: 'left',
            }}
            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            className="navbar-dropdown-menu"
         >
            <MenuItem onClick={() => handleEdit(props.phraseId)}>{phrase.editMode ? 'Lukk redigering' : 'Rediger frase'}</MenuItem>
            <MenuItem onClick={() => handleDelete(props.phraseId)}>Slett frase</MenuItem>
            <Divider />
            {/* <MenuItem onClick={() =>handleMoveUp(props.phraseId, true)}>Flytt til toppen</MenuItem> */}
            <MenuItem onClick={() => handleMoveUp(props.phraseId, false)}>Flytt opp</MenuItem>
            <MenuItem onClick={() => handlMoveDown(props.phraseId)}>Flytt Ned</MenuItem>
         </Menu>
      </div>;

   }

   return (
      <>
         <div className="phrase-text" key={props.key} >
            <GreenCheckbox className="no-padding" checked={phrase.checked} onClick={handleClick} />
            {phrase.editMode ?
               <>
                  <div className="phrase-auto-text-content">
                     <AutoTextBox text={phrase.text} onValueChangeCb={handleTextChange} focus={props.hasFocus}></AutoTextBox>
                     {(phrase.text == '' && props.defaultText != '' && props.defaultText != null) &&
                        <div className="phrase-auto-text-copy-btn">
                           <BkButtonSmall caption='Hent fra skjematur' onClickHandler={handleCopyText} />
                        </div>
                     }
                  </div>
               </>
               :
               <>
                  <div className={"phrase-text-content"} key={"tb" + props.key}>
                     <AutoTextBox hardRows={5} readOnly text={phrase.text} onValueChangeCb={handleClick} />
                  </div>
               </>
            }

            <div className="bk-icon">
               {props.isSystem == false &&
                  <ActionMenu phraseId={props.phraseId}></ActionMenu>
               }
            </div>

         </div>

      </>

   )
}


export default React.memo(PhraseRow);