import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'
import { formatValue, formatDateTime } from '../../../utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { Menu } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import { SimpleCalculationApi } from '../../../api';
import { ConfirmationDialog } from '../../../components/Dialogs'

const SimpleCalculationRow = ({ calculation, index, onDeleteCb, onCopyCb }) => {
  const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
  const history = useHistory();

  const getRowClass = function (index) {
    if (index % 2 === 0) {
      return "bk-project-row bk-row-white"
    }
    else {
      return "bk-project-row bk-row-grey"
    }
  }

  const formatDescription = function () {
    if (calculation.subTitle === null || calculation.subTitle === '') {
      return calculation.name;
    }
    return calculation.name + " - " + calculation.subTitle;
  }

  const id = calculation.simpleCalculationId;
  const handleEditCalculation = event => {
    event.preventDefault();
    const url = "/simpleCalculation/" + id;
    history.push(url);
  }

  const handlePrintCalculation = event => {
    SimpleCalculationApi.downloadPdf(id, 1);
  }

  const handleCopyCalculation = event => {
    if (id > 0) {
      onCopyCb(id);
    }
  }

  const handleDeleteCalculation = event => {
    setDisplayConfirmDialog(true);
  }

  const handleConfirmDialogClose = function (id) {
    if (id > 0) {
      //onDeleteCb(id);
      onDeleteCb(id);
    }
    setDisplayConfirmDialog(false);
  }



  function ActionMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return <div aria-controls="fade-menu" aria-haspopup="true" className='navbar-profile'  >
      <FontAwesomeIcon icon={faBars} onClick={handleClick} />
      <Menu
        elevation={0}
        //getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        className="navbar-dropdown-menu"
      >
        <MenuItem onClick={handleEditCalculation}>Rediger</MenuItem>
        <MenuItem onClick={handlePrintCalculation}>Skriv ut</MenuItem>
        <MenuItem onClick={handleCopyCalculation}>Kopier</MenuItem>
        <MenuItem onClick={handleDeleteCalculation}>Slett</MenuItem>
      </Menu>
    </div>;

  }



  return (
    <>
      <div className={[getRowClass(index), 'module-row bk-project-row-text'].join(' ')} id='module-row-no-margin'>
        <div className='flex-01'></div>
        <div className="flex-1" onClick={handleEditCalculation}>{formatDateTime(calculation.created)}</div>
        <div className="flex-7" title={calculation.name} onClick={handleEditCalculation}>{formatDescription()}</div>
        <div className="flex-2 align-right" onClick={handleEditCalculation}>{formatValue(calculation.sumIncVat, 0, ' kr')}</div>
        <div className='flex-1'></div>
        <div className='flex-05'><div className="bk-icon"><ActionMenu /></div></div>
      </div>

      <ConfirmationDialog
        title="Slette?"
        text="Er du sikker på at du vil slette kalkulasjonen"
        open={displayConfirmDialog}
        id={id}
        onClose={handleConfirmDialogClose}
      />
    </>
  )
}
SimpleCalculationRow.displayName = 'Skadekalkyle';

export default React.memo(SimpleCalculationRow);