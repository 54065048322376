import React from 'react';
import ReactTooltip from 'react-tooltip';
import { renderToString } from 'react-dom/server'
import { formatValue } from '../../../utils'
import { SectionHeader } from '../../../components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight, faPenToSquare, faPrint, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'


const SumProperty = ({ cost, costPrSquareMeter }) => {

   const getHelpTextTotal = function (section) {
      switch (section) {
         case 0:
            return (<> <h5><b>Sum totalt for hele tomta</b></h5><div className="helptext-list-item"><p>I denne posten fremkommer den totale kostnaden for tomta.</p></div></>);
         case 1:
            return (<> <h5><b>Sum pr m²</b></h5><div className="helptext-list-item"><p>I denne posten fremkommer gjennomsnittsprisen pr. m² ut fra de valgene som blir gjort i de forgående postene.</p></div></>);
      }
   }

   return (
      <>
         <div className="module-row">
            <div className="flex-2">
               Sum totalt for hele tomta
            </div>
            <div className="flex-1">{formatValue(cost, 0, ' kr')}
            </div><div className="flex-05">
               <FontAwesomeIcon className='bk-icon' icon={faQuestionCircle} data-tip={renderToString(getHelpTextTotal(0))} data-for='id5' />
               <ReactTooltip id='id5' place="right" type="light" html={true} effect="solid" className="helptext-custom" />
            </div>
            <div className="flex-5"></div>
         </div>
         <div className="module-row">
            <div className="flex-2">
               Sum pr m²
            </div>
            <div className="flex-1">
               {formatValue(costPrSquareMeter, 0, ' kr')}
            </div><div className="flex-05">
               <FontAwesomeIcon className='bk-icon' icon={faQuestionCircle} data-tip={renderToString(getHelpTextTotal(1))} data-for='id5' />
               <ReactTooltip id='id5' place="right" type="light" html={true} effect="solid" className="helptext-custom" />
            </div>
            <div className="flex-5"></div>
         </div>

      </>
   )
}


export default React.memo(SumProperty);