import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { ContactButton } from '../../components';
import { BkButton } from '../../components';
import parse from 'html-react-parser';

export interface ConfirmationDialogProps {
  title:string;
  text:string;
  open: boolean;
  id:number;
  onClose: (value: number) => void;
}

export default function ConfirmationDialog(props: ConfirmationDialogProps) {
  const { onClose, open, title, text, id } = props;
 
  const handleCancel = () => {
    onClose(0);
  };

  const handleOk = () => {
    onClose(id);
  };


  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="dialog-title"
      open={open}
    >
      <DialogTitle id="dialog-title" className="dialog-header">{title}</DialogTitle>
      <DialogContent dividers>
        <div className='bk-text-blue'>{parse(text)}</div>
      </DialogContent>
      <DialogActions>
      <div className='container mx-1'>
               <div className='row justify-content-end'>
                  <div className='col-auto'> <ContactButton caption='Avbryt' onClickHandler={handleCancel}/></div>
                  <div className='col-auto'> <BkButton caption='OK'  onClickHandler={handleOk}/></div>
               </div>
            </div>
      </DialogActions>
    </Dialog>
  );
}

