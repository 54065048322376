import React from 'react';
import { useTextInput } from '../../../hooks';
import { createClassName } from '../../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';

type PasswordInputProps = {
  readonly showPassword: boolean;
  readonly isInputInvalid: boolean;
  readonly toggleShowPassword: () => void;
  readonly textInput: ReturnType<typeof useTextInput>;
};

const PasswordInput = React.memo<PasswordInputProps>(
  ({ textInput, showPassword, isInputInvalid, toggleShowPassword }) => {
    const { hasValue, bindToInput } = textInput;

    const className = createClassName([
      'input',
      'is-medium',
      (isInputInvalid && !hasValue) && 'is-danger',
    ]);

    return (

        <div className='mb-3'>
         <Form.Control autoFocus
           {...bindToInput}
           className=""
           placeholder= 'Passord'/>
        </div>
    );
  }
);

PasswordInput.displayName = 'PasswordInput';

export default PasswordInput;
