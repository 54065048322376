import React, {useState} from 'react';
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser,faTrashCan } from '@fortawesome/free-solid-svg-icons'
import {Menu} from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import { MonetizationOn, Cancel } from '@material-ui/icons';

const CustomerResultRow = ({customer, index, handleDeletedCb }) => {

   const handleDeleteClick = function(id) {
      handleDeletedCb(id);
   }
   
   return (
      <>
            <div className="main-table-data-row white-row module-row">
               <div className="flex-05 text-trim">{customer.firstName}</div>
               <div className="flex-05 text-trim">{customer.lastName}</div>
               <div className="flex-1 text-trim">{customer.company}</div>
               <FontAwesomeIcon icon={faTrashCan} className="bk-icon" onClick={()=>handleDeleteClick(customer.userId)}/> 
            </div>
      </>
   )
}

export default React.memo(CustomerResultRow);
