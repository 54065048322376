import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { DocumentApi } from '../../api/document.service';
import parse from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faArrowLeft } from '@fortawesome/free-solid-svg-icons'

const Document: React.FC = () => {
   const [document, setDocument] = useState({
      content: '',
      heading: '',
      url: ''
   });

   const params = useParams();
   const id = (params as any).id;

   useEffect(() => {
      const fetchData = async () => {
         DocumentApi.getDocument(id)
            .then(
               (response) => {
                  setDocument(response.data);
               },
               (error) => {
                  console.log(error);
                  // history.goBack();
               });
      }

      fetchData();
   }, []);

   var html = parse(document.content);

   return (
      <div className="main-container">
         <div className='module-row' id='module-row-no-margin'>
            <div className='bk-text-blue-big flex-5'> {document.heading}</div>
            <div className='flex-1'>
               <a href="/documents" className='dialog-button'>
                  Tilbake til hovedmeny
               </a></div>
         </div>
         <div className='report-content'>
            {html}
         </div>
      </div>
   )
}

export default Document;