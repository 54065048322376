import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCircleXmark, faDollarSign } from '@fortawesome/free-solid-svg-icons'
import { Menu } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import { MonetizationOn, Cancel } from '@material-ui/icons';

const CustomerRow = ({ customer, index }) => {

   const history = useHistory();

   const formatDateTime = function (date) {
      if (date === undefined || date === null) {
         // Could be for a new record
         return "-";
      }
      let thedate = new Date(date);
      var options = { year: 'numeric', month: '2-digit', day: '2-digit' } as const;
      return thedate.toLocaleDateString('nb-NO', options);
   }

   function ActionMenu(props) {
      const [anchorEl, setAnchorEl] = React.useState(null);
      const open = Boolean(anchorEl);

      const handleClick = (event) => {
         setAnchorEl(event.currentTarget);
      };

      const handleClose = () => {
         setAnchorEl(null);
      };

      return <div aria-controls="fade-menu" aria-haspopup="true" className='navbar-profile'  >
         <FontAwesomeIcon icon={faBars} onClick={handleClick} />
         <Menu
            elevation={0}
            transformOrigin={{
               vertical: 'top',
               horizontal: 'center',
            }}
            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            className="navbar-dropdown-menu"
         >
            {/* <MenuItem onClick={handleEditProject}>Rediger</MenuItem>
            <MenuItem onClick={handlePrintProject}>Skriv ut</MenuItem>
            <MenuItem onClick={handleCopyProject} disabled>Kopier</MenuItem>
            <MenuItem onClick={handleDeleteProject}>Slett</MenuItem> */}
         </Menu>
      </div>;
   }

   const getRowClass = function (index) {
      if (index % 2 === 0) {
         return "small-table-data-row module-row white-row"
      }
      else {
         return "small-table-data-row module-row grey-row"
      }
   }

   const getSubscriptionStatus = () => {
      if (customer.acceptedDate != null) {
         return <div className="bk-icon green"><MonetizationOn /></div>
      }
      else {
         return <div className="bk-icon transparent"><MonetizationOn /></div>
      }
   }

   const getActivStatus = () => {
      if (customer.active == false) {
         return <div className="bk-icon red"><Cancel /></div>
      }
      else {
         return <></>
      }
   }

   const handleCustomerClick = () => {
      window.open("/customer/" + customer.userId, "_blank")
      // win.focus();
      // const url = "/customer/" + customer.userId;
      // history.push(url);
   }


   return (
      <>

         <div className={getRowClass(index)} onClick={handleCustomerClick} id='module-row-no-margin'>
            <div className="flex-015 ms-1">{index + 1}</div>
            <div className='status-icons-container flex-025'>
               {
                  getSubscriptionStatus()
               }
               {
                  getActivStatus()

               }
            </div>
            <div className="flex-05">{customer.userId}</div>
            <div className="flex-1 text-trim">{customer.lastName}</div>
            <div className="flex-1 text-trim">{customer.firstName}</div>
            <div className="flex-1 text-trim">{customer.company}</div>
            <div className="flex-05">{formatDateTime(customer.registeredDate)}</div>
            <div className="flex-05">{formatDateTime(customer.lastLogin)}</div>
            <div className="flex-025 align-right">{customer.projectCount}</div>
            <div className="flex-025 align-right">{customer.calculationCount}</div>
            <div className="flex-025 align-right">{customer.reportCount}</div>
            <div className="flex-01"></div>
         </div>

      </>
   )
}

CustomerRow.displayName = 'CustomerRow';

export default React.memo(CustomerRow);
