import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ContactButton } from '../'
import { BkButton } from '../'
import { BkButtonSmall } from '../'
import { PhraseApi } from '../../api';
import { ConfirmationDialog } from '.'
import PhraseRow from './PhraseRow';
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faPlus } from '@fortawesome/free-solid-svg-icons'

function PaperComponent(props) {
   return (
      <Draggable
         handle="#draggable-dialog-title"
         cancel={'[class*="MuiDialogContent-root"]'}
      >
         <Paper {...props} />
      </Draggable>
   );
}


export interface PhraseDialogProps {
   open: boolean;
   phraseKey: string;
   text: string;
   singleLine?: boolean;
   handleCancelCb: () => void;
   handleSaveCb: (text: string) => void;
}

const PhraseDialog = (props: PhraseDialogProps) => {
   const [openDialog, setOpenDialog] = useState(false);
   const [phrases, setPhrases] = useState([]);
   const [selectedPhrases, setSelectedPhrases] = useState([]);
   const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
   const [idToBeDeleted, setIdToBeDeleted] = useState(0);
   const [focusPhraseId, setFocusPhraseId] = useState(0);
   const [defaultText, setDefaultText] = useState('');


   useEffect(() => {
      setOpenDialog(props.open);
      setFocusPhraseId(0);
      setDefaultText(props.text);
      setSelectedPhrases([]);
      if (props.open) {
         PhraseApi.getPhrases(props.phraseKey, false)
            .then(
               (response) => {
                  setPhrases(response.data);
               },
               (error) => {
                  //    
                  console.log(error);
               });
      }
   }, [props.open]);

   const handleClose = () => {
      props.handleCancelCb();
   };

   const handleSave = () => {
      var text = '';
      selectedPhrases.map((phrase, index) => {
         if (phrase.selected === true) {
            if (text == '') {
               text = phrase.text;
            }
            else if (props.singleLine) {
               text = text + ', ' + phrase.text;
            }
            else {
               text = text + '\r\n' + phrase.text;
            }
         }
         return phrase;
      })
      props.handleSaveCb(text);
   }

   const handlePhraseSelected = (id, selected) => {
      if (id > 0) {
         var thePhrase = null;
         phrases.map((phrase, index) => {
            if (phrase.phraseId === id) {
               phrase.selected = selected;
               thePhrase = phrase;
            }
            return phrase;
         })

         if (selected) {
            setSelectedPhrases(selectedPhrases => [...selectedPhrases, thePhrase])
         }
         else {
            setSelectedPhrases(selectedPhrases.filter(p => p.phraseId != id));
         }
      }
   };

   const handleConfirmDialogClose = (id) => {
      setDisplayConfirmDialog(false);
      if (id > 0) {
         PhraseApi.deletePhrase(id)
            .then(
               (response) => {
                  setPhrases(phrases.filter(p => p.phraseId != id));
                  setIdToBeDeleted(0);
               },
               (error) => {
                  //    
                  console.log(error);
               });
      }
   }

   const handleDelete = (id) => {
      setIdToBeDeleted(id);
      setDisplayConfirmDialog(true);
   };

   const handleUpdated = (phraseId, text) => {
      if (phraseId == 0) {
         //New (post)
         var phrase = phrases.find(phrase => phrase.phraseId == 0);
         if (phrase != null) {
            phrase.text = text;
            addPhrase(phrase, phrases);
         }
      }
      else {
         // existing => put
         var phrase = phrases.find(phrase => phrase.phraseId == phraseId);
         if (phrase != null) {
            phrase.text = text;
            updatePhrase(phrase);
         }
      }
   }

   const handleAdd = () => {
      //Check that this is no other pending update
      var phrase = phrases.find(phrase => phrase.phraseId == 0);
      if (phrase != null) {
         // Already an active new
         return;
      }
      var newPhrase = {
         phraseId: 0,
         userId: 0,
         key: props.phraseKey,
         text: '',
         order: 0
      }
      const newArray = [...phrases];
      newArray.splice(0, 0, newPhrase);
      setPhrases(newArray);
      setFocusPhraseId(0);
      // if (defaultText != undefined && defaultText != '') {
      //    addPhrase(newPhrase, newArray);
      //    setDefaultText('');
      // }
   }

   const addPhrase = (phrase, array) => {
      PhraseApi.addPhrase(phrase)
         .then(
            (response) => {
               var copy = array.map((phrase, index) => {
                  if (phrase.phraseId === 0) {
                     if (focusPhraseId == 0) {
                        setFocusPhraseId(response.data.phraseId);
                     }
                     return response.data;
                  }
                  return phrase;
               })
               setPhrases(copy);
            },
            (error) => {
               //    
               console.log(error);
            });
   }

   const updatePhrase = (phrase) => {
      PhraseApi.updatePhrase(phrase)
         .then(
            (response) => {
            },
            (error) => {
               //    
               console.log(error);
            });
   }

   const handleMoveUp = (phraseId, toTop) => {
      var selectedIndex = 0;
      phrases.map((phrase, index) => {
         if (phrase.phraseId === phraseId) {
            selectedIndex = index;
         }
         return phrase;
      })

      if (selectedIndex === 0) return; // already on top

      const data = [...phrases];
      var firstOrder = data[selectedIndex - 1].order;
      var lastOrder = data[selectedIndex].order;
      let temp = data[selectedIndex - 1];
      temp.order = lastOrder;
      data[selectedIndex].order = firstOrder;
      data[selectedIndex - 1] = data[selectedIndex];
      data[selectedIndex] = temp;
      setPhrases(data);
      updatePhrase(data[selectedIndex]);
      updatePhrase(data[selectedIndex - 1]);
   };

   const handleMoveDown = (phraseId) => {
      var selectedIndex = phrases.length - 1;
      phrases.map((phrase, index) => {
         if (phrase.phraseId === phraseId) {
            selectedIndex = index;
         }
         return phrase;
      })
      if (selectedIndex === phrases.length - 1) return; // already at the end

      const data = [...phrases];
      var firstOrder = data[selectedIndex].order;
      var lastOrder = data[selectedIndex + 1].order;
      let temp = data[selectedIndex + 1];
      temp.order = firstOrder;
      data[selectedIndex].order = lastOrder;
      data[selectedIndex + 1] = data[selectedIndex];
      data[selectedIndex] = temp;
      setPhrases([...data]);
      updatePhrase(data[selectedIndex]);
      updatePhrase(data[selectedIndex + 1]);
   };


   var phrasesHtml = null;
   if (phrases && phrases.length > 0) {
      phrasesHtml = phrases.map((phrase, index) => {
         var key = "phrase_" + phrase.phraseId;
         if (phrase.phraseId == 0) {
            var key = "newPhrase_" + phrases.length;
         }
         return (
            <>
               <PhraseRow
                  phraseId={phrase.phraseId}
                  index={index}
                  text={phrase.text}
                  defaultText={defaultText}
                  order={phrase.order}
                  isSystem={phrase.userId == null}
                  hasFocus={phrase.phraseId == focusPhraseId}
                  editMode={phrase.phraseId == focusPhraseId}
                  handleUpdatedCb={handleUpdated}
                  handleDeleteCb={handleDelete}
                  handleMoveUpCb={handleMoveUp}
                  handleMoveDownCb={handleMoveDown}
                  handleSelectedCb={handlePhraseSelected}
                  key={key}
               ></PhraseRow>


            </>)
      });
   }




   return (
      <>

         <Dialog open={openDialog} fullWidth={true} maxWidth={'lg'} onClose={handleClose} aria-labelledby="form-dialog-title" keepMounted={false} PaperComponent={PaperComponent}>
            <DialogTitle className="dialog-header">
               <div className='module-row'>
                  <div className='flex-2'>Velg frase</div>
                  <div className='flex-5'></div>
                  <div className='flex-05'><div className="phrase-add-button-container">
                     <FontAwesomeIcon icon={faPlus} title="Legg til" className='cursor-pointer' onClick={handleAdd} />
                  </div>
                  </div>

               </div>
            </DialogTitle>
            <DialogContent>
               <div className="phrase-text-container mt-3">
                  {phrases.length > 0 ?
                     <>
                        {phrasesHtml}
                     </>
                     :
                     <>
                        <div className="phrase-text-no-phrases">
                           Ingen fraser er registrert
                        </div>

                     </>
                  }
               </div>
            </DialogContent>
            <DialogActions className='dialog-actions'>
               <ContactButton caption='Avbryt' onClickHandler={handleClose} />
               <BkButton caption='Legg til' onClickHandler={handleSave} />
            </DialogActions>
         </Dialog>

         <ConfirmationDialog
            title="Slette?"
            text="Er du sikker på at du vil slette frasen"
            open={displayConfirmDialog}
            id={idToBeDeleted}
            onClose={handleConfirmDialogClose}
         />

      </>

   )
}


export default React.memo(PhraseDialog);