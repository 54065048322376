import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { CostFactorCategory } from '../../../config/constants';
import { displayNumericValue } from '../../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight, faPenToSquare, faPrint, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { NumericInput, TextLineInput, AutoTextBox, SelectInput } from '../../../components';

const primaryAreaProperty = 27;
const secondaryAreaProperty = 28;

interface CalculationPropertyProps {
  property: any,
  index: number,
  readonly onUpdatedCb?: (preoperty: any) => void;
}

const keyPressTimer = 1000;
let inputTimer = null;

function CalculationProperty(props: CalculationPropertyProps) {

  const [property, setProperty] = useState({
    propertyTypeId: 0,
    controlTypeId: 0,
    items: [],
    name: '',
    suffix: '',
    helpText: '',
    hidden: false,
    numericValue: 0,
    textValue: '',
    required: true
  });

  useEffect(() => {
    setProperty(props.property);
  }, [props]);


  const onNumericChange = (value, id, name) => {
    var intVal = parseInt(value);
    if (isNaN(intVal)) {
      intVal = 0;
    }
    if (property.controlTypeId == 12) {
      if (intVal > 100)
        intVal = 100;
    }
    if (intVal == property.numericValue) {
      return;
    }
    setProperty(property => ({ ...property, numericValue: intVal }));
    var toBeUpdated = property;
    toBeUpdated.numericValue = intVal;
    updateProperty(toBeUpdated);
  }

  const onNumericBlur = (e) => {
    const { name, value } = e.target;
    clearTimeout(inputTimer);
    var intVal = parseInt(value);
    if (isNaN(intVal)) {
      intVal = 0;
    }
    if (property.controlTypeId == 12) {
      if (intVal > 100)
        intVal = 100;
    }
    setProperty(property => ({ ...property, numericValue: intVal }));
    var toBeUpdated = property;
    toBeUpdated.numericValue = intVal;
    updateProperty(toBeUpdated);
  }

  const onTextChange = (value, id, name) => {
    setProperty(property => ({ ...property, textValue: value }));
    var toBeUpdated = property;
    toBeUpdated.textValue = value;
    updateProperty(toBeUpdated);
  }

  const onTextBlur = (e) => {
    const { name, value } = e.target;
    clearTimeout(inputTimer);

    setProperty(property => ({ ...property, textValue: value }));
    var toBeUpdated = property;
    toBeUpdated.textValue = value;
    updateProperty(toBeUpdated);
  }


  const onAutoTextChange = (value) => {
    setProperty(property => ({ ...property, textValue: value }));
    var toBeUpdated = property;
    toBeUpdated.textValue = value;
    updateProperty(toBeUpdated);
  }

  const updateProperty = (prop) => {
    props.onUpdatedCb(prop);
  }


  const initialOptions = function (caption) {
    return (
      <option key={0} value={0}>{caption}</option>
    )
  }

  const getText = (txt) => {
    if (txt == null || txt == undefined) {
      return "";
    }

    return txt;
  }

  const onSelectChanged = function (value) {
    value = parseInt(value);

    if (value && value !== 0) {
      setProperty(property => ({ ...property, numericValue: value }));
      var toBeUpdated = property;
      toBeUpdated.numericValue = value;
      updateProperty(toBeUpdated);
    }
  }

  const getSelectItems = function (property) {

    return <SelectInput selectedValue={property.numericValue} valueKey='id' nameKey='name' defaultOption={property.caption} options={property.items} onValueChangeCb={onSelectChanged} />

    // var options = [];

    // if (property.items) {
    //   options.push(initialOptions(property.caption));
    //   var newOptions = property.items.map((option) => {
    //     return <option key={option.id} value={option.id}>{option.name}</option>
    //   });
    //   options.push(newOptions);
    // }

    // return (
    //   <SelectInput valueKey='id' nameKey='name' defaultOption={property.caption} options={property.items} onValueChangeCb={onSelectChanged} />
    //   // <elect
    //   //   className={['bk-input-field', (property.required && property.numericValue <= 0) ? 'calculation-required-error' : ''].join(' ')}
    //   //   value={property.numericValue}
    //   //   onChange={(e) => onSelectChanged(e)}>
    //   //   {options}
    //   // </select>
    // );
  }

  let propertyHtml = null;
  if (property) {
    if (!property.hidden && !(property.propertyTypeId === primaryAreaProperty || property.propertyTypeId === secondaryAreaProperty)) {

      const floorsWithoutItems = property.propertyTypeId === CostFactorCategory.ResidenceFloors && (property.items && property.items.length <= 0) ? true : false;

      // do not show floors without items. For special case such as: 'leileghet'
      if (property.controlTypeId === 1 && !floorsWithoutItems) {

        // Select
        propertyHtml = (
          <div className="module-row" key={"TC" + props.index}>
            <div className="flex-6">{property.name}</div>
            <div className="flex-3">
              <i>{property.suffix}</i>
              {getSelectItems(property)}
            </div>
            <div className="flex-1">
              <FontAwesomeIcon className='bk-icon' icon={faQuestionCircle} data-for={"tooltip" + props.index} data-tip={property.helpText} />
              <ReactTooltip id={"tooltip" + props.index} type="info" place="right" html={true} effect="solid" className="helptext-custom" />
            </div>
          </div>
        );

      }
      else if (property.controlTypeId === 2 || property.controlTypeId === 3 || property.controlTypeId === 5 || property.controlTypeId === 12) {
        // numeric
        propertyHtml = (
          <div className="module-row" key={"TC" + props.index}>
            <div className="flex-6">{property.name}</div>
            <div className="flex-3">
              <NumericInput class={(property.required && property.numericValue <= 0) ? 'calculation-required-error' : ''} suffix={property.suffix} placeholder="0" name={"TC" + props.index} value={property.numericValue} onValueChangeCb={onNumericChange} />
            </div>
            <div className="flex-1">
              <FontAwesomeIcon className='bk-icon' icon={faQuestionCircle} data-for={"tooltip" + props.index} data-tip={property.helpText} />
              <ReactTooltip id={"tooltip" + props.index} place="right" type="light" html={true} effect="solid" className="helptext-custom" />
            </div>
          </div>
        );
      }
      else if (property.controlTypeId === 4) {
        // text
        propertyHtml = (
          <div className="module-row" key={"TC" + props.index}>
            <div className="flex-6">{property.name}</div>
            <div className="flex-3">
              <AutoTextBox defaultRows={3} text={property.textValue || ''} onValueChangeCb={onTextChange} />

            </div>
            <div className="flex-1">
              <FontAwesomeIcon className='bk-icon' icon={faQuestionCircle} data-for={"tooltip" + props.index} data-tip={property.helpText} />
              <ReactTooltip id={"tooltip" + props.index} place="right" type="light" html={true} effect="solid" className="helptext-custom" />
            </div>
          </div>
        );
      }
      else if (property.controlTypeId === 6) {
        // note
        propertyHtml = (
          <div className="module-row align-items-start" key={"TC" + props.index}>
            <div className='flex-6'>{property.name}</div>
            <div className='flex-3'><AutoTextBox defaultRows={3} text={property.textValue || ''} onValueChangeCb={onTextChange} /></div>
            <div className="flex-1"></div>
          </div>
        );
      }
      else if (property.controlTypeId === 11) {
        // text
        propertyHtml = (
          <div className="module-row" key={"TC" + props.index}>
            <div className="flex-2">{property.name}</div>
            <div className="flex-4">
              <AutoTextBox defaultRows={1} text={getText(property.textValue)} lineHeight={25} onValueChangeCb={onAutoTextChange}></AutoTextBox>
            </div>
            <div className="flex-3">
              <NumericInput placeholder="0" name={"TC" + props.index} suffix={property.suffix} value={property.numericValue} onValueChangeCb={onNumericChange} />
            </div>
            <div className="flex-1">
              <FontAwesomeIcon className='bk-icon' icon={faQuestionCircle} data-for={"tooltip" + props.index} data-tip={property.helpText} />
              <ReactTooltip id={"tooltip" + props.index} place="right" type="light" html={true} effect="solid" className="helptext-custom" />
            </div>
          </div>
        );
      }
    }
  }



  return (
    <>

      {propertyHtml}
    </>
  )
}


export default React.memo(CalculationProperty);