import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { actionCreators } from '../../store/auth';
import { useHistory } from 'react-router-dom'
import { RoutesConfig } from '../../config/routes.config';
import Aos from "aos";
import "aos/dist/aos.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckSquare, faHouse, faCheck,
    faMoneyCheckAlt, faHouseDamage, faHandHoldingDollar,
    faSearchDollar, faRulerCombined, faCoins, faMoneyBill1Wave, faClipboard, faCalculator, faMedal,
    faMugHot, faHourglass2, faClock, faHammer,
    faClipboardCheck,
    faRobot,
} from '@fortawesome/free-solid-svg-icons'
import { BkButton, BkButtonSmall } from '../../components';



type HomeProps = typeof actionCreators &
{
    readonly isAuthenticated: boolean;
    readonly role: number;
    readonly userName: string;
};


function Item(props) {
    return (
        <div>
            {props.item.html}
        </div>
    )
}


const Home: React.FC<HomeProps> = ({
    isAuthenticated,
    userName,
    role
}) => {

    useEffect(() => {
        Aos.init({ duration: 1700, once: true })
    }, [])


    const history = useHistory();

    const handleQuestionClick = event => {
        event.preventDefault();
        history.push(RoutesConfig.Contact.path)
    }

    const handleRegistrationClick = event => {
        event.preventDefault();
        history.push(RoutesConfig.Register.path)
    }

    if (isAuthenticated) {
        history.push(RoutesConfig.Projects.path)
    }


    return (<>
        <div>
            <div className='hero-section'>
                <div className='module-row justify-end'>
                    <div data-aos="slide-left" className='hero-card'>
                        <img className='hero-card-image' alt="Byggekost" src="\Content\images\design\BK-enkel-blue.svg" />
                        <div className='hero-card-text'>
                            <div className='hero-card-title'>Verktøyet som forenkler takstarbeidet!</div>
                            <div className='hero-card-content'>
                                Gjør kalkulasjonene enklere, raskere og mer presise. <br></br>
                                Spar tid, reduser feil og lever profesjonelle takstrapporter <br></br>
                                med vårt brukervennlige verktøy,spesialdesignet for takstbransjen. <br></br>
                                Prøv det gratis i dag og oppdag forskjellen!
                            </div>
                        </div>
                        <div className='module-row'><BkButton caption='Start prøveperioden' /></div>
                    </div>
                </div>
            </div>

            <div className='customer-review-container' id='customer_review_web'>
                <div className='flex-3'>
                    <div> Brukervennlig, oversiktlig og tidsbesparende verktøy. Bør prøves.</div>
                    <hr></hr>
                    <div className='customer-review-name'>Jim Holm-Johansen</div>
                </div>
                <div className='flex-3'>
                    <div>Supert arbeidsredskap, innholdsrik og brukervennlig. Anbefales på det sterkeste.</div>
                    <hr></hr>
                    <div className='customer-review-name' >Olav Sørensen </div>
                </div>
                <div className='flex-3'>
                    <div> Utrolig bra verktøy, som virkelig forenkler hverdagen. Så enkelt kan det gjøres. </div>
                    <hr></hr>
                    <div className='customer-review-name'>Helge Fjellro</div></div>
                <div className='flex-3'>
                    <div> Fint verktøy og tjenester. Akkurat det jeg trenger, til en fornuftig pris.</div>
                    <hr></hr>
                    <div className='customer-review-name'>Roger Johannessen</div>
                </div>
            </div>

            <div className='services-container' id="function">
                <div data-aos="fade-up" className='services-card'>
                    <FontAwesomeIcon icon={faClipboardCheck} className="fa-3x" />
                    <div className='services-card-title'>Rapporter</div>
                    <div className=''>Digitaliser takstrapportene dine med vårt intuitive verktøy.
                        Spar tid, reduser feil, og lever profesjonelle rapporter på rekordtid!</div>
                </div>
                <div data-aos="fade-up" className='services-card'>
                    <FontAwesomeIcon icon={faCalculator} className="fa-3x" />
                    <div className='services-card-title'>Kalkulasjoner</div>
                    <div className=''>Effektiviser kalkulasjonene med vårt digitale verktøy.
                        Få nøyaktige resultater raskt og enkelt, og spar verdifull tid!</div>
                </div>
                <div data-aos="fade-up" className='services-card'>
                    <FontAwesomeIcon icon={faCoins} className="fa-3x" />
                    <div className='services-card-title'>Prisliste</div>
                    <div className=''>Sjekk prislister enkelt med vårt digitale verktøy.
                        Hold deg oppdatert og ta informerte beslutninger på sekunder!</div>
                </div>
                {/* <div data-aos="fade-up" className='services-card'>
                    <FontAwesomeIcon icon={faRobot} className="fa-3x" />
                    <div className='services-card-title'>AI-hjelp</div>
                    <div className=''>Skriv rapporter raskere med vår innebygd AI verktøy.
                        Verktøyet hjelper deg å forbedre og renskrive tekster.</div>
                </div> */}
            </div>
        </div>


        <div className='advantages-container'>
            <div className='advantages-card'>
                <FontAwesomeIcon icon={faClock} className='fa-3x' />
                <div>
                    <div className='advantages-card-title'> Alltid oppdatert</div>
                    <div className='advantages-card-content'>Oppdateres jevnlig iht. SSB og prisliste fra Optimera</div>
                </div>
            </div>

            <div className='advantages-card'>
                <FontAwesomeIcon icon={faMugHot} className='fa-3x' />
                <div>
                    <div className='advantages-card-title'> Enkel bruk</div>
                    <div className='advantages-card-content'>Raskt å bruke og sikrer høy kvalitet i de tekniske beregningene</div>
                </div>
            </div>

            <div className=' advantages-card'>
                <FontAwesomeIcon icon={faMedal} className='fa-3x' />
                <div>
                    <div className='advantages-card-title'> Kvalitetssikring</div>
                    <div className='advantages-card-content'>Kvalitetssikret av eksterne kilder. Utregning man kan stole på </div>
                </div>
            </div>

            <div className='advantages-card'>
                <FontAwesomeIcon icon={faHourglass2} className='fa-3x' />
                <div>
                    <div className='advantages-card-title'> Gratis prøveperiode</div>
                    <div className='advantages-card-content'>Gratis prøveperiode på 14 dager for ny brukere</div>
                </div>
            </div>
        </div>

        <div>

            <div className='home-price-container'>

                <div data-aos="zoom-in" className='home-price-card'>

                    <div >
                        <div className='home-price-card-title'>Årsabonnement</div>
                        <div className='home-price-card-subtitle'>5 <span className=''>200,-</span>
                            <span className=''>eks.mva</span></div>
                        <hr style={{ color: '#44758C' }} />
                    </div>

                    <div className='home-price-card-list'>
                        <div className='home-price-card-info-text'>
                            <FontAwesomeIcon icon={faCheck} /> Tekniske kalkulasjoner</div>
                    </div>

                    <div>
                        <hr style={{ color: '#44758C' }} />
                        Pris er per bruker.
                        <div>Abonnementsperioden starter ved akseptert avtale</div>
                    </div>

                    <div className='module-row'>
                        <BkButton caption={'Registrer deg'} href="/register" />
                    </div>

                </div>

                <div data-aos="zoom-in" className='home-price-card'>

                    <div >
                        <div className='home-price-card-title'>Tillegg - Rapporter</div>
                        <div className='home-price-card-subtitle'> 7 <span className=''>500,-</span>
                            <span className=''>eks.mva</span></div>
                        <hr style={{ color: '#44758C' }} />
                    </div>
                    <div className='home-price-card-list'>
                        <div className='home-price-card-info-text'>
                            <FontAwesomeIcon icon={faCheck} className="me-2" /> Rapporter</div>
                        <div className='home-price-card-info-text'>
                            <FontAwesomeIcon icon={faCheck} className="me-2" /> Andre kalkulasjoner</div>
                    </div>


                    <div>
                        <hr style={{ color: '#44758C' }} />
                        <div>Pris inkluderer 50 stk. rapporter (forhåndsfaktureres)</div>
                        <div>Ingen tidsbegrensning på av rapporter / klikk.</div>
                        <div>Tilgang forutsetter betalt årsabonnement.</div>
                    </div>

                    <div className='module-row'>
                        <BkButton caption={'Registrer deg'} href="/register" />
                    </div>

                </div>

            </div>


        </div>


    </>)


}

const mapStateToProps = (state: IApplicationState) => ({
    isAuthenticated: state.auth.isAuthenticated,
    role: state.auth.role,
    userName: state.auth.userName,
});

export default connect(mapStateToProps, actionCreators)(Home);
