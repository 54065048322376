import React, { useState, useEffect} from 'react';
import ReactTooltip from 'react-tooltip';
import { renderToString } from 'react-dom/server'
import { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { BkButton, NumericInput, TextLineInput, AutoTextBox, SelectInput, BkButtonSmall } from '../../../components';


const ValueReduction = ({
  index,
  calculationId,
  valueReductions,
  valueReductionTypeCostFactorEntities,
  onChanged,
  onBuildYearClicked,
}) => {

  const handleValueReductionChange = function (value, id, name) {
    onChanged(calculationId, id, value);
  }

  const handleBuildYearClick = function () {
    onBuildYearClicked(calculationId);
  }

  const getName = function () {
    if (index === 0) {
      return "Opprinnelig bygning";
    }
    else {
      return "Tilbygg " + index;
    }
  }

  const getValueReductionItem = function (reduction) {
    return (
      <SelectInput selectedValue={reduction.selectedId} id={reduction.valueReductionId} valueKey='costFactorId' nameKey='name' defaultOption='-' options={valueReductionTypeCostFactorEntities} onValueChangeCb={handleValueReductionChange} />
    );

  }

  let valueReductionsHtml = null;
  if (valueReductions) {
    valueReductionsHtml = valueReductions.map((reduction, index) => {
      if (reduction.controlTypeId === 0 && reduction.visible) {
        // Select
        return (
          <div className="module-row" key={calculationId + '.' + index}>
            <div className="flex-6 ">{reduction.description}</div>
            <div className="flex-3  ">
              {getValueReductionItem(reduction)}
            </div>
            <div className="flex-1"></div>
          </div>
        );
      }
      else if (reduction.controlTypeId === 3 && reduction.visible) {
        // numeric
        return (
          <div className="module-row" key={calculationId + '.' + index}>
            <div className="flex-6 ">{reduction.description}</div>
            <div className="flex-3  ">
            <NumericInput placeholder="0" id={reduction.valueReductionId} suffix='kr' value={reduction.numericValue} onValueChangeCb={handleValueReductionChange} />
            </div>
            <div className="flex-1"></div>
          </div>
        );
      }
      else if (reduction.controlTypeId === 4 && reduction.visible) {
        // text
        return (
          <div className="module-row" key={calculationId + '.' + index}>
            <div className="flex-9">
              <div className="">{reduction.description}</div>
              <div className="">
                <div className="input-icon input-icon-right">
                  <AutoTextBox defaultRows={3} id={reduction.valueReductionId} text={reduction.textValue} onValueChangeCb={handleValueReductionChange} />
                </div>
              </div>
            </div>
            <div className="flex-1"></div>
          </div>
        );
      }
      else
        return null;
    });
  }


  const getValueReductionHelpText = function () {
    return (
      <>
        <h5><b>Verdireduksjon</b></h5>
        <div className="helptext-list-item">
          <p>Velg byggeår på bygningsdel</p>
        </div>
        <div className="helptext-list-item">
          <p>Dersom alle bygningsdeler er fra byggeår trykk «Bruk byggeår»</p>
        </div>
        <div className="helptext-list-item">
          <p>Dersom det er gjort oppgraderinger på bygningen etter byggeår velges alder på bygningsdelen</p>
        </div>
        <div className="helptext-list-item">
          <p>Vurderes bygningsdelen å ha mer (eller mindre) slitasje i forhold til det som kan forventes fra byggeår må antall år justeres (skjønn)</p>
        </div>
        <div className="helptext-list-item">
          <p>TEK og levetidstabeller er hensyntatt i sum for verdireduksjon.</p>
        </div>
        <div className="helptext-list-item">
          <p>Ekstraordinær verdireduksjon</p>
        </div>
        <div className="helptext-list-item-level2">
          <p>Denne posten skal man aller helst IKKE bruke!!!</p>
        </div>
        <div className="helptext-list-item-level2">
          <p>Brukes kun for ekstraordinære verdireduksjon ut over det som medtas i de andre postene.</p>
        </div>
      </>
    )
  }


  return (
    <>
      <div className="module-row mb-3">
        <div className="flex-3">
          <div className=' module-row-header-medium mt-1' id='module-header-left'> Verdireduksjon - {getName()}
            <FontAwesomeIcon icon={faQuestionCircle} className=" ms-2 mt-1 bk-icon" data-for={"index" + index} data-tip={renderToString(getValueReductionHelpText())} />
          </div>
          <ReactTooltip place="left" id={"index" + index} type="light" html={true} effect="solid" className="helptext-custom" />
        </div>
        <div className='flex-4'></div>
        <div className='flex-1'>
          {/* <BkButtonSmall name="UseBuildYear" type="submit" caption='Bruk byggeår' title="Trykk her for å sette alder til byggeår for alle feltene" onClick={handleBuildYearClick} /> */}
          <input name="UseBuildYear" type="submit" className="dialog-button" value="Bruk Byggeår" title="Trykk her for å sette alder til byggeår for alle feltene" 
          onClick={handleBuildYearClick}/>
        </div>
      </div>

      {valueReductionsHtml}
    </>
  )
}


export default React.memo(ValueReduction);