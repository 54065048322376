import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { ContactApi } from '../../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { Menu } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import { ContactRow, ContactDialog, AddContactDialog } from './child-components';
import { isNullOrEmpty } from '../../utils'
import { BkButton, TextLineInput } from '../../components';

const Contacts: React.FC = () => {
  const [contacts, setContacts] = useState([]);

  // Used for edit project data
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);
  const [searchText, setSearchText] = useState('');
  const [contactData, setContactData] = useState({
    contactId: 0,
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    address: '',
    zipCode: '',
    city: '',
    company: '',
    vatNumber: '',
  });

  const [displayArray, setDispayArray] = useState([]);

  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      ContactApi.getContacts()
        .then(
          (response) => {
            var data = response.data.sort((a, b) => (a.firstName < b.firstName ? -1 : 1));
            setContacts(data);
            setDispayArray(data);
          },
          (error) => {
            console.log(error);
          });
    }
    fetchData();
    setSearchText('');
  }, []);


  const handleAddContact = function () {
    setOpenAdd(true);
  }

  const handleEditContact = function () {
    setOpenEdit(true);
  }

  const handleContactAdded = function (contact) {
    if (contact !== null) {
      // Add new contact
      ContactApi.addContact(contact)
        .then(
          (response) => {
            setContacts(contacts => [response.data, ...contacts]);
            setDispayArray(displayArray => [response.data, ...displayArray]);
          },
          (error) => {
            console.log(error);
          });
    }
    setOpenAdd(false);
  }
  const handleContactUpdated = function (contact) {
    if (contact !== null) {

      // Update existing contact
      ContactApi.updateContact(contact)
        .then(
          (response) => {
            // Update the contact in the list
            var newContacts = contacts.map((oldContact) => {
              if (oldContact.contactId === contact.contactId) {
                return response.data;
              }
              return oldContact;
            })
            setContacts(newContacts);
            setDispayArray(newContacts);
          },
          (error) => {
            console.log(error);
          });
    }
    setOpenEdit(false);
  }

  const handleContactSelected = function (id) {
    // Not used
  }

  const handleContactDeleted = function (id) {
    // Delete contact
    ContactApi.deleteContact(id)
      .then(
        (response) => {
          setContacts(contacts.filter(contact => contact.contactId !== id));
          setDispayArray(displayArray.filter(contact => contact.contactId !== id));
        },
        (error) => {
          console.log(error);
        });
  }


  function ActionMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return <div aria-controls="fade-menu" aria-haspopup="true" className='navbar-profile'  >
      <FontAwesomeIcon icon={faBars} onClick={handleClick} />
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        className="navbar-dropdown-menu"
      >
        <MenuItem onClick={handleAddContact}>Legg til kontakt</MenuItem>
      </Menu>
    </div>;
  }

  const handleContactSearch = function (value, id, name) {
    var searchstring = value.toLowerCase();
    setSearchText(searchstring);

    if (isNullOrEmpty(searchstring)) {
      setDispayArray(contacts);
      return;
    }

    setDispayArray(contacts.filter(contact => (
      contact.firstName.toLowerCase().includes(searchstring) ||
      contact.lastName.toLowerCase().includes(searchstring) ||
      (contact.firstName.toLowerCase() + ' ' + contact.lastName.toLowerCase()).includes(searchstring) ||
      contact.email.toLowerCase().includes(searchstring) ||
      contact.address.toLowerCase().includes(searchstring) ||
      contact.city.toLowerCase().includes(searchstring)
    )));


  }


  let contactsHtml = null;
  if (displayArray) {
    contactsHtml = displayArray.map((contact, index) => {
      return (
        <ContactRow key={'CR_' + index}
          contactData={contact}
          index={index}
          mode={0}
          handleSelectedCb={handleContactSelected}
          handleChangeCb={handleContactUpdated}
          handleDeleteCb={handleContactDeleted}
        />)
    })
  }

  return (
    <div className="main-container">

      <div className="title-row">
        <div className='title-btn-group'>
          <div className="bk-text-blue-big">Kontaktoversikt</div>
          <BkButton caption='Legg til kontakt' onClickHandler={handleAddContact} />
        </div>

        <div className='filter-search-group module-row'>
          <div></div>
          <TextLineInput class='search-bar' placeholder="Søk" name="ContactSearch" value={searchText} onValueChangeCb={handleContactSearch} /></div>
        </div>

      <div>
        <div className="main-table-header-row module-row" id='module-row-no-margin'>
          <div className="flex-025"></div>
          <div className="flex-1-5">Navn</div>
          <div className="flex-1">Epost</div>
          <div className="flex-05">Mobil</div>
          <div className="flex-1-5">Adresse</div>
          <div className="flex-05"></div>
        </div>


        {contactsHtml}


        <ContactDialog
          contactData={contactData}
          open={openEdit}
          handleChangeCb={handleContactUpdated}
        />
        <AddContactDialog
          //contactData={contactData}
          open={openAdd}
          handleChangeCb={handleContactAdded}
        />
      </div>
    </div>
  )
}

export default Contacts;