import React, { useState, useEffect } from 'react';
import {Menu} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';


const ProjectStatus = ({status, statusChangedCb}) => {
   const [projectStatus,setProjectStatus]= useState(0);

   useEffect(() => {
      setProjectStatus(status);
   }, [status]);

   const getStatusClass = function(status) {
      if (status === 1){
         return "bk-project-status-icon bk-project-status-active"
      }
      if (status === 2){
         return "bk-project-status-icon bk-project-status-closed"
      }
      if (status === 3){
         return "bk-project-status-icon bk-project-status-deleted"
      }

      return "bk-project-status-icon";
   }
  
  
   const handleStatusChanged = (status) =>  {
      setProjectStatus(status);
      statusChangedCb(status);
      return;
   }

   function ProjectStatusMenu(props) {
      const [anchorEl, setAnchorEl] = React.useState(null);
      const open = Boolean(anchorEl);
    
      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
      const handleClose = () => {
        setAnchorEl(null);
      };
      
        return (
            <> 
               <div className="bk-text-blue-medium">Prosjektstatus:</div>
               <div aria-controls="fade-menu" aria-haspopup="true" className='navbar-profile'>
                  <div className="" onClick={handleClick} >
                     <div className={getStatusClass(projectStatus)} ></div>
                  </div>
                  
                  <Menu
                     elevation={0}
                     transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                     }}
                     anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                        }}
                  id="fade-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                  className="navbar-dropdown-menu"
                  >
                  <MenuItem onClick={() =>handleStatusChanged(1)}><span className={getStatusClass(1)}></span></MenuItem>
                  <MenuItem onClick={() =>handleStatusChanged(2)}><span className={getStatusClass(2)}></span></MenuItem>
                  <MenuItem onClick={() =>handleStatusChanged(3)}><span className={getStatusClass(3)}></span></MenuItem>
                  </Menu>
               </div>
            </>
         );
    }
  

   



   return (
      <>
         <ProjectStatusMenu />
      </>
   )
}


export default React.memo(ProjectStatus);