import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Checkbox } from '../../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom'
import { RoutesConfig } from '../../../config/routes.config';

type LoginControlsProps = {
  readonly rememberMe: boolean;
  readonly handleRememberMeCheck: (checked: boolean) => void;
};




const LoginControls = React.memo<LoginControlsProps>(
  ({ rememberMe, handleRememberMeCheck }) => {

    const history = useHistory();
    const handleForgotPasswordClick = () => {
      history.push(RoutesConfig.Forgot.path)
    }

    return (
      <Fragment>
        <div className='module-row '>
          <div className='flex-1 dialog-title'>
            <Checkbox
              label='Husk meg'
              checked={rememberMe}
              onCheck={handleRememberMeCheck}
            />
          </div>

          <div className='flex-1 dialog-link align-right'>
            <div onClick={handleForgotPasswordClick}>Glemt passord?</div>
          </div>

        </div>

        <div className='module-row mt-4'>
          <div className='flex-10 align-center'><button className="dialog-button" type="submit">Logg inn</button></div>
        </div>

      </Fragment>
    )
  }
);

LoginControls.displayName = 'LoginControls';

export default LoginControls;
