import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { actionCreators } from '../../store/auth';
import { ProjectApi, ProjectOwnerApi, ProjectClientApi, SurveyParticipantApi } from '../../api';
import { useHistory } from 'react-router-dom'
import { useParams } from "react-router-dom";
import { BkButton, UploadDialog, Spinner } from '../../components';
import { AlertDialog, ConfirmationDialog } from '../../components/Dialogs';
import { formatValue, formatDateTime } from '../../utils/'
import {
  CalculationRow, PropertyRow, SimpleCalculationRow, RightOfUseRow, SelectContactDialog,
  ProjectStatus, NewServiceDialog, EditProjectDialog, NotesDialog
} from "./child-components"
import ReportRow from './child-components/ReportRow';
import { ReportEntity } from '../Report/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight, faPenToSquare, faPrint } from '@fortawesome/free-solid-svg-icons'

type ProjectProps = typeof actionCreators &
{
  readonly isAuthenticated: boolean;
  readonly role: number;
  readonly userName: string;
};

const Project: React.FC<ProjectProps> = ({
  isAuthenticated,
  userName,
  role
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [owners, setOwners] = useState([]);
  const [clients, setClients] = useState([]);
  const [surveyParticipants, setSurveyParticipants] = useState([]);
  const [properties, setProperties] = useState([]);
  const [sharedUsers, setSharedUsers] = useState([]);
  const [calculations, setCalculations] = useState([]);
  const [simpleCalculations, setSimpleCalculations] = useState([]);
  const [rightOfUseCalculations, setRightOfUseCalculations] = useState([]);
  const [reports, setReports] = useState<Array<ReportEntity> | undefined>([]);
  const [project, setProject] = useState({
    projectId: 0,
    address: {
      projectId: 0,
      address: '',
      zipCode: '',
      city: '',
      municipality: '',
      gnr: '',
      bnr: '',
      fnr: '',
      snr: '',
      lnr: '',
      andelsnr: '',
      countyId: 1,
    },
    state: 1,
    county: '',
    imageUrl: '',
    created: '',
    updated: '',
    userName: '',
    userId: 0,
    surveyDate: null,
    requesterDate: null,
    notes: ''
  });

  // Used for edit project data
  const [openEdit, setOpenEdit] = React.useState(false);
  const [addressData, setAddressData] = useState({
    projectId: 0,
    address: '',
    zipCode: '',
    city: '',
    municipality: '',
    gnr: '',
    bnr: '',
    fnr: '',
    snr: '',
    lnr: '',
    andelsnr: '',
    countyId: 1,
  });

  const [openClientDialog, setOpenClientDialog] = useState(false);
  const [openClientDateDialog, setOpenClientDateDialog] = useState(false);
  const [client, setClient] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    address: '',
    zipCode: '',
    city: '',
  })
  const [openOwnerDialog, setOpenOwnerDialog] = useState(false);
  const [owner, setOwner] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    address: '',
    zipCode: '',
    city: '',
  })

  const [openSurveyDialog, setOpenSurveyDialog] = useState(false);


  const [openUploadDialog, setOpenUploadDialog] = React.useState(false);
  const [openNewServiceDialog, setOpenNewServiceDialog] = React.useState(false);
  const [openNotesDialog, setOpenNotesDialog] = React.useState(false);
  const [counties, setCounties] = useState([]);
  const [hasReportSubscription, setHasReportSubscription] = useState(false);
  const [reportSubscriptionDeactivated, setReportSubscriptionDeactivated] = useState(false);
  const [openAlertDialog, setOpenAlertDialog] = React.useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = React.useState(false);
  const [selectedReportNumber, setSelectedReportNumber] = React.useState(0);



  const params = useParams();
  const id = (params as any).id;
  const inputFile = useRef(null);


  let user_data = JSON.parse(localStorage.getItem('user')) == null ? [] : JSON.parse(localStorage.getItem('user'));

  const history = useHistory();

  const fetchData = async () => {
    ProjectApi.getProject(id)
      .then(
        (response) => {
          setProject(response.data.data);
          setSharedUsers(response.data.sharedUsers);
          setCalculations(response.data.calculations);
          setProperties(response.data.properties);
          setSimpleCalculations(response.data.simpleCalculations);
          setRightOfUseCalculations(response.data.rightOfUseCalculations);
          setOwners(response.data.owners);
          setClients(response.data.clients);
          setSurveyParticipants(response.data.surveyParticipants);
          setCounties(response.data.counties);
          setReports(response.data.reports);
          setHasReportSubscription(response.data.hasReportSubscription);
          setReportSubscriptionDeactivated(response.data.reportSubscriptionDeactivated);
          setIsLoading(false);
        },
        (error) => {
          console.log(error);
          setIsLoading(false);
          actionCreators.logoutUser();
          history.push("/login");
        });
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleOpenClientDialog = () => {
    setOpenClientDialog(true);
  }

  const handleClientChanged = function (contacts, date) {
    setOpenClientDialog(false);

    if (contacts !== null) {

      // Find if any removed
      clients.map(client => {
        var found = contacts.some(c => c.contactId == client.contactId);
        if (!found) {
          ProjectClientApi.deleteContact(client.contactId, parseInt(id))
            .then(
              (response) => {
                //
                // Were good
              },
              (error) => {
                //    
                console.log(error);
              });
        }
      })

      // Add new
      contacts.map(contact => {
        var found = clients.some(p => p.contactId == contact.contactId);

        if (!found) {
          var data = {
            projectClientId: 0,
            projectId: parseInt(id),
            contactId: contact.contactId,
            deleted: false
          }
          ProjectClientApi.addContact(data)
            .then(
              (response) => {
                // Were good
              },
              (error) => {
                console.log(error);
              });
        }
      })

      setClients(contacts);
    }
    setProject({ ...project, requesterDate: date });
    let data = project;
    data.requesterDate = date;
    ProjectApi.editProjectData(data);
  }

  const handleOpenOwnerDialog = () => {
    setOpenOwnerDialog(true);
  }

  const handleOwnerChanged = function (contacts, date) {
    setOpenOwnerDialog(false);

    if (contacts !== null) {

      // Find if any removed
      owners.map(owner => {
        var found = contacts.some(c => c.contactId == owner.contactId);
        if (!found) {
          ProjectOwnerApi.deleteContact(owner.contactId, parseInt(id))
            .then(
              (response) => {
                //
                // Were good
              },
              (error) => {
                //    
                console.log(error);
              });
        }
      })

      // Add new
      contacts.map(contact => {
        var found = owners.some(p => p.contactId == contact.contactId);

        if (!found) {
          var data = {
            projectOwnerId: 0,
            projectId: parseInt(id),
            contactId: contact.contactId,
            deleted: false
          }
          ProjectOwnerApi.addContact(data)
            .then(
              (response) => {
                // Were good
              },
              (error) => {
                console.log(error);
              });
        }
      })

      setOwners(contacts);
    }
  }


  const handleOpenSurveyDialog = () => {
    setOpenSurveyDialog(true);
  }


  const handleSurveyChanged = function (contacts, date) {
    setOpenSurveyDialog(false);

    if (contacts !== null) {

      // Find if any removed
      surveyParticipants.map(participant => {
        var found = contacts.some(c => c.contactId == participant.contactId);
        if (!found) {
          SurveyParticipantApi.deleteParticipant(participant.contactId, parseInt(id))
            .then(
              (response) => {
                //
                // Were good
              },
              (error) => {
                //    
                console.log(error);
              });
        }
      })

      // Add new
      contacts.map(contact => {
        var found = surveyParticipants.some(p => p.contactId == contact.contactId);

        if (!found) {
          var data = {
            surveyParticipantId: 0,
            projectId: parseInt(id),
            contactId: contact.contactId,
            deleted: false
          }
          SurveyParticipantApi.addParticipant(data)
            .then(
              (response) => {
                // Were good
              },
              (error) => {
                console.log(error);
              });
        }
      })

      setSurveyParticipants(contacts);
    }


    setProject({ ...project, surveyDate: date });
    let data = project;
    data.surveyDate = date;
    ProjectApi.editProjectData(data);

  }


  const handleClickOpenEdit = () => {
    // Clone the project data.
    setAddressData({ ...addressData, ...project.address });
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleSaveAndCloseEdit = (addressData, userId) => {

    if (addressData !== null) {
      //Find userId in shared array
      var userName = userName;
      if (userId != project.userId) {
        sharedUsers.map(user => {
          if (user.userId == userId) {
            userName = user.firstName + ' ' + user.lastName;
          }
        });
        setProject(project => ({ ...project, userId: userId, userName: userName }));
        var proj = project;
        proj.userId = userId;
        proj.userName = userName;
        ProjectApi.editProjectData(proj);
      }
      // Only set relevant data
      setProject(project => ({ ...project, address: addressData }));
      ProjectApi.editAddressData(addressData);
    }
    setOpenEdit(false);
  }


  const handleClickOpenNotes = () => {
    setOpenNotesDialog(true);
  };

  const handleSaveAndCloseNotes = (notes) => {
    if (notes != null) {
      setProject(project => ({ ...project, notes: notes }));
      //Todo: save
      var proj = project;
      proj.notes = notes;
      ProjectApi.editProjectData(proj);
    }
    setOpenNotesDialog(false);
  }

  const handleSaveAndCloseAlert = () => {
    setOpenAlertDialog(false);
  };

  const handleSaveAndCloseConfirm = (id) => {
    setOpenConfirmationDialog(false);
    if (id == 0) {
      return;
    }
    else {
      addReport(id);
    }
  }



  const handleStatusChanged = function (state) {
    var data = project;
    data.state = state;
    setProject(project => ({ ...project, state: state }));
    ProjectApi.editProjectData(data);
  }


  const handleOpenFileDialog = function () {
    //inputFile.current.click();
    setOpenUploadDialog(true);
  }

  const handleImageUrlUpdated = function (url) {
    setProject({ ...project, imageUrl: url });
    setOpenUploadDialog(false);
    var data = project;
    data.imageUrl = url;
    ProjectApi.editProjectData(data);
  }

  const handleImageCancel = function () {
    setOpenUploadDialog(false);
  }


  const handleFileSelected = function (e) {
    var data = new FormData();
    data.append("projectid", id);

    if (e.target.files.length > 0) {
      data.append("file", e.target.files[0]);
    } else {
      return;
    }

    ProjectApi.uploadImage(data)
      .then(
        (response) => {
          setProject({ ...project, imageUrl: response.data });
        },
        (error) => {
          console.log(error);
        }
      )

  }

  const getPicture = function (imageUrl) {
    if (imageUrl !== null && imageUrl !== 'undefined' && imageUrl !== '') {
      return (
        <div className='bk-project-tile-picture-frame '>
          <img className='bk-project-tile-picture' src={"/upload/" + imageUrl} />
        </div>
      )
    }
    else {
      return (
        <div className='bk-project-tile-header'>
          Forsidebilde
        </div>);
    }
  }


  const handlePrint = function () {

    ProjectApi.getPdf(id);
  }


  const getTotalGrossArea = function () {
    var sum = 0;
    calculations.map((calculation) => {
      sum = sum + calculation.grossArea;
    })
    return sum;
  }

  const getTotalSum = function () {
    var sum = 0;
    calculations.map((calculation) => {
      sum = sum + calculation.totalCost;
    })
    properties.map((calculation) => {
      sum = sum + calculation.totalCost;
    })
    return sum;
  }


  const getTotalReduction = function () {
    var sum = 0;
    calculations.map((calculation) => {
      sum = sum + calculation.totalCost * calculation.reductionPercent / 100;
    })
    return sum;
  }


  const handleAddService = function () {
    setOpenNewServiceDialog(true);
  }

  const handleNewService = function (service, type) {
    setOpenNewServiceDialog(false);
    if (type == 1) {
      if (reportSubscriptionDeactivated) {
        //Show message that you
        setOpenAlertDialog(true);
        return;
      }

      if (!hasReportSubscription) {
        //Show dialog
        setOpenConfirmationDialog(true);
        setSelectedReportNumber(service);
        return;
      }

      if (service == 25) {
        var data3 = {
          projectId: id,
          calculationTypeId: '',
          description: '',
          buildYear: 0
        }
        ProjectApi.addRightOfUse(data3)
          .then(
            (response) => {
              if (response && response.data) {
                const url = "/ownership/" + response.data;
                history.push(url);
              }
            },
            (error) => {
              console.log(error);
            }
          )
        return;
      }

      addReport(service);
    }
    else if (type == 2) {
      var data = {
        projectId: id,
        calculationTypeId: service.toString(),
        description: '',
        buildYear: new Date().getFullYear()
      }

      ProjectApi.addCalculation(data)
        .then(
          (response) => {
            var url = "/calculation/" + response.data;
            if (service === 8) {
              url = "/plot/" + response.data;
            }
            history.push(url);
          },
          (error) => {
            console.log(error);
          }
        )

    }
    // else if (type == 3) {
    //   switch (service) {
    //     case 1:
    //       var data3 = {
    //         projectId: id,
    //         calculationTypeId: '',
    //         description: '',
    //         buildYear: 0
    //       }
    //       ProjectApi.addRightOfUse(data3)
    //         .then(
    //           (response) => {
    //             const url = "/ownership/" + response.data;
    //             history.push(url);
    //           },
    //           (error) => {
    //             console.log(error);
    //           }
    //         )
    //       break;
    //     case 2:
    //       addReport(17);
    //       break;
    //     case 3:
    //       addReport(2);
    //       break;
    //   }
    // }

  }


  const addReport = (templateId) => {
    var data = {
      projectId: id,
      reportTemplateId: templateId,
      description: '',
      buildYear: 0
    }
    ProjectApi.addReport(data)
      .then(
        (response) => {

          if (response && response.data) {
            const url = "/report/" + response.data;
            history.push(url);
          }

        },
        (error) => {
          console.log(error);
        }
      )
  }


  const handleCalculationDeleted = function (calculationId) {
    ProjectApi.deleteCalculation(id, calculationId)
      .then(
        (response) => {
          // remove from array
          setCalculations(calculations.filter(calculation => calculation.calculationId !== calculationId));
          setProperties(properties.filter(property => property.calculationId !== calculationId));
        },
        (error) => {
          console.log(error);
        }
      )
  }

  const handleCalculationCopied = function (calculationId) {
    ProjectApi.copyCalculation(id, calculationId)
      .then(
        (response) => {
          var calculation = response.data;

          if (calculation.calculationTypeId == 5 || calculation.calculationTypeId == 8) {
            setProperties([calculation, ...properties]);
          }
          else {
            setCalculations([calculation, ...calculations]);
          }
          // remove from array
          // setCalculations(calculations.filter(calculation =>calculation.calculationId !== calculationId));
          // setProperties(properties.filter(property =>property.calculationId !== calculationId));
        },
        (error) => {
          console.log(error);
        }
      )
  }

  const handleOwnershipDeleted = function (calculationId) {
    ProjectApi.deleteOwnershipCalculation(id, calculationId)
      .then(
        (response) => {
          // remove from array
          setRightOfUseCalculations(rightOfUseCalculations.filter(calculation => calculation.otherCalculationId !== calculationId));

        },
        (error) => {
          console.log(error);
        }
      )
  }

  const handleRightOfUseCopied = (rightOfUseId) => {
    ProjectApi.copyOwnerShipCalculation(id, rightOfUseId)
      .then(
        (response) => {
          var ownership = response.data;

          setRightOfUseCalculations([ownership, ...rightOfUseCalculations]);

        },
        (error) => {
          console.log(error);
        }
      )
  }

  const handleReportCopied = function (reportId) {
    ProjectApi.copyReport(id, reportId)
      .then(
        (response) => {
          var report = response.data;
          setReports([report, ...reports]);
        },
        (error) => {
          console.log(error);
        }
      )
  }

  const handleSimpleCalculationDeleted = function (calculationId) {
    ProjectApi.deleteSimpleCalculation(id, calculationId)
      .then(
        (response) => {
          // remove from array
          setSimpleCalculations(simpleCalculations.filter(calculation => calculation.simpleCalculationId !== calculationId));
        },
        (error) => {
          console.log(error);
        }
      )
  }

  const handleSimpleCalculationCopied = function (calculationId) {
    ProjectApi.copySimpleCalculation(id, calculationId)
      .then(
        (response) => {
          var calculation = response.data;

          setSimpleCalculations([calculation, ...simpleCalculations]);
          // remove from array
          // setCalculations(calculations.filter(calculation =>calculation.calculationId !== calculationId));
          // setProperties(properties.filter(property =>property.calculationId !== calculationId));
        },
        (error) => {
          console.log(error);
        }
      )
  }


  const handleReportDeleted = function (reportId: number) {
    if (!reportId)
      return;

    ProjectApi.deleteReport(id, reportId)
      .then(
        (response) => {
          // remove from array
          setReports(reports.filter(rep => rep.reportId !== reportId));

        },
        (error) => {
          console.log(error);
        }
      )
  }

  const getColor = function () {
    if (project.userName != userName && project.userName != null && project.userName != '') {
      return 'main-table-header-row-other-user'
    }
    else {
      return ''
    }

  }

  const buildReportHtml = (templateName: string, showValue: boolean, useTemplateTitle: boolean) => {
    var rows = null;
    if (reports && reports.length > 0) {
      let counter = 0;
      rows = reports.map((report, index) => {
        if (report.reportTemplateName == templateName) {
          counter++;
          return <ReportRow report={report} index={counter - 1} onDeleteCb={handleReportDeleted} onCopyCb={handleReportCopied} showValue={showValue} useTemplateTitle={useTemplateTitle}></ReportRow>
        }
      });
    }

    let html = null;
    if (reports != null && reports.filter(r => r.reportTemplateName == templateName).length > 0) {
      html =
        <div>
          <div className="project-row-header bk-text-blue-medium">{templateName}</div>

          <div className={[getColor(), 'main-table-header-row module-row'].join(' ')} id='module-row-no-margin'>
            <div className='flex-01'></div>
            <div className="flex-1 align-left">Opprettet</div>
            <div className="flex-7 align-left">Beskrivelse</div>
            <div className="flex-2 align-right">
              {showValue &&
                <>Sum (inkl. MVA)</>
              }
            </div>
            <div className="flex-05"></div>
            <div className='flex-1'></div>
          </div>
          {rows}
        </div>
    }
    return html;
  }






  if (user_data === null || user_data.length === 0) {
    actionCreators.logoutUser();
    history.push("/login");
  }


  let calculationRows = null;
  if (calculations.length > 0) {
    calculationRows = calculations.map((calculation, index) =>
      <CalculationRow calculation={calculation} index={index} onDeleteCb={handleCalculationDeleted} onCopyCb={handleCalculationCopied}></CalculationRow>
    );
  }

  let propertyRows = null;
  if (properties.length > 0) {
    var startIndex = calculations.length;
    propertyRows = properties.map((property, index) =>
      <PropertyRow property={property} index={index} startIndex={startIndex} onDeleteCb={handleCalculationDeleted} onCopyCb={handleCalculationCopied}></PropertyRow>
    );
  }


  let simpleCalculationRows = null;
  if (simpleCalculations.length > 0) {
    simpleCalculationRows = simpleCalculations.map((calculation, index) =>
      <SimpleCalculationRow calculation={calculation} index={index} onDeleteCb={handleSimpleCalculationDeleted} onCopyCb={handleSimpleCalculationCopied}></SimpleCalculationRow>
    );
  }

  let rightOfUseRows = null;
  if (rightOfUseCalculations.length > 0) {
    rightOfUseRows = rightOfUseCalculations.map((calculation, index) =>
      <RightOfUseRow calculation={calculation} index={index} onDeleteCb={handleOwnershipDeleted} onCopyCb={handleRightOfUseCopied}></RightOfUseRow>
    );
  }



  let gnrHtml = null;
  let bnrHtml = null;
  let fnrHtml = null;
  let snrHtml = null;
  let lnrHtml = null;
  let anrHtml = null;

  if (project.address.gnr !== null && project.address.gnr !== undefined && project.address.gnr !== '') {
    gnrHtml = <div className=""> <span>Gnr.: {project.address.gnr} </span></div>;
  }
  if (project.address.bnr !== null && project.address.bnr !== undefined && project.address.bnr !== '') {
    bnrHtml = <div className=""> <span>Bnr.: {project.address.bnr} </span></div>;
  }
  if (project.address.fnr !== null && project.address.fnr !== undefined && project.address.fnr !== '') {
    fnrHtml = <div className=""> <span>Fnr.: {project.address.fnr} </span></div>;
  }
  if (project.address.snr !== null && project.address.snr !== undefined && project.address.snr !== '') {
    snrHtml = <div className=""> <span>Snr.: {project.address.snr} </span></div>;
  }
  if (project.address.lnr !== null && project.address.lnr !== undefined && project.address.lnr !== '') {
    lnrHtml = <div className=""> <span>Lnr.: {project.address.lnr} </span></div>;
  }
  if (project.address.andelsnr !== null && project.address.andelsnr !== undefined && project.address.andelsnr !== '') {
    anrHtml = <div className=""><span>Anr.: {project.address.andelsnr} </span></div>;
  }

  let ownerHtml = null;
  if (owners !== null && owners.length > 0) {
    ownerHtml = owners.map((contact) => {
      return (
        <div className="module-row-title">{contact.firstName} {contact.lastName}</div>
      )
    })
  }

  let clientHtml = null;
  if (clients !== null && clients.length > 0) {
    clientHtml = clients.map((contact) => {
      return (
        <div className="module-row-title">{contact.firstName} {contact.lastName}</div>
      )
    })
  }


  let surveyHtml = null;
  if (surveyParticipants !== null && surveyParticipants.length > 0) {
    surveyHtml = surveyParticipants.map((contact) => {
      return (
        <div className="module-row-title">{contact.firstName} {contact.lastName}</div>
      )
    })
  }


  let tehcnicalCalculationHtml = null;
  if ((calculationRows != null && calculationRows.length > 0) ||
    (propertyRows != null && propertyRows.length > 0)) {
    tehcnicalCalculationHtml =
      <div>
        <div className="project-row-header bk-text-blue-medium">Teknisk beregning</div>
        <div className={[getColor(), 'main-table-header-row module-row'].join(' ')} id='module-row-no-margin'>
          <div className='flex-01'></div>
          <div className="flex-1">Opprettet</div>
          <div className="flex-1">Endret</div>
          <div className="flex-1">Kalkulasjon</div>
          <div className="flex-1">Merknad</div>
          <div className="flex-1">Byggeår</div>
          <div className="flex-1">Sum BRA</div>
          <div className="flex-2">Byggekostnad iht TEK</div>
          <div className="flex-2">Verdireduksjon</div>
          <div className="flex-1">Etter fradrag</div>
          <div className="flex-05"></div>
        </div>

        {calculationRows}
        {propertyRows}

        <div className={[getColor(), 'main-table-header-row module-row'].join(' ')}>
          <div className='flex-01'></div>
          <div className="flex-1">Totalt</div>
          <div className="flex-3"></div>
          <div className="flex-015"></div>
          <div className="flex-1"></div>
          <div className="flex-1">{formatValue(getTotalGrossArea(), 0, ' m²')}</div>
          <div className="flex-2">{formatValue(getTotalSum(), 0, ' kr')}</div>
          <div className="flex-2">{formatValue(getTotalReduction(), 0, ' kr')}</div>
          <div className="flex-1">{formatValue(getTotalSum() - getTotalReduction(), 0, ' kr')}</div>
          <div className="flex-05"></div>
        </div>
      </div>
  }

  let rightOfUseHtml = null;
  if (rightOfUseCalculations != null && rightOfUseCalculations.length > 0) {
    rightOfUseHtml =
      <div>
        <div className="project-row-header bk-text-blue-medium">Bo- og bruksrett</div>

        <div className={[getColor(), 'main-table-header-row module-row'].join(' ')} id='module-row-no-margin'>
          <div className="flex-01"></div>
          <div className="flex-1 align-left">Opprettet</div>
          <div className="flex-7 align-left">Beskrivelse</div>
          <div className="flex-2 align-right">Sum (inkl. MVA)</div>
          <div className="flex-05"></div>
          <div className="flex-1"></div>
        </div>
        {rightOfUseRows}
      </div>
  }


  let detailedCalculationHtml = buildReportHtml("Detaljert Kalkulasjon", true, false);
  let areaHtml = buildReportHtml("Arealskjema", false, true);
  let leaseholdHtml = buildReportHtml("Festetomt", false, true);
  let reportHtml = buildReportHtml("Verditakst", false, true);
  let ns3600Html = buildReportHtml("Tilstandsrapport - NS 3600", false, true);
  let valueReportHtml = buildReportHtml("Verdivurdering", false, true);
  let advanceReportHtml = buildReportHtml("Forhåndstakst", false, true);
  let areaReportHtml = buildReportHtml("Arealrapport", false, true);
  let areaSimpleReportHtml = buildReportHtml("Arealrapport - Enkel", false, true);
  let simpleReportHtml = buildReportHtml("Brevtakst", false, false);
  let ns3424Html = buildReportHtml("Tilstandsrapport - NS 3424", false, true);
  let damageHtml = buildReportHtml("Skaderapport", false, true);
  let simpleDamageHtml = buildReportHtml("Skaderapport - Enkel", false, true);
  let commercialHtml = buildReportHtml("Næringstakst", false, true);
  let agriculturalHtml = buildReportHtml("Landbrukstakst", false, true);
  let simpleReclamaitionReportHtml = buildReportHtml("Reklamasjonsrapport", false, true);
  let reclamaitionReportHtml = buildReportHtml("Reklamasjonstakst", false, true);
  let offerLetterHtml = buildReportHtml("Tilbudsbrev", false, true);
  let plotReportHtml = buildReportHtml("Tomtetakst", false, true);
  let maintenanceReportHtml = buildReportHtml("Vedlikeholdsrapport", false, true);
  let buildLoanControllReportHtml = buildReportHtml("Byggelånskontroll", false, true);
  let oneYearInspectionHtml = buildReportHtml("Ettårsbefaring", false, true);
  let independentControllHtml = buildReportHtml("Uavhengig kontroll", false, true);



  const showOwner = function () {
    if (project.userName != userName && project.userName != null && project.userName != '') {
      return <div className='bk-project-owner-title'>Bygningssakkyndig: {project.userName}</div>
    }
  }

  if (project === undefined || project === null) {
    return <div>Laster prosjekt</div>
  }
  else if (project) {
    return (
      <div className="main-container">
        <div className="module-row bk-project-header-tile-line">
          <div className="bk-project-header-tile flex-1" title="Klikk for å endre" onClick={handleOpenFileDialog}>
            <div></div>
            <div className='module-row justify-center'><div className='align-center'>
              {getPicture(project.imageUrl)}
              <input type='file' id='file' accept=".jpg, .png, .jpeg, .gif" ref={inputFile} style={{ display: 'none' }} onChange={handleFileSelected} />
            </div>
            </div>
            <div className='module-row justify-end cursor-pointer'><FontAwesomeIcon icon={faPenToSquare} /></div>
          </div>
          <div className="bk-project-header-tile flex-1">

            <div className='bk-project-tile-header'>Prosjekt</div>
            <div className='bk-project-tile-body'>
              <div className='module-row mt-1 module-row-title'>{project.address.address}</div>
              <div className='module-row mt-1 module-row-title'>{project.address.zipCode + ' ' + project.address.city}</div>

              <div className='module-row mt-2'>
                <div className='flex-05'>
                  <div className='module-row-title'>{gnrHtml}</div>
                  <div className='module-row-title'>{bnrHtml}</div>
                  <div className='module-row-title'>{fnrHtml}</div>
                </div>

                <div className='flex-05'>
                  <div className='module-row-title'>{snrHtml}</div>
                  <div className='module-row-title'>{lnrHtml}</div>
                  <div className='module-row-title'>{anrHtml}</div>
                </div>
              </div>
            </div>
            <div className='module-row justify-end cursor-pointer' onClick={handleClickOpenEdit}><FontAwesomeIcon icon={faPenToSquare} /></div>
          </div>

          <div className="bk-project-header-tile flex-1">
            <div className='bk-project-tile-header'>Hjemmelshaver</div>
            <div className='bk-project-tile-body' >{ownerHtml}  </div>
            <div className='module-row justify-end cursor-pointer' onClick={handleOpenOwnerDialog}><FontAwesomeIcon icon={faPenToSquare} /></div>
          </div>

          <div className="bk-project-header-tile flex-1">
            <div className='bk-project-tile-header'>Rekvirent</div>
            <div className='bk-project-tile-body'>{clientHtml}  </div>
            <div className='module-row'>
              <div className='module-row-title bold'>{formatDateTime(project.requesterDate)}</div>
              <div className='module-row justify-end cursor-pointer' onClick={handleOpenClientDialog}><FontAwesomeIcon icon={faPenToSquare} />
              </div>
            </div>
          </div>

          <div className="bk-project-header-tile flex-1">
            <div className='bk-project-tile-header'>Besiktigelse</div>
            <div className='bk-project-tile-body'>{surveyHtml}  </div>
            <div className='module-row'>
              <div className='module-row-title bold'>{formatDateTime(project.surveyDate)}</div>
              <div className='module-row justify-end cursor-pointer' onClick={handleOpenSurveyDialog}><FontAwesomeIcon icon={faPenToSquare} /></div>
            </div>
          </div>
          <div className="bk-project-header-tile flex-1">
            <div className='bk-project-tile-header'>Notater</div>
            <div className='module-row-title notes'>{project.notes} </div>
            <div className='module-row justify-end cursor-pointer' onClick={handleClickOpenNotes}><FontAwesomeIcon icon={faPenToSquare} /></div>
          </div>
        </div>


        <div className="module-row align-items-end">
          <BkButton caption='Legg til tjeneste' onClickHandler={handleAddService} />
          <div className="flex-2">
            {showOwner()}
          </div>
          <div className="flex-2"></div>
          <div className="flex-05 module-row">
            <ProjectStatus status={project.state} statusChangedCb={handleStatusChanged} />
          </div>
          <div className="flex-015"><FontAwesomeIcon className='printer-icon bk-icon' icon={faPrint} onClick={handlePrint} /></div>
        </div>

        {tehcnicalCalculationHtml}

        {detailedCalculationHtml}

        {rightOfUseHtml}

        {areaHtml}
        {leaseholdHtml}
        {reportHtml}
        {ns3600Html}
        {ns3424Html}
        {valueReportHtml}
        {advanceReportHtml}
        {areaReportHtml}
        {areaSimpleReportHtml}
        {simpleReportHtml}
        {damageHtml}
        {simpleDamageHtml}
        {commercialHtml}
        {agriculturalHtml}
        {simpleReclamaitionReportHtml}
        {reclamaitionReportHtml}
        {offerLetterHtml}
        {plotReportHtml}
        {maintenanceReportHtml}
        {buildLoanControllReportHtml}
        {oneYearInspectionHtml}
        {independentControllHtml}


        <EditProjectDialog
          open={openEdit}
          title='Rediger prosjekt/ eiendom'
          address={addressData}
          userId={project.userId}
          counties={counties}
          onClose={handleSaveAndCloseEdit}
          sharedUsers={sharedUsers}
        />

        <SelectContactDialog
          open={openClientDialog}
          title='REKVIRENT'
          displayDate={true}
          date={project.requesterDate}
          multiselect={true}
          contactList={clients}
          handleSelectedCb={handleClientChanged}
        />

        <SelectContactDialog
          open={openOwnerDialog}
          title='HJEMMELSHAVER'
          displayDate={false}
          multiselect={true}
          contactList={owners}
          handleSelectedCb={handleOwnerChanged} />


        <SelectContactDialog
          open={openSurveyDialog}
          title='BESIKTIGELSE'
          displayDate={true}
          date={project.surveyDate}
          multiselect={true}
          contactList={surveyParticipants}
          handleSelectedCb={handleSurveyChanged}
        />

        <UploadDialog
          open={openUploadDialog}
          handleImageUrlUpdatedCb={handleImageUrlUpdated}
          handleCancelCb={handleImageCancel}
          url={project.imageUrl}
        />

        <NewServiceDialog
          open={openNewServiceDialog}
          handleNewServiceCb={handleNewService}
          showReports={true}
        />

        <NotesDialog
          open={openNotesDialog}
          title='NOTATER'
          onClose={handleSaveAndCloseNotes}
          notes={project.notes}
        />

        <AlertDialog
          open={openAlertDialog}
          title='Abonnementet på rapporter er deaktivert'
          text='Kontakt oss for å reaktivere abonnementet'
          onClose={handleSaveAndCloseAlert}
        />

        <ConfirmationDialog
          open={openConfirmationDialog}
          title='Rapportmodulen'
          text='Det påløper en klikkavgift per rapport.
          For å teste tjenesten får du de første 10 rapportene kostnadsfritt.
          Når disse er brukt opp, vil rapporter bli forhåndsfakturert i pakker på 50.'
          id={selectedReportNumber}
          onClose={handleSaveAndCloseConfirm}
        />


        <Spinner isLoading={isLoading} />
      </div>
    )

  }
}

const mapStateToProps = (state: IApplicationState) => ({
  isAuthenticated: state.auth.isAuthenticated,
  role: state.auth.role,
  userName: state.auth.userName,
});

export default connect(mapStateToProps, actionCreators)(Project);
